import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Badge } from '@abyss/web/ui/Badge';
import { dayjs } from '@abyss/web/tools/dayjs';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isUndefined, orderBy } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Table as TableComponent } from '@src/components/Table-query';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { useApi } from '@src/context/Api';
import { useToast } from '@abyss/web/hooks/useToast';
import { Styles } from './includes/styles';
import { EditModal } from '../EditModal';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Displays a list of alerts within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    error,
    headerLeft,
    isLoading,
    refetch,
    requestArgs,
    requestFunction,
    requestKey,
    rows,
    totalPages,
    totalRecords,
  } = props;

  const { toast } = useToast();

  const { useApiMutation, clearApiCache } = useApi();
  const [SaveAlert] = useApiMutation('SaveAlert');

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentAlert, setCurrentAlert] = useState({});

  /**
   * renderCellLastUpdated
   *
   * Displays the date and time of when the action path was last updated within a cell.
   *
   * @param cell
   * @returns {*}
   */
  const renderCellLastUpdated = ({ cell }) => {
    return dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * renderCellStatus
   *
   * Displays the current status of the alert within a cell.
   *
   * @param cell
   * @returns {React.JSX.Element|*}
   */
  const renderCellStatus = ({ cell }) => {
    const badges = {
      triggered: 'warning',
      assigned: 'success',
      closed: 'neutral',
    };

    if (!isUndefined(badges[cell?.value.toLowerCase()])) {
      return (
        <Badge variant={badges[cell?.value.toLowerCase()]} outline css={{ lineHeight: 1 }}>
          <span style={{ textTransform: 'capitalize' }}>{cell?.value.toLowerCase()}</span>
        </Badge>
      );
    }

    return cell.value;
  };

  /**
   * renderCellActions
   *
   * Provides the user with a menu of actionable items to perform on a specific action path within the list of action
   * paths.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellActions = ({ row }) => {
    const menuItems = [
      {
        title: 'Edit',
        onClick: () => {
          setIsEditModalOpen(true);
          setCurrentAlert(row?.original);
        },
        icon: <IconSymbol icon="edit" />,
      },
    ];

    return (
      <div
        style={{
          marginLeft: '-10px',
          marginRight: '-10px',
        }}
      >
        <Layout.Group
          css={{
            height: '100%',
            marginLeft: '2px',
            marginRight: '2px',
          }}
        >
          <DropdownMenu
            hideLabel
            before={<IconSymbol icon="more_vert" size={24} color="#6F6F6F" />}
            menuItems={menuItems}
          />
        </Layout.Group>
      </div>
    );
  };

  /**
   * renderCellDescription
   *
   * Provides the user with a list of Alert tags
   *
   * @param cell
   * @returns {Element}
   */
  const renderCellDescription = ({ cell }) => {
    return (
      <Tooltip
        content={
          <div
            style={{
              padding: themeConfiguration?.theme?.space?.sm,
              fontFamily: themeConfiguration?.theme?.fonts?.primary,
            }}
          >
            {cell?.value}
          </div>
        }
      >
        <span className="truncate">{cell?.value}</span>
      </Tooltip>
    );
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.Header === 'Last Updated') {
        column.Cell = renderCellLastUpdated;
      }

      if (column.Header === 'Status') {
        column.Cell = renderCellStatus;
      }

      if (column.Header === 'Description') {
        column.Cell = renderCellDescription;
      }

      if (column.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      return column;
    });
  }, []);

  /**
   * handleSave
   *
   * Handles the saving of an alert.
   *
   * @param payload
   * @returns {Promise<void>}
   */
  const handleSave = async (payload = {}) => {
    const toastId = 'save-alert';

    toast.show({
      id: `${toastId}-info`,
      title: 'Saving Alert...',
      message: 'Alert is preparing to save.',
      isLoading: true,
      ariaLoadingLabel: 'Saving Alert',
      variant: 'info',
      autoClose: false,
    });

    await SaveAlert(payload, {
      onSuccess: () => {
        clearApiCache(['ListAlerts']);
        refetch();
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-success`,
          title: 'Saved Alert',
          message: `Alert has been saved.`,
          variant: 'success',
        });
      },
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          title: 'Alert Save Failed',
          message: `Unable to save alert.`,
          variant: 'error',
        });
      },
    });
  };

  return (
    <ErrorHandler location="src/routes/private/Notifications/screens/Alerts/List/components/Table/Table.jsx">
      <Styles>
        <TableComponent
          {...{
            columns,
            configuration,
            error,
            isLoading,
            requestArgs,
            requestFunction,
            requestKey,
            rows,
            totalPages,
            totalRecords,
            headerLeft,
          }}
        />
        <EditModal
          isOpen={isEditModalOpen}
          setIsOpen={setIsEditModalOpen}
          alert={currentAlert}
          setAlert={setCurrentAlert}
          handleSave={handleSave}
        />
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.number,
  }),
  headerLeft: PropTypes.node,
  isLoading: PropTypes.bool,
  refetch: PropTypes.func,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
    alertStatuses: PropTypes.arrayOf(PropTypes.string),
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  error: null,
  headerLeft: null,
  isLoading: false,
  refetch: () => {},
  requestArgs: {
    page: 0,
    size: 25,
    sort: 'lastModifiedDate,desc',
    alertStatus: ['TRIGGERED', 'ASSIGNED'],
  },
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  totalPages: 1,
  totalRecords: 0,
};

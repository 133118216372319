import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Popover } from '@abyss/web/ui/Popover';
import { NameField } from '../../fields/Name';

/**
 * Button: EditName
 *
 * This button is used to edit the name of the entity.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const EditName = (props) => {
  const { form, currentEntity, refetch } = props;

  const [isOpen, setIsOpen] = useState(null);

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/buttons/EditName/EditName.jsx">
      <Popover
        width="auto"
        align="start"
        alignOffset={24}
        position="top"
        css={{ '.abyss-popover-content-container': { overflow: 'visible !important' } }}
        title=""
        content={<NameField form={form} currentEntity={currentEntity} refetch={refetch} />}
        open={isOpen === currentEntity?.id}
        onOpenChange={() => {
          return isOpen === currentEntity?.id ? setIsOpen(null) : setIsOpen(currentEntity?.id);
        }}
      >
        <Button
          variant="outline"
          onClick={() => {
            return setIsOpen(currentEntity?.id);
          }}
        >
          Edit Name
        </Button>
      </Popover>
    </ErrorHandler>
  );
};

EditName.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    register: PropTypes.func,
    setError: PropTypes.func,
    trigger: PropTypes.func,
    validate: PropTypes.func,
    clearErrors: PropTypes.func,
  }),
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
  }),
  refetch: PropTypes.func,
};

EditName.defaultProps = {
  form: {},
  currentEntity: {},
  refetch: () => {},
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '#searchContainer': {
    position: 'relative',
    '.abyss-descriptors-error-message': {
      bottom: 'initial',
    },
  },
  '#eidSearchResults': {
    '.abyss-indicator-root': {
      '.abyss-indicator-container': {
        background: 'var(--abyss-colors-info2)',
        borderColor: 'var(--abyss-colors-info2)',
        '.abyss-indicator-label': {
          color: 'var(--abyss-colors-info1)',
          fontWeight: 'bold',
        },
      },
    },
  },
});

import React, { useCallback, useEffect, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isArray, isEmpty, isNull, isUndefined } from 'lodash';
import { Loader } from '@src/components/Loader';
import { motion } from 'framer-motion';
import { useApi } from '@src/context/Api';
import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { Tabs } from './components/Tabs';
import fields from './includes/fields.json';

/**
 * AlertConfig
 *
 * Screen to list and manage alert configurations.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const AlertConfig = () => {
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const [defaultValues, setDefaultValues] = useState({ repeatableFields: [] });
  const [placeholderValues, setPlaceholderValues] = useState({ repeatableFields: [] });
  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const { useApiQuery, useApiQueries, useApiMutation, clearApiCache } = useApi();

  const theAssets = ['ListTags'];
  const assets = useApiQueries(theAssets);
  const [ListAlertConfigurations, { data, isLoading, isFetching, refetch }] = useApiQuery('ListAlertConfigurations');
  const [SaveAlertConfiguration] = useApiMutation('SaveAlertConfiguration');

  const { toast } = useToast();
  const form = useForm({ defaultValues });
  const { isSubmitting, isValid } = form?.formState;

  /**
   * Determines the overall loading state of all asset queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((assetKey) => {
          const asset = assets[assetKey];
          return !(!asset?.isLoading && !asset?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * Get the list of alert configurations from the remote api.
   */
  useEffect(() => {
    if (isUndefined(data) && !isLoading && !isFetching) {
      ListAlertConfigurations();
    }
  }, [data, isLoading, isFetching]);

  /**
   * Set default values from the api data.
   */
  useEffect(() => {
    const defaults = {
      repeatableFields: {},
    };

    if (isArray(data?.content) && !isEmpty(data?.content)) {
      data?.content.forEach((item) => {
        if (isUndefined(defaults?.repeatableFields?.[item?.reportId])) {
          defaults.repeatableFields[item?.reportId] = [];
        }

        if (item?.defaultForReport === true && isNull(item?.configScopeItem)) {
          defaults.repeatableFields?.[item?.reportId].push({
            deviationSensitivityPercAAD: String(item?.deviationSensitivityPercAAD),
            deviationSensitivityPercMAD: String(item?.deviationSensitivityPercMAD),
            minRecordDiff: String(item?.minRecordDiff),
            configScopeItem:
              item?.defaultForReport === true && isNull(item?.configScopeItem)
                ? 'default'
                : String(item?.configScopeItem),
            minTotalRecords: String(item?.minTotalRecords),
            configurationId: String(item?.configurationId),
          });
        }
      });
    }

    setDefaultValues(defaults);
  }, [data?.content]);

  /**
   * Set placeholder values from the api data.
   */
  useEffect(() => {
    const placeholders = {
      repeatableFields: {},
    };

    if (isArray(data?.content) && !isEmpty(data?.content)) {
      data?.content.forEach((item) => {
        if (isUndefined(placeholders?.repeatableFields?.[item?.reportId])) {
          placeholders.repeatableFields[item?.reportId] = {};
        }

        let placeholderField = {};

        if (item?.reportId === 'Daily_Tags_Count_Red') {
          placeholderField = {
            configScopeItem: '',
            configurationId: '',
            deviationSensitivityPercAAD: '0',
            deviationSensitivityPercMAD: '0',
            minRecordDiff: '0',
            minTotalRecords: '0',
          };
        }

        if (isEmpty(placeholders.repeatableFields?.[item?.reportId])) {
          placeholders.repeatableFields[item?.reportId] = placeholderField;
        }
      });
    }

    setPlaceholderValues(placeholders);
  }, [data?.content]);

  /**
   * Set form values from the api data.
   */
  useEffect(() => {
    const values = {
      repeatableFields: {},
    };

    if (isArray(data?.content) && !isEmpty(data?.content)) {
      data?.content.forEach((item) => {
        if (isUndefined(values?.repeatableFields?.[item?.reportId])) {
          values.repeatableFields[item?.reportId] = [];
        }

        let populatedField = {};

        if (item?.reportId === 'Daily_Tags_Count_Red') {
          populatedField = {
            deviationSensitivityPercAAD: String(item?.deviationSensitivityPercAAD),
            deviationSensitivityPercMAD: String(item?.deviationSensitivityPercMAD),
            minRecordDiff: String(item?.minRecordDiff),
            configScopeItem:
              item?.defaultForReport === true && isNull(item?.configScopeItem)
                ? 'default'
                : String(item?.configScopeItem),
            minTotalRecords: String(item?.minTotalRecords),
            configurationId: String(item?.configurationId),
          };
        }

        values.repeatableFields?.[item?.reportId].push(populatedField);
      });
    }

    form?.reset(values, {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: false,
      keepIsValid: false,
      keepSubmitCount: true,
      keepTouched: false,
      keepValues: false,
    });
  }, [data?.content]);

  /**
   * getTagOptions
   *
   * Compiles a list of selectable options based on the tags returned from the api.
   *
   * @returns {[{section: string, items: [{label: string, value: string}]}]}
   */
  const getTagOptions = () => {
    const tagOptions = [{ section: 'Default', items: [{ label: 'Default', value: 'default' }] }];

    if (!isUndefined(assets?.ListTags?.data?.tagsList) && !isEmpty(assets?.ListTags?.data?.tagsList)) {
      Object.keys(assets?.ListTags?.data?.tagsList).forEach((categoryCode) => {
        const tagOption = {
          section: '',
          items: [],
        };
        assets?.ListTags?.data?.tagsList[categoryCode].forEach((tag) => {
          if (isEmpty(tagOption.section)) {
            tagOption.section = tag?.categoryDesc;
          }
          const tagItem = {
            label: `${tag?.code} (${tag?.categoryCode})`,
            value: tag?.tag,
          };
          if (!tagOption.items.includes(tagItem)) {
            tagOption.items.push(tagItem);
          }
        });

        if (!tagOptions.includes(tagOption)) {
          tagOptions.push(tagOption);
        }
      });
    }

    return tagOptions;
  };

  /**
   * getFields
   *
   * Get the fields for a specific report id.
   *
   * @param reportId
   * @returns {*}
   */
  const getFields = (reportId) => {
    let theFields = fields[reportId];

    if (reportId === 'Daily_Tags_Count_Red') {
      theFields = theFields.map((field) => {
        const theField = { ...field };

        if (field?.model === 'configScopeItem') {
          theField.options = getTagOptions();
        }

        return theField;
      });
    }

    return theFields;
  };

  /**
   * Define a list of tabs based on the report id from the data.
   */
  useEffect(() => {
    const theTabs = [];
    if (isArray(data?.content) && !isEmpty(data?.content)) {
      data?.content?.forEach((item, index) => {
        const tab = {
          label: item?.reportId,
          subText: '',
          accessor: item?.reportId,
          fields: getFields(item?.reportId),
          defaultValues: defaultValues?.repeatableFields?.[item?.reportId],
          placeholderValues: placeholderValues?.repeatableFields?.[item?.reportId],
          values: form?.getValues(`repeatableFields.${item?.reportId}`),
          disabledFields: { 0: ['configScopeItem'] },
          index,
          originalValues: data?.content,
        };

        if (
          isUndefined(
            theTabs.find((theTab) => {
              return theTab.label === tab.label;
            })
          )
        ) {
          theTabs.push(tab);
        }
      });
    }

    if (theTabs !== tabs) {
      setTabs(theTabs);
    }
  }, [data?.content, defaultValues, placeholderValues, assets?.ListTags?.data?.tagsList]);

  /**
   * handleSubmit
   *
   * Calls a remote API to save the form data into a database.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    async (submittedValues) => {
      const theTab = tabs?.find((tab) => {
        return tab?.index === currentTab;
      });

      if (!isUndefined(theTab) && !isSubmitting && isValid) {
        const toSave = [];

        if (!isEmpty(submittedValues?.repeatableFields?.[theTab?.accessor])) {
          let values = submittedValues?.repeatableFields?.[theTab?.accessor];

          values = values.map((value) => {
            let theValue = { ...value };

            const theOriginalValue = theTab?.originalValues?.find((originalValue) => {
              return originalValue?.configurationId === value?.configurationId;
            });

            if (!isUndefined(theOriginalValue)) {
              theValue = { ...theOriginalValue, ...value };
            }

            return theValue;
          });

          values.forEach((value) => {
            const theValue = {
              ...value,
              ...{
                reportId: String(theTab?.accessor),
                configScopeItem: value?.configScopeItem === 'default' ? null : String(value?.configScopeItem),
                defaultForReport: Boolean(value?.defaultForReport),
                deviationSensitivityPercAAD: parseFloat(value?.deviationSensitivityPercAAD),
                deviationSensitivityPercMAD: parseFloat(value?.deviationSensitivityPercMAD),
                minRecordDiff: parseFloat(value?.minRecordDiff),
                minTotalRecords: parseFloat(value?.minTotalRecords),
              },
            };

            toSave.push(theValue);
          });

          const mutations = [];

          const toastId = 'save-alert-configuration';
          toast.show({
            id: `${toastId}-info`,
            title: 'Saving Alert Configuration...',
            message: 'Alert configuration is preparing to save.',
            isLoading: true,
            ariaLoadingLabel: 'Saving Alert Configuration',
            variant: 'info',
            autoClose: false,
          });

          toSave.forEach((payload = {}) => {
            mutations.push(
              SaveAlertConfiguration(payload, {
                onSuccess: () => {
                  clearApiCache(['ListAlertConfigurations']);
                  refetch();
                  toast.hide(`${toastId}-info`);
                  toast.show({
                    id: `${toastId}-success`,
                    title: 'Saved Alert Configuration',
                    message: `Alert configuration has been saved.`,
                    variant: 'success',
                  });
                },
                onError: () => {
                  toast.hide(`${toastId}-info`);
                  toast.show({
                    id: `${toastId}-error`,
                    title: 'Alert Configuration Save Failed',
                    message: `Unable to activate save alert.`,
                    variant: 'error',
                  });
                },
              })
            );
          });

          await Promise.all(mutations);
        }
      }
    },
    [isSubmitting, isValid, currentTab, tabs]
  );

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/AlertConfig/AlertConfig.jsx">
      <motion.div
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <FormProvider state={form} autoComplete="off" highlighted onSubmit={handleSubmit}>
          <Grid>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Flex direction="row" alignItems="center">
                <Heading offset={0}>Alert Config</Heading>
              </Flex>
            </Grid.Col>

            {isLoading || isFetching || isLoadingAssets ? (
              <Grid.Col span={{ xs: '100%' }}>
                <Loader verticalAlignment="top" />
              </Grid.Col>
            ) : (
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                {!isEmpty(tabs) && (
                  <Tabs form={form} tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
                )}
              </Grid.Col>
            )}
          </Grid>
        </FormProvider>
      </motion.div>
    </ErrorHandler>
  );
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.eid-remove-button': {
    background: 'transparent !important',
    '&:hover': {
      background: 'transparent !important',
    },
    '.eid-remove-icon': {
      color: 'var(--abyss-colors-error1) !important',
    },
  },

  '*[class*="isDisabled-true"]': {
    '.abyss-icon': {
      color: 'var(--abyss-colors-gray5) !important',
    },
  },
  '*[class*="isDisabled-false"]': {
    '.abyss-icon': {
      color: 'var(--abyss-colors-error1) !important',
    },
  },
  '.abyss-table-root': {
    '.abyss-table-head': {
      '.abyss-table-header': {
        'th:last-child': {
          flex: 'auto !important',
        },
      },
    },
    '.abyss-table-body': {
      '.abyss-table-row': {
        'td:last-child': {
          flex: 'auto !important',
        },
      },
    },
  },
});

import React from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import { Box } from '@abyss/web/ui/Box';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Label } from '@abyss/web/ui/Label';
import { Layout } from '@abyss/web/ui/Layout';
import { Visibility } from '@src/components/Visibility';
import { config } from '@abyss/web/tools/config';
import { useCurrentEnvironment } from '@src/hooks/useCurrentEnvironment';
import { useCurrentVersion } from '@src/hooks/useCurrentVersion';

/**
 * CurrentEnvironment
 *
 * Displays the current environment name along with the current GitHub version number.
 *
 * @returns {Element}
 * @constructor
 */
export const CurrentEnvironment = () => {
  const currentEnvironment = useCurrentEnvironment();
  const currentVersion = useCurrentVersion();

  return (
    <ErrorHandler location="src/components/CurrentEnvironment/CurrentEnvironment.jsx">
      <Visibility
        accessor="CurrentEnvironment"
        enabledEnvironments={['Local', 'Development', 'Stage']}
        disabledEnvironments={['Production']}
      >
        <Box color="$interactive1" padding="$sm" data-testid="current-environment-box" css={{ position: 'relative' }}>
          <Layout.Group alignLayout="center">
            <Label color="$white" className="text-align-center">
              {currentEnvironment} Environment
            </Label>
            <Badge
              variant="info"
              outline
              css={{
                color: '#ffffff',
                borderColor: '#ffffff',
                backgroundColor: 'transparent',
                borderRadius: '0px',
              }}
            >
              v{currentVersion}
            </Badge>
          </Layout.Group>
          {String(currentEnvironment).toLowerCase().includes('local') && (
            <div
              style={{
                position: 'absolute',
                top: 8,
                right: 10,
              }}
            >
              <Layout.Group alignLayout="center">
                <Label color="$white" className="text-align-center">
                  API Environment:
                </Label>
                <Badge
                  variant="info"
                  outline
                  css={{
                    color: '#ffffff',
                    borderColor: '#ffffff',
                    backgroundColor: 'transparent',
                    borderRadius: '0px',
                  }}
                >
                  {config('APP_ENV') === 'local' ? 'Local' : 'Development'}
                </Badge>
              </Layout.Group>
            </div>
          )}
        </Box>
      </Visibility>
    </ErrorHandler>
  );
};

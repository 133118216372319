import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { useApi } from '@src/context/Api';
import { Widget } from '@src/components/Widget';
import { Table } from './components/Table';

/**
 * TrustedValueHistory
 *
 * Displays list of trusted record changes about the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const TrustedValueHistory = (props) => {
  const { eid } = props;

  const { useApiQuery } = useApi();

  const [ListTrustedValueHistory, { data, error, isLoading, isFetching }] = useApiQuery('ListTrustedValueHistory');

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/TrustedValueHistory/TrustedValueHistory.jsx">
      <Widget
        title="Trusted Value History"
        description="Historical changes to IRE Trusted and Untrusted Attbributes"
        collapsible
        collapsed
      >
        <Grid>
          <Grid.Col span={{ xs: '100%' }}>
            <Table
              error={error}
              isLoading={isLoading || isFetching}
              requestArgs={{ page: 0, size: 25, sort: 'flipDate,desc', eid }}
              requestFunction={ListTrustedValueHistory}
              requestKey="ListTrustedValueHistory"
              rows={data?.content || []}
              totalPages={data?.totalPages || 1}
              totalRecords={data?.totalElements || 0}
            />
          </Grid.Col>
        </Grid>
      </Widget>
    </ErrorHandler>
  );
};

TrustedValueHistory.propTypes = {
  eid: PropTypes.string,
};

TrustedValueHistory.defaultProps = {
  eid: '',
};

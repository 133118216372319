import axios from 'axios';
import { config } from '@abyss/web/tools/config';
import { loginRequest } from '@src/includes/configuration/authentication';
import { msalInstance } from '@src/client';

const AxiosFrontendInstance = axios.create();

/**
 * Modify the request before it's sent.
 */
AxiosFrontendInstance.interceptors.request.use(
  async (axiosConfig) => {
    const configuration = axiosConfig;

    if (config('APP_ENV') !== 'local') {
      const activeAccount = msalInstance.getActiveAccount();

      if (activeAccount) {
        const token = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: activeAccount,
        });

        configuration.headers.Authorization = `Bearer ${token?.accessToken}`;
      }
    }

    return configuration;
  },
  (error) => {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error(
      'src/context/Api/includes/Axios.js -> AxiosFrontendInstance.interceptors.request -> error:',
      theError
    );

    throw error;
  }
);

/**
 * Modify the response before it's returned.
 */
AxiosFrontendInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error(
      'src/context/Api/includes/Axios.js -> AxiosFrontendInstance.interceptors.response -> error:',
      theError
    );

    if (error?.response?.status === 401 || error?.status === 401) {
      if (typeof window !== 'undefined') {
        window.location.href = `${config('APP_URL')}/unauthorized`;
        return false;
      }
      return {
        status: 401,
        statusText: 'Unauthorized',
      };
    }

    throw error;
  }
);

AxiosFrontendInstance.defaults.timeout = 60000; // set the default network timeout to 60 seconds

/**
 * Axios
 *
 * Makes a network request utilizing Axios library.
 *
 * @type {axios.AxiosInstance}
 */
export const Axios = AxiosFrontendInstance;

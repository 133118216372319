import PropTypes from 'prop-types';
import React from 'react';
import { CurrentEnvironment } from '@src/components/CurrentEnvironment';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Header } from '@src/layouts/default/Header';
import { motion } from 'framer-motion';
import { StickyContainer } from 'react-sticky';
import { Main } from './Main';

/**
 * Layout
 *
 * Glues together the various pieces of the layout.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const Layout = (props) => {
  const { children } = props;

  return (
    <ErrorHandler location="src/layouts/default/Layout.jsx">
      <motion.div
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <StickyContainer>
          <CurrentEnvironment />
          <Header />
          <Main>{children}</Main>
        </StickyContainer>
      </motion.div>
    </ErrorHandler>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

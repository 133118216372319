import React, { useEffect, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { motion } from 'framer-motion';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { useApi } from '@src/context/Api';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Table } from './components/Table';

/**
 * List
 *
 * Provides the user with a screen listing the existing action paths.
 *
 * @returns {Element}
 * @constructor
 */
export const List = () => {
  const [showAll, setShowAll] = useState(false);

  const router = useRouter();

  const { useApiQuery, clearApiCache } = useApi();

  const [ListActionPaths, { data, isLoading, isFetching, error, refetch }] = useApiQuery('ListActionPaths');

  /**
   * Invalidate the query cache when the showAll state changes.
   */
  useEffect(() => {
    clearApiCache(['ListActionPaths']);
  }, [showAll]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/List/List.jsx">
      <motion.div
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Flex direction="row" alignItems="center">
              <Heading offset={0}>Action Paths</Heading>
              <div
                style={{
                  marginLeft: themeConfiguration?.theme?.space?.md,
                }}
              >
                <DropdownMenu
                  className="no-padding no-margin"
                  outline
                  label="Create"
                  after={<IconSymbol icon="keyboard_arrow_down" />}
                  menuItems={[
                    {
                      title: 'Create with Entrance Criteria',
                      onClick: async () => {
                        router?.navigate(`create`);
                      },
                    },
                    {
                      title: 'Create Manually',
                      onClick: async () => {
                        router?.navigate(`create/manual`);
                      },
                    },
                  ]}
                />
              </div>
            </Flex>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            {!showAll && (
              <Table
                error={error}
                isLoading={isLoading || isFetching}
                requestArgs={{ page: 0, size: 25, sort: 'lastModifiedDate,desc', statusList: ['ACTIVE'] }}
                requestFunction={ListActionPaths}
                requestKey="ListActionPaths"
                rows={data?.content || []}
                totalPages={data?.totalPages || 1}
                totalRecords={data?.totalElements || 0}
                refetch={refetch}
                headerLeft={
                  <ToggleSwitch
                    label="Show All Action Paths"
                    isChecked={showAll}
                    onChange={(event) => {
                      return setShowAll(event?.target?.checked);
                    }}
                  />
                }
              />
            )}

            {showAll && (
              <Table
                error={error}
                isLoading={isLoading || isFetching}
                requestArgs={{ page: 0, size: 25, sort: 'lastModifiedDate,desc' }}
                requestFunction={ListActionPaths}
                requestKey="ListActionPaths"
                rows={data?.content || []}
                totalPages={data?.totalPages || 1}
                totalRecords={data?.totalElements || 0}
                refetch={refetch}
                headerLeft={
                  <ToggleSwitch
                    label="Show All Action Paths"
                    isChecked={showAll}
                    onChange={(event) => {
                      return setShowAll(event?.target?.checked);
                    }}
                  />
                }
              />
            )}
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};

import React from 'react';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { ErrorHandler } from '@src/components/ErrorHandler';
import fields from '../includes/fields.json';

const { exitCriteriaMethod: exitCriteriaMethodField } = fields;

export const ExitCriteriaMethod = () => {
  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/ExitCriteria/components/fields/ExitCriteriaMethod/ExitCriteriaMethod.jsx">
      <SelectInput {...exitCriteriaMethodField} width="30ch" css={{ width: 'auto' }} />
    </ErrorHandler>
  );
};

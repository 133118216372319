import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { merge, orderBy } from 'lodash';
import { Table as TableComponent } from '@src/components/Table-query';
import { ExpansionRow } from './components/ExpansionRow';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Displays a list of action paths within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { error, headerLeft, isLoading, requestArgs, requestFunction, requestKey, rows, totalPages, totalRecords } =
    props;

  /**
   * renderCellChangedDate
   *
   * Displays the changed date cell.
   *
   * @param cell
   * @returns {*}
   */
  const renderCellChangedDate = ({ cell }) => {
    return dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * renderCellCounts
   *
   * Displays the cell counts.
   *
   * @param cell
   * @returns {string}
   */
  const renderCellCounts = ({ cell }) => {
    return Number(cell?.value).toLocaleString('en-US');
  };

  /**
   * Columns for table.
   *
   * @type {T[]}
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.Header === 'Changed Date') {
        column.Cell = renderCellChangedDate;
      }

      if (String(column.Header).toLowerCase().includes('count')) {
        column.Cell = renderCellCounts;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/TrustedValueHistory/components/Table/Table.jsx">
      <TableComponent
        {...{
          columns,
          configuration: merge({}, configuration, {
            renderSubComponent: ({ original }) => {
              return <ExpansionRow row={original} />;
            },
          }),
          error,
          headerLeft,
          isLoading,
          requestArgs,
          requestFunction,
          requestKey,
          rows,
          totalPages,
          totalRecords,
        }}
      />
    </ErrorHandler>
  );
};

Table.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  headerLeft: PropTypes.element,
  isLoading: PropTypes.bool,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  error: null,
  headerLeft: null,
  isLoading: false,
  requestArgs: {},
  requestFunction: null,
  requestKey: '',
  rows: [],
  totalPages: 0,
  totalRecords: 0,
};

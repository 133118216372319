import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isUndefined } from 'lodash';

/**
 * SaveCode
 *
 * Saves a single code with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveCode = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/code-lists/categories',
      },
      data: {},
    };

    const thePayload = {
      ...payload?.[1],
    };

    if (!isUndefined(payload?.[0])) {
      requestArgs.params = { ...{ mutationKey: payload?.[0] } };
    }

    if (!isUndefined(thePayload?.data?.categoryCode) && !isEmpty(thePayload?.data?.categoryCode)) {
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.data?.categoryCode}/code`;

      if (thePayload?.mode === 'edit' && !isUndefined(thePayload?.id) && !isEmpty(thePayload?.id)) {
        requestArgs.method = 'PUT';
        requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}`;
      }
    }

    requestArgs.data = { ...thePayload?.data };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveCode.js -> SaveCode() -> error:', theError);

    throw error;
  }
};

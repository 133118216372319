import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isUndefined } from 'lodash';

/**
 * SaveAlertConfiguration
 *
 * Saves a single alert with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveAlertConfiguration = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/alerts/configuration',
      },
      data: {},
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ mutationKey: payload[0] } };
    }

    if (!isUndefined(payload?.[1]?.configurationId) && !isEmpty(payload?.[1]?.configurationId)) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${payload?.[1]?.configurationId}`;
    }

    Object.keys(payload?.[1]).forEach((key) => {
      const value = payload?.[1][key];

      if (key === 'configurationId' && isEmpty(value)) {
        return;
      }

      requestArgs.data[key] = value;
    });

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveAlertConfiguration.js -> SaveAlertConfiguration() -> error:', theError);

    throw error;
  }
};

import PropTypes from 'prop-types';
import React from 'react';
import { Card } from '@abyss/web/ui/Card';

/**
 * WithCard
 *
 * Conditionally wraps the children in a Card component.
 *
 * @param props
 * @returns {React.JSX.Element|*}
 * @constructor
 */
export const WithCard = (props) => {
  const { backgroundColor, children, withCard } = props;

  if (withCard === false) {
    return children;
  }

  return (
    <Card className="widget-inner" size="small" css={{ backgroundColor }}>
      {children}
    </Card>
  );
};

WithCard.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  withCard: PropTypes.bool,
};

WithCard.defaultProps = {
  backgroundColor: 'transparent',
  withCard: true,
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isUndefined } from 'lodash';

/**
 * SaveTag
 *
 * Saves a single tag with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveTag = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/tags',
      },
      data: {},
    };

    const thePayload = {
      ...payload?.[1],
    };

    if (!isUndefined(payload?.[0])) {
      requestArgs.params = { ...{ mutationKey: payload?.[0] } };
    }

    if (
      thePayload?.mode === 'edit' &&
      !isUndefined(thePayload?.data?.categoryCode) &&
      !isEmpty(thePayload?.data?.categoryCode) &&
      !isUndefined(thePayload?.data?.code) &&
      !isEmpty(thePayload?.data?.code)
    ) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.data?.categoryCode}/${thePayload?.data?.code}`;
      delete thePayload?.data?.categoryCode;
      delete thePayload?.data?.code;
    }

    requestArgs.data = { ...thePayload?.data };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveTag.js -> SaveTag() -> error:', theError);

    throw error;
  }
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.eventTimeline': {
    position: 'relative',
    width: '100%',
    '.abyss-slide-container': {
      position: 'relative',
      width: '100%',
      backgroundColor: 'transparent',
      '.abyss-slide-content-wrapper': {
        position: 'relative',
        width: '100%',
        padding: 0,
        '.eventTimelineFlex': {
          position: 'relative',
          marginLeft: 'calc(calc(var(--abyss-space-xl) *2) * -1)',
          marginRight: 'calc(calc(var(--abyss-space-xl) *2) * -1)',
          minHeight: 'calc(var(--abyss-space-xl) * 4)',
          maxHeight: 'calc(var(--abyss-space-xl) * 5)',
          '.abyss-timeline-root': {
            position: 'relative',
            width: '100%',
            display: 'flex',
            paddingTop: 'var(--abyss-space-sm)',
            paddingBottom: 'var(--abyss-space-sm)',
          },
          '.abyss-timeline-container': {
            position: 'relative',
            width: '100%',
            flexGrow: 1,
            justifyContent: 'space-evenly',
            marginTop: 'var(--abyss-space-xl)',
            '.abyss-timeline-item': {
              width: '20%',
              '&::before, &::after': {
                borderColor: 'var(--abyss-colors-primary1) !important',
              },
              '.abyss-timeline-item-title': {
                color: 'var(--abyss-colors-gray7)',
                marginTop: 'calc(calc(var(--abyss-space-xl) - var(--abyss-space-xs)) * -1)',
                marginBottom: 'calc(var(--abyss-space-lg) * 2.25)',
              },
              '.abyss-timeline-item-bullet': {
                zIndex: 1,
                backgroundColor: '#ffffff !important',
                alignItems: 'center',
                '.abyss-layout-group': {
                  backgroundColor: '#ffffff',
                  '.abyss-icon': {
                    backgroundColor: '#ffffff',
                    color: 'var(--abyss-colors-primary1) !important',
                  },
                },
              },
              '.abyss-timeline-item-content': {
                '.abyss-text-root': {
                  color: 'var(--abyss-colors-gray9)',
                },
              },
            },
            '&.hide-1': {
              '.abyss-timeline-item:nth-child(1)': {
                '&::after': {
                  display: 'none',
                },
              },
              '.abyss-timeline-item:nth-child(2)': {
                '.abyss-timeline-item-bullet': {
                  display: 'none',
                },
                '&::before, &::after': {
                  display: 'none',
                },
              },
            },
            '&.hide-2': {
              '.abyss-timeline-item:nth-child(2)': {
                '&::after': {
                  display: 'none',
                },
              },
              '.abyss-timeline-item:nth-child(3)': {
                '.abyss-timeline-item-bullet': {
                  display: 'none',
                },
                '&::before, &::after': {
                  display: 'none',
                },
              },
            },
            '&.hide-3': {
              '.abyss-timeline-item:nth-child(3)': {
                '&::after': {
                  display: 'none',
                },
              },
              '.abyss-timeline-item:nth-child(4)': {
                '.abyss-timeline-item-bullet': {
                  display: 'none',
                },
                '&::before, &::after': {
                  display: 'none',
                },
              },
            },
            '&.hide-4': {
              '.abyss-timeline-item:nth-child(4)': {
                '&::after': {
                  display: 'none',
                },
              },
              '.abyss-timeline-item:nth-child(5)': {
                '.abyss-timeline-item-bullet': {
                  display: 'none',
                },
                '&::before, &::after': {
                  display: 'none',
                },
              },
            },
          },
        },
      },
    },
  },
});

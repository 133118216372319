import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FilterCriteria } from '@src/common/widgets/FilterCriteria';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { RemediationActions } from '@src/common/widgets/RemediationActions';

/**
 * ReviewConfirm
 *
 * Provides the user with a screen to verify the specified entrance criteria (filters) and actions to run on the
 * queried risk records.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ReviewConfirm = (props) => {
  const { actionPath, currentStep, form, isManual, assets } = props;

  const formValues = form.getValues();

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/ReviewConfirm/ReviewConfirm.jsx">
      <Grid>
        <Grid.Col
          css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
          span={{
            xs: '100%',
          }}
        >
          <Heading offset={1}>{currentStep?.label}</Heading>
          <p>{currentStep?.description}</p>
        </Grid.Col>
        {!isManual && [
          <Grid.Col
            span={{
              xs: '50%',
            }}
          >
            <FilterCriteria
              {...{
                filterKey: 'entranceCriteria',
                criteria: actionPath?.criteria,
                context: 'entrance',
              }}
            />
          </Grid.Col>,
          <Grid.Col
            span={{
              xs: '50%',
            }}
          >
            <FilterCriteria
              {...{
                filterKey: 'exitCriteria',
                criteria: actionPath?.criteria,
                context: 'exit',
                criteriaMethod: form?.getValues('exitCriteriaMethod'),
              }}
            />
          </Grid.Col>,
        ]}
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <RemediationActions
            {...{
              scopeCode: formValues?.remediation?.actionPathScopeCode,
              remediationType: formValues?.remediation?.remediationMethod,
              assignments: formValues?.remediation?.assignments.map((assignment) => {
                const theAssignment = { ...assignment };
                const theAction = assets?.ListActions?.data?.content?.find((action) => {
                  return action?.id === theAssignment?.actionId;
                });

                theAssignment.actionName = theAction?.name;
                return theAssignment;
              }),
            }}
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

ReviewConfirm.propTypes = {
  actionPath: PropTypes.shape({
    criteria: PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
      })
    ),
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  assets: PropTypes.shape({
    ListActions: PropTypes.shape({
      data: PropTypes.shape({
        content: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  currentStep: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
  isManual: PropTypes.bool,
};

ReviewConfirm.defaultProps = {
  actionPath: {
    criteria: [],
    id: '',
    name: '',
  },
  assets: {},
  currentStep: {},
  form: {},
  isManual: false,
};

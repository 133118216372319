import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-table-root': {
    '.abyss-table-head': {
      '.abyss-table-header': {
        th: {
          flex: 'auto !important',
          width: '25% !important',
          minWidth: '25% !important',
        },
        'th:last-child': {
          flex: 'none !important',
          width: '128px !important',
          minWidth: '128px !important',
        },
      },
    },
    '.abyss-table-body': {
      '.abyss-table-row': {
        td: {
          flex: 'auto !important',
          width: '25% !important',
          minWidth: '25% !important',
        },
        'td:last-child': {
          flex: 'none !important',
          width: '128px !important',
          minWidth: '128px !important',
        },
      },
    },
  },
  '#addField': {
    fontWeight: 'bold !important',
    '.abyss-icon': {
      marginTop: 'var(--abyss-space-xs)',
      marginRight: 'var(--abyss-space-xs)',
    },
    'span.abyss-icon-symbol:first-of-type': {
      display: 'none',
      visibility: 'hidden',
    },
    'span.abyss-icon-symbol:last-of-type': {
      display: 'inline-block',
      visibility: 'visible',
    },
    '&:hover:not([class*="isDisabled-true"])': {
      'span.abyss-icon-symbol:first-of-type': {
        display: 'inline-block',
        visibility: 'visible',
      },
      'span.abyss-icon-symbol:last-of-type': {
        display: 'none',
        visibility: 'hidden',
      },
    },
  },
});

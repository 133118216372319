import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '@abyss/web/ui/Flex';
import { Indicator } from '@abyss/web/ui/Indicator';

/**
 * ActionText
 *
 * Renders the text within each step/navigation item.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ActionText = (props) => {
  const { text, index } = props;

  return (
    <Flex justify="right" alignItems="center" direction="row">
      <Indicator withBorder label={index + 1} offset={-1} size="small" showZero={false} position="top-start" />
      <div>{text}</div>
    </Flex>
  );
};

ActionText.propTypes = {
  text: PropTypes.string,
  index: PropTypes.number,
};

ActionText.defaultProps = {
  text: '',
  index: 0,
};

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ActionNav } from '@abyss/web/ui/ActionNav';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty } from 'lodash';
import { isValidStep } from '@src/routes/private/ActionPaths/components/Wizard/includes/functions';
import { ActionText } from './components/ActionText';
import { Search } from './components/Search';
import { Styles } from './includes/styles';

/**
 * Header
 *
 * Responsible for handling various logic between all steps.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Header = (props) => {
  const { actionPath, currentStep, form, steps, previousStep, isManual } = props;

  const validName = !!isEmpty(form?.formState?.errors?.name);
  const isCurrentStepValid = isValidStep(currentStep, validName, form?.formState?.errors, isManual);

  /**
   * Highlights each current/completed step.
   */
  useEffect(() => {
    const navigationItems = document.querySelectorAll('.abyss-action-nav-list-item');

    if (navigationItems instanceof NodeList && !isEmpty(navigationItems)) {
      navigationItems.forEach((navigationItem, index) => {
        const classes = navigationItem.classList;
        classes.remove('current', 'completed');

        if (currentStep?.order - 1 === index && !classes.contains('current')) {
          classes.add('current');
        }

        if (currentStep?.order - 1 > index && !classes.contains('completed')) {
          classes.add('completed');
        }
      });
    }
  }, [currentStep, steps, isCurrentStepValid]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/components/Header/Header.jsx">
      <Styles>
        <ActionNav title="Action Path" subText={<Search form={form} actionPath={actionPath} />}>
          {steps?.map((step, index) => {
            return (
              <ActionNav.Item
                key={`${steps[index]?.key}`}
                style={{
                  cursor: 'auto !important',
                  paddingBottom: themeConfiguration?.theme?.space?.lg,
                }}
                className="current"
                href=""
                onClick={async (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
                title=""
                hideIcon
                actionText={<ActionText index={index} text={steps[index]?.label} />}
                variant={currentStep?.order > previousStep?.order ? 'success' : 'info'}
              />
            );
          })}
        </ActionNav>
      </Styles>
    </ErrorHandler>
  );
};

Header.propTypes = {
  actionPath: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  currentStep: PropTypes.shape({
    order: PropTypes.number,
    label: PropTypes.string,
  }),
  form: PropTypes.shape({
    formState: PropTypes.shape({
      errors: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    trigger: PropTypes.func,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
    register: PropTypes.func,
  }),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  previousStep: PropTypes.shape({
    order: PropTypes.number,
    label: PropTypes.string,
  }),
  isManual: PropTypes.bool,
};

Header.defaultProps = {
  actionPath: {},
  currentStep: {},
  form: {},
  steps: [],
  previousStep: {},
  isManual: false,
};

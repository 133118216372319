import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isUndefined } from 'lodash';

/**
 * DeleteActionPath
 *
 * Deletes an action path from the remote API.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const DeleteActionPath = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'delete',
      headers: {
        'x-api-endpoint': `/action-paths`,
      },
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ mutationKey: payload[0] } };
      requestArgs.headers['x-api-endpoint'] += `/${payload[1]?.id}`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/DeleteActionPath.js -> DeleteActionPath() -> error:', theError);

    throw error;
  }
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';

/**
 * SaveActionPathNote
 *
 * Saves a note to an  action path with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const SaveActionPathNote = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'PUT',
      headers: {
        'x-api-endpoint': `/action-paths/${thePayload?.actionPathId}/note`,
      },
      params: { mutationKey: theMutationKey },
      data: {
        note: thePayload?.note,
      },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveActionPathNote.js -> SaveActionPathNote() -> error:', theError);

    throw error;
  }
};

import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table } from '@src/components/Table-static';
import { merge } from 'lodash';
import configuration from './includes/configuration.json';

/**
 * DataTable
 *
 * a data table is a structured arrangement of information into rows and columns for organizing and presenting data in
 * a tabular format.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const DataTable = (props) => {
  const { data, tableConfiguration } = props;

  return (
    <ErrorHandler location="src/components/Chart/components/DataTable/DataTable.jsx">
      <Table
        {...{
          configuration: merge({}, configuration, tableConfiguration),
          columns: tableConfiguration?.initialColumns,
          rows: data,
        }}
      />
    </ErrorHandler>
  );
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tableConfiguration: PropTypes.shape({
    initialColumns: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

DataTable.defaultProps = {
  tableConfiguration: {},
};

import PropTypes from 'prop-types';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import { Box } from '@abyss/web/ui/Box';
import { dayjs } from '@abyss/web/tools/dayjs';
import { DeletionModal } from '@src/routes/private/ActionPaths/screens/List/components/DeletionModal';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, isUndefined, orderBy } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { SourcesTooltip } from '@src/common/tooltips';
import { Table as TableComponent } from '@src/components/Table-query';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { useApi } from '@src/context/Api';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useToast } from '@abyss/web/hooks/useToast';
import { Styles } from './includes/styles';
import { DuplicationModal } from '../DuplicationModal';
import { ActivationModal } from '../ActivationModal';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Displays a list of action paths within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    error,
    headerLeft,
    isLoading,
    refetch,
    requestArgs,
    requestFunction,
    requestKey,
    rows,
    totalPages,
    totalRecords,
  } = props;

  const { toast } = useToast();
  const router = useRouter();
  const location = router?.getLocation();
  const urlParams = new URLSearchParams(location?.search);
  const highlight = urlParams.getAll('highlight');

  const [reload, setReload] = useState(false);
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
  const [isDuplicationModalOpen, setIsDuplicationModalOpen] = useState(false);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [currentActionPath, setCurrentActionPath] = useState({});

  const { useApiMutation, clearApiCache } = useApi();

  const [CopyActionPath] = useApiMutation('CopyActionPath');
  const [DeleteActionPath] = useApiMutation('DeleteActionPath');
  const [SaveActionPath] = useApiMutation('SaveActionPath');

  /**
   * handleActivation
   *
   * Makes a remote API request to change the status of the action path to 'active'.
   *
   * @param actionPath
   * @returns {Promise<void>}
   */
  const handleActivation = async (actionPath = {}) => {
    const toastId = 'list-action-paths-activation';
    toast.show({
      id: `${toastId}-info`,
      title: 'Activating Action Path...',
      message: 'The action path is preparing to activate.',
      isLoading: true,
      ariaLoadingLabel: 'Activating Action Path',
      variant: 'info',
      autoClose: false,
    });

    await SaveActionPath(
      {
        ...actionPath,
        ...{
          status: 'ACTIVE',
        },
      },
      {
        onSuccess: () => {
          clearApiCache(['ListActionPaths', 'GetActionPath']);
          refetch();
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            title: 'Activated Action Path',
            message: `${actionPath?.name} has been activated.`,
            variant: 'success',
          });
        },
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            title: 'Action Path Activation Failed',
            message: `Unable to activate ${actionPath?.name}.`,
            variant: 'error',
          });
        },
      }
    );
  };

  /**
   * handleDeactivation
   *
   * Makes a remote API request to change the status of the action path to 'inactive'.
   *
   * @param actionPath
   * @returns {Promise<void>}
   */
  const handleDeactivation = async (actionPath = {}) => {
    const toastId = 'list-action-paths-deactivation';
    toast.show({
      id: `${toastId}-info`,
      title: 'Deactivating Action Path...',
      message: 'Action path is preparing to deactivate.',
      isLoading: true,
      ariaLoadingLabel: 'Deactivating Action Path',
      variant: 'info',
      autoClose: false,
    });

    await SaveActionPath(
      {
        ...actionPath,
        ...{
          status: 'INACTIVE',
        },
      },
      {
        onSuccess: () => {
          clearApiCache(['ListActionPaths', 'GetActionPath']);
          refetch();
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            title: 'Deactivated Action Path',
            message: `${actionPath?.name} has been deactivated.`,
            variant: 'success',
          });
        },
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            title: 'Action Path Deactivation Failed',
            message: `Unable to deactivate ${actionPath?.name}.`,
            variant: 'error',
          });
        },
      }
    );
  };

  /**
   * handleDuplication
   *
   * Makes a remote API request to make a copy of the action path, and redirect the user to the edit action path screen.
   *
   * @param actionPath
   * @returns {Promise<void>}
   */
  const handleDuplication = async (actionPath = {}) => {
    const toastId = 'list-action-paths-activation';
    toast.show({
      id: `${toastId}-info`,
      title: 'Duplicating Action Path...',
      message: 'Action path is preparing to duplicate.',
      isLoading: true,
      ariaLoadingLabel: 'Duplicating Action Path',
      variant: 'info',
      autoClose: false,
    });

    await CopyActionPath(actionPath, {
      onSuccess: async (response = {}) => {
        clearApiCache(['ListActionPaths', 'GetActionPath']);
        refetch();
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-success`,
          title: 'Duplicated Action Path',
          message: `${actionPath?.name} has been duplicated.`,
          variant: 'success',
        });
        router?.navigate(`/action-paths/${response?.id}/edit/draft`);
      },
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          title: 'Action Path Duplication Failed',
          message: `Unable to duplicate ${actionPath?.name}.`,
          variant: 'error',
        });
      },
    });
  };

  /**
   * handleDeletion
   *
   * Makes a remote API request to delete the action path.
   *
   * @param actionPath
   * @returns {Promise<void>}
   */
  const handleDeletion = async (actionPath = {}) => {
    const toastId = 'list-action-paths-deletion';
    toast.show({
      id: `${toastId}-info`,
      title: 'Deleting Action Path...',
      message: 'Action path is preparing to delete.',
      isLoading: true,
      ariaLoadingLabel: 'Deleting Action Path',
      variant: 'info',
      autoClose: false,
    });

    await DeleteActionPath(actionPath, {
      onSuccess: () => {
        clearApiCache(['ListActionPaths', 'GetActionPath']);
        refetch();
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-success`,
          title: 'Deleted Action Path',
          message: `${actionPath?.name} has been deleted.`,
          variant: 'success',
        });
      },
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          title: 'Action Path Deletion Failed',
          message: `Unable to delete ${actionPath?.name}.`,
          variant: 'error',
        });
      },
    });
  };

  /**
   * renderCell
   *
   * Displays the cell contents and determines if it should be highlighted or not.
   *
   * @param content
   * @param row
   * @returns {JSX.Element|*}
   */
  const renderCell = (content, row) => {
    if (highlight.includes(row?.original?.id)) {
      return (
        <Box
          color="$tint2"
          css={{
            position: 'absolute',
            padding: 0,
            marginLeft: '-10px',
            marginRight: '-10px',
          }}
        >
          <Layout.Group
            css={{
              height: '100%',
              marginLeft: '6px',
              marginRight: '6px',
            }}
          >
            {content}
          </Layout.Group>
        </Box>
      );
    }
    return content;
  };

  /**
   * renderCellName
   *
   * Displays the name of the action path within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellName = ({ row, cell }) => {
    if (String(row?.original?.status).toLowerCase() === 'active') {
      return renderCell(<Link href={`/action-paths/${row?.original?.id}`}>{cell?.value}</Link>, row);
    }
    return renderCell(cell?.value, row);
  };

  /**
   * renderCellLastUpdated
   *
   * Displays the date and time of when the action path was last updated within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellLastUpdated = ({ row, cell }) => {
    return renderCell(dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss'), row);
  };

  /**
   * renderCellLastUpdatedBy
   *
   * Displays the email address of the person who last modified the action path within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellLastUpdatedBy = ({ row, cell }) => {
    return renderCell(cell?.value, row);
  };

  /**
   * renderCellStatus
   *
   * Displays the current status of the action path within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellStatus = ({ row, cell }) => {
    const badges = {
      active: 'success',
      inactive: 'error',
      draft: 'neutral',
    };

    if (!isUndefined(badges[cell?.value.toLowerCase()])) {
      return renderCell(
        <Badge variant={badges[cell?.value.toLowerCase()]} outline>
          <span style={{ textTransform: 'capitalize' }}>{cell?.value.toLowerCase()}</span>
        </Badge>,
        row
      );
    }

    return renderCell(cell.value, row);
  };

  /**
   * renderCellRemediationType
   *
   * @TODO Needs description.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderRemediationType = ({ row, cell }) => {
    return renderCell(cell.value, row);
  };

  /**
   * renderCellSourcesRemediated
   *
   * Displays a concatenated/truncated list of impacted sources within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellSourcesRemediated = ({ row, cell }) => {
    if (!isEmpty(row?.original?.assignments)) {
      const sources = [];

      row?.original?.assignments.forEach((assignment) => {
        if (!sources.includes(assignment?.impactedSource)) {
          sources.push(assignment?.impactedSource);
        }
      });

      return renderCell(
        <Tooltip placement="auto" content={<SourcesTooltip sources={sources} />}>
          <span className="truncate">{sources.join(', ')}</span>
        </Tooltip>,
        row
      );
    }

    return renderCell(cell.value, row);
  };

  /**
   * renderCellMode
   *
   * displays the mode of the action path within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellMode = ({ row }) => {
    if (row?.original?.manualAssociation === false) {
      return renderCell(
        <Badge
          icon={<IconSymbol variant="outlined" style={{ color: 'inherit' }} size={16} icon="autorenew" />}
          variant="success"
          outline
        >
          Automatic
        </Badge>,
        row
      );
    }

    return renderCell(
      <Badge
        icon={<IconSymbol variant="outlined" style={{ color: 'inherit' }} size={16} icon="back_hand" />}
        variant="warning"
        outline
      >
        Manual
      </Badge>,
      row
    );
  };

  /**
   * canActivate
   *
   * Verifies everything needed exists before allowing the user to click activate.
   *
   * @param row
   * @returns {boolean}
   */
  const canActivate = (row) => {
    if (row?.original?.manualAssociation === true) {
      return true;
    }

    return (
      !isEmpty(row?.original?.criteria?.entrance?.merged) &&
      !isEmpty(row?.original?.assignments) &&
      row?.original?.status === 'DRAFT'
    );
  };

  /**
   * renderCellActions
   *
   * Provides the user with a menu of actionable items to perform on a specific action path within the list of action
   * paths.
   *
   * @param row
   * @returns {JSX.Element}
   */
  const renderCellActions = ({ row }) => {
    const menuItems = [
      {
        title: 'Deactivate',
        onClick: async () => {
          await handleDeactivation(row?.original);
        },
        icon: <IconSymbol variant="outlined" icon="remove_circle_outline" />,
        isSeparated: true,
      },
      {
        title: 'Activate',
        onClick: async () => {
          setIsActivationModalOpen(true);
          setCurrentActionPath(row?.original);
        },
        icon: <IconSymbol variant="outlined" icon="check_circle_outline" />,
        isSeparated: true,
        disabled: canActivate(row) !== true,
      },
      {
        title: 'Duplicate',
        onClick: async () => {
          setIsDuplicationModalOpen(true);
          setCurrentActionPath(row?.original);
        },
        icon: <IconSymbol variant="outlined" icon="content_copy" />,
      },
      {
        title: 'Edit',
        onClick: async () => {
          router?.navigate(`/action-paths/${row?.original?.id}/edit/${String(row?.original?.status).toLowerCase()}`);
        },
        icon: <IconSymbol variant="outlined" icon="edit" />,
        disabled: !!['ACTIVE', 'INACTIVE'].includes(row?.original?.status),
      },
      {
        title: 'Delete',
        onClick: async () => {
          setIsDeletionModalOpen(true);
          setCurrentActionPath(row?.original);
        },
        icon: <IconSymbol variant="outlined" icon="delete" />,
        disabled: row?.original?.status !== 'DRAFT',
      },
    ].filter((menuItem) => {
      if (menuItem.title === 'Deactivate' && row?.original?.status === 'INACTIVE') {
        return false;
      }
      if (menuItem.title === 'Activate' && row?.original?.status === 'ACTIVE') {
        return false;
      }
      return !(['Deactivate'].includes(menuItem.title) && !['ACTIVE'].includes(row?.original?.status));
    });

    return (
      <div
        style={{
          marginLeft: '-10px',
          marginRight: '-10px',
        }}
      >
        <Layout.Group
          css={{
            height: '100%',
            marginLeft: '2px',
            marginRight: '2px',
          }}
        >
          <DropdownMenu
            hideLabel
            before={<IconSymbol variant="outlined" icon="more_vert" size={24} color="#6F6F6F" />}
            menuItems={menuItems}
          />
        </Layout.Group>
      </div>
    );
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      switch (column.Header) {
        case 'Name':
          column.Cell = renderCellName;
          break;
        case 'Last Updated':
          column.Cell = renderCellLastUpdated;
          break;
        case 'Last Updated By':
          column.Cell = renderCellLastUpdatedBy;
          break;
        case 'Status':
          column.Cell = renderCellStatus;
          break;
        case 'Remediation Type':
          column.Cell = renderRemediationType;
          break;
        case 'Source(s) Remediated':
          column.Cell = renderCellSourcesRemediated;
          break;
        case 'actions':
          column.Cell = renderCellActions;
          break;
        case 'Mode':
          column.Cell = renderCellMode;
          break;
        default:
          break;
      }

      if (column.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      return column;
    });
  }, []);

  /**
   * Reset the reload status if it was already set once.
   */
  useLayoutEffect(() => {
    if (reload === true) {
      setReload(false);
    }
  }, [reload]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/List/components/Table/Table.jsx">
      <Styles>
        <TableComponent
          {...{
            columns,
            configuration,
            error,
            headerLeft,
            isLoading,
            reload,
            requestArgs,
            requestFunction,
            requestKey,
            rows,
            setReload,
            totalPages,
            totalRecords,
          }}
        />
        {isActivationModalOpen && !isEmpty(currentActionPath) && (
          <ActivationModal
            isOpen={isActivationModalOpen}
            setIsOpen={setIsActivationModalOpen}
            actionPath={currentActionPath}
            setActionPath={setCurrentActionPath}
            handleActivation={handleActivation}
          />
        )}
        {isDuplicationModalOpen && !isEmpty(currentActionPath) && (
          <DuplicationModal
            isOpen={isDuplicationModalOpen}
            setIsOpen={setIsDuplicationModalOpen}
            actionPath={currentActionPath}
            setActionPath={setCurrentActionPath}
            handleDuplication={handleDuplication}
          />
        )}
        <DeletionModal
          isOpen={isDeletionModalOpen}
          setIsOpen={setIsDeletionModalOpen}
          actionPath={currentActionPath}
          setActionPath={setCurrentActionPath}
          handleDeletion={handleDeletion}
        />
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.number,
  }),
  headerLeft: PropTypes.node,
  isLoading: PropTypes.bool,
  refetch: PropTypes.func,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      remediationType: PropTypes.string,
      assignments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          impactedSource: PropTypes.string,
        })
      ),
      entranceCriteria: PropTypes.string,
      manualAssociation: PropTypes.bool,
    })
  ),
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  error: {},
  headerLeft: null,
  isLoading: false,
  refetch: () => {},
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  totalPages: 1,
  totalRecords: 0,
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isUndefined } from 'lodash';

/**
 * CopyActionPath
 *
 * Duplicates a single action path from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const CopyActionPath = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': `/action-paths`,
      },
      data: {},
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ mutationKey: payload[0] }, ...payload[1] };
      requestArgs.headers['x-api-endpoint'] += `/${payload[1]?.id}/duplicate`;
    }

    if (!isEmpty(payload[1]?.name)) {
      requestArgs.data.actionPathName = payload[1]?.name;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/CopyActionPath.js -> CopyActionPath() -> error:', theError);

    throw error;
  }
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isUndefined } from 'lodash';

/**
 * RiskRecordTagAssignment
 *
 * Assign user created tags to a risk record.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const RiskRecordTagAssignment = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/risk-records',
      },
      data: {},
    };

    const thePayload = {
      ...payload?.[1],
    };

    if (!isUndefined(payload?.[0])) {
      requestArgs.params = { ...{ mutationKey: payload?.[0] } };
    }

    if (!isUndefined(thePayload?.eid) && !isUndefined(thePayload?.tagCode)) {
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.eid}/assign/${thePayload?.tagCode}`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/RiskRecordTagAssignment.js -> RiskRecordTagAssignment() -> error:', theError);

    throw error;
  }
};

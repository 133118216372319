import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.remediation-assignments-retryInterval.abyss-text-input-left-add-on': {
    padding: 0,
  },
  '.remediation-assignments-retryInterval': {
    "*[class*='isInvalid-true']": {
      position: 'absolute',
      right: '-3px',
      bottom: '10px',
      marginRight: 'var(--abyss-space-md)',
      padding: '4px',
      zIndex: '999',
      backgroundColor: 'var(--abyss-colors-error2)',
      border: '1px solid var(--abyss-colors-error1)',
    },
    '.remediation-assignments-retryIntervalUnit': {
      border: 'none',
      '&.abyss-select-input-root': {
        width: '105px',
      },
      "*[class*='isInvalid-true']": {
        position: 'absolute',
        top: 'calc(100% + 1px)',
        right: 'unset',
        bottom: 'unset',
        marginRight: 'var(--abyss-space-lg)',
        padding: '4px',
        zIndex: '999',
        backgroundColor: 'var(--abyss-colors-error2)',
        border: '1px solid var(--abyss-colors-error1)',
      },
    },
  },
  '.abyss-data-table-root': {
    marginTop: 'var(--abyss-space-sm)',
  },
  td: {
    padding:
      'calc(var(--abyss-space-lg) - 5px) var(--abyss-space-md) calc(var(--abyss-space-lg) + 5px) var(--abyss-space-md)',
  },
  '#removeField': {
    color: 'var(--abyss-colors-error1) !important',
    fontWeight: 'bold !important',
    '.abyss-icon': {
      marginTop: 'var(--abyss-space-xs)',
      marginRight: 'var(--abyss-space-xs)',
      color: 'var(--abyss-colors-error1) !important',
    },
    'span.abyss-icon-symbol:first-of-type': {
      display: 'none',
      visibility: 'hidden',
    },
    'span.abyss-icon-symbol:last-of-type': {
      display: 'inline-block',
      visibility: 'visible',
    },
    '&:hover': {
      'span.abyss-icon-symbol:first-of-type': {
        color: 'var(--abyss-colors-error1)',
        display: 'inline-block',
        visibility: 'visible',
      },
      'span.abyss-icon-symbol:last-of-type': {
        display: 'none',
        visibility: 'hidden',
      },
    },
  },
});

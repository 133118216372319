import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { useFormFieldArray } from '@abyss/web/hooks/useFormFieldArray';
import { useRoutesContext } from '@src/context/Routes';
import { Styles } from './includes/styles';
import { Table } from './components/Table';
import { useAssets } from './hooks/useAssets';

/**
 * Filters
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Filters = (props) => {
  const { form, filterKey, headerLeft, isDisabled } = props;

  const { data: assets, isLoading: isLoadingAssets } = useAssets();

  const { currentRoute } = useRoutesContext();

  const { fields, append, remove, replace } = useFormFieldArray({
    control: form.control,
    name: filterKey,
  });

  /**
   * handleReset
   *
   * Resets the user specified filters from the data table.
   *
   * @returns {Promise<void>}
   */
  const handleReset = async () => {
    await form.setValue(filterKey, []);
    replace([]);
  };

  return (
    <ErrorHandler location="src/components/Filters/Filters.jsx">
      <Styles>
        <Grid>
          <Grid.Col span={{ xs: '50%' }}>
            <Flex
              className="topRow"
              justify="flex-start"
              alignItems="flex-end"
              alignContent="flex-start"
              direction="row"
            >
              {headerLeft}
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ xs: '50%' }} css={{ paddingBottom: 0 }}>
            <Layout.Group alignLayout="right" alignItems="bottom" css={{ height: '100%' }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                variant="custom"
                id="addField"
                className={isDisabled ? 'isDisabled-true' : ''}
                onClick={() => {
                  append({ column: '', condition: '', value: '' });
                }}
                css={{ height: '100%' }}
                isDisabled={isDisabled}
              >
                <Flex alignItems="center" css={{ height: '100%' }}>
                  <div>
                    <IconSymbol icon="add_circle" variant="filled" />
                    <IconSymbol icon="add_circle" variant="outlined" />
                  </div>
                  <div>Add Filter</div>
                </Flex>
              </Link>
            </Layout.Group>
          </Grid.Col>
          <Grid.Col span={{ xs: '100%' }} css={{ paddingTop: 0 }}>
            <Table
              {...{
                append,
                assets,
                dataKey: `${currentRoute?.slug}-filters`,
                fields,
                filterKey,
                form,
                handleReset,
                isDisabled,
                isLoading: isLoadingAssets,
                remove,
                replace,
              }}
            />
          </Grid.Col>
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

Filters.propTypes = {
  filterKey: PropTypes.string,
  form: PropTypes.shape({
    control: PropTypes.shape({}),
    setValue: PropTypes.func,
  }),
  headerLeft: PropTypes.element,
  isDisabled: PropTypes.bool,
};

Filters.defaultProps = {
  filterKey: 'filters',
  form: {
    control: {},
    setValue: () => {},
  },
  headerLeft: null,
  isDisabled: false,
};

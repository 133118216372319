import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-end-date-input-container': {
    '.abyss-date-picker-input-root': {
      '.abyss-layout-group': {
        visibility: 'hidden',
      },
    },
  },
});

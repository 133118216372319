import { config } from '@abyss/web/tools/config';
import { useApi } from '@src/context/Api';
import { useCallback } from 'react';
import { useToast } from '@abyss/web/hooks/useToast';
import modes from './includes/modes.json';

/**
 * useSave
 *
 * Makes an API request to save the common criteria.
 *
 * @param mode
 * @returns {(function({}=, function()=): Promise<void>)|*}
 */
export const useSave = (mode = null) => {
  const currentMode = modes?.[mode];

  const { useApiMutation, clearApiCache } = useApi();
  const [SaveCommonCriteria] = useApiMutation('SaveCommonCriteria');

  const { toast } = useToast();

  return useCallback(async (submittedValues = {}, refetch = () => {}) => {
    try {
      const toastId = `common-criteria-${mode}`;

      toast.show({
        id: `${toastId}-info`,
        title: currentMode?.info?.title,
        message: currentMode?.info?.message,
        isLoading: true,
        variant: 'info',
        autoClose: false,
      });

      const payload = {
        ...submittedValues,
        ...{
          action: mode,
        },
      };

      await SaveCommonCriteria(payload, {
        onSuccess: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            title: currentMode?.success?.title,
            message: currentMode?.success?.message,
            variant: 'success',
          });
          clearApiCache(['ListCommonCriteria', 'ListCommonCriteriaVersions']);
          refetch();
        },
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            title: currentMode?.error?.title,
            message: currentMode?.error?.message,
            variant: 'error',
          });
        },
      });
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error('src/routes/private/Admin/screens/CommonCriteria/hooks/useSave.js -> useSave():', theError);
    }
  }, []);
};

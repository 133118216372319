import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';

/**
 * Mode
 *
 * displays the mode of the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Mode = (props) => {
  const { actionPath } = props;

  if (actionPath?.manualAssociation === false) {
    return (
      <Badge
        icon={<IconSymbol variant="outlined" style={{ color: 'inherit' }} size={16} icon="autorenew" />}
        variant="success"
        outline
      >
        Automatic
      </Badge>
    );
  }
  return (
    <Badge
      icon={<IconSymbol variant="outlined" style={{ color: 'inherit' }} size={16} icon="back_hand" />}
      variant="warning"
      outline
    >
      Manual
    </Badge>
  );
};

Mode.propTypes = {
  actionPath: PropTypes.shape({
    manualAssociation: PropTypes.bool,
  }),
};

Mode.defaultProps = {
  actionPath: {
    manualAssociation: false,
  },
};

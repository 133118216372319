/**
 * ACTIONS
 *
 * @TODO Needs description.
 *
 * @type {{UPDATE_COMPONENT: string}}
 */
export const ACTIONS = {
  UPDATE_COMPONENT: 'updateComponent',
};

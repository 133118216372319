import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import { FilterCriteria as Table } from '@src/common/tables';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { useApi } from '@src/context/Api';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { abbrNum } from '@src/includes/functions';
import fields from '@src/routes/private/ActionPaths/components/Wizard/steps/ExitCriteria/components/fields/includes/fields.json';
import { Styles } from './includes/styles';

const { exitCriteriaMethod: exitCriteriaMethodField } = fields;

/**
 * Widget: FilterCriteria
 *
 * @TODO Needs description
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const FilterCriteria = (props) => {
  const { filterKey, criteria, context, criteriaMethod } = props;

  const [count, setCount] = useState(null);

  const { useApiQuery } = useApi();
  const [GetRiskRecordsCount, { data: riskRecordsCount }] = useApiQuery('GetRiskRecordsCount');

  /**
   * Get the total number of risk records if not already fetched.
   */
  useEffect(() => {
    if (isUndefined(riskRecordsCount) && !isEmpty(criteria?.[context]?.merged)) {
      GetRiskRecordsCount({ criteria });
    }
  }, [riskRecordsCount, criteria]);

  useEffect(() => {
    if (!isUndefined(riskRecordsCount)) {
      if (context === 'entrance') {
        setCount(riskRecordsCount?.entranceCriteriaCount);
      }

      if (context === 'exit') {
        setCount(riskRecordsCount?.exitCriteriaCount);
      }
    }
  }, [riskRecordsCount]);

  /**
   * add the type of criteria to the rows
   * @type {*[]}
   */
  const rows = useMemo(() => {
    const theRows = [];

    // add additional criteria
    if (!isEmpty(criteria?.[context]?.additional)) {
      criteria?.[context]?.additional.forEach((row) => {
        const theRow = {
          ...row,
          ...{
            type: 'additional',
          },
        };
        if (!theRows.includes(theRow)) {
          theRows.push(theRow);
        }
      });
    }

    // add common criteria
    if (!isEmpty(criteria?.[context]?.common)) {
      criteria?.[context]?.common.forEach((row) => {
        const theRow = {
          ...row,
          ...{
            type: 'common',
          },
        };
        if (!theRows.includes(theRow)) {
          theRows.push(theRow);
        }
      });
    }

    return theRows;
  }, [criteria]);

  let title = '';

  if (context === 'entrance') {
    title = 'Entrance Criteria';
  }

  if (context === 'exit') {
    title = 'Exit Criteria';
  }

  const theCriteriaMethod = useMemo(() => {
    return exitCriteriaMethodField?.options?.find((option) => {
      return option?.value === criteriaMethod;
    });
  }, [criteriaMethod]);

  return (
    <ErrorHandler location="src/common/widgets/FilterCriteria/FilterCriteria.jsx">
      <Styles>
        <Widget
          title={title}
          description=""
          button={
            !isUndefined(riskRecordsCount) &&
            !isNull(count) && (
              <Badge outline variant="info">
                {abbrNum(count)} records
              </Badge>
            )
          }
        >
          {isEmpty(criteriaMethod) || criteriaMethod === 'EXIT_CRITERIA_MET' ? (
            <Table rows={rows || []} filterKey={filterKey} />
          ) : (
            theCriteriaMethod?.label
          )}
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

FilterCriteria.propTypes = {
  criteria: PropTypes.shape({
    merged: PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      })
    ),
  }),
  filterKey: PropTypes.string,
  context: PropTypes.string,
  criteriaMethod: PropTypes.string,
};

FilterCriteria.defaultProps = {
  criteria: {
    merged: [],
  },
  filterKey: 'filters',
  context: '',
  criteriaMethod: '',
};

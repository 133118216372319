import { config } from '@abyss/web/tools/config';
import { useApi } from '@src/context/Api';
import { useCallback } from 'react';
import { useToast } from '@abyss/web/hooks/useToast';

/**
 * useSave
 *
 * Makes an API request to save the action path.
 *
 * @param mode
 * @returns {(function({}=, function()=): Promise<void>)|*}
 */
export const useSave = (mode = null) => {
  const { useApiMutation, clearApiCache } = useApi();
  const [SaveActionPath, mutation] = useApiMutation('SaveActionPath');

  const { toast } = useToast();

  const mutate = useCallback(async (submittedValues = {}, refetch = () => {}) => {
    try {
      const toastId = `action-paths-${mode}`;

      toast.show({
        id: `${toastId}-info`,
        title: `Saving Action Path`,
        message: 'Preparing to save the Action Path.',
        isLoading: true,
        variant: 'info',
        autoClose: false,
      });

      const payload = {
        ...submittedValues,
        ...{
          action: mode,
        },
      };

      await SaveActionPath(payload, {
        onSuccess: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            title: `Saved Action Path`,
            message: 'Successfully saved the Action Path.',
            variant: 'success',
          });
          clearApiCache(['ListActionPaths', 'GetActionPath']);
          refetch();
        },
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            title: `Error Saving Action Path`,
            message: 'An error occurred while saving the Action Path.',
            variant: 'error',
          });
        },
      });
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/ActionPaths/components/Wizard/hooks/useSave/useSave.js -> useSave():',
        theError
      );
    }
  }, []);

  return { handleSave: mutate, isSaving: mutation?.isPending };
};

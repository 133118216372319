import { CopyActionPath } from './CopyActionPath';
import { DeleteActionPath } from './DeleteActionPath';
import { Logger } from './Logger';
import { ReEvaluateActionPath } from './ReEvaluateActionPath';
import { RiskRecordActionPathAssociation } from './RiskRecordActionPathAssociation';
import { RiskRecordActionPathDisassociation } from './RiskRecordActionPathDisassociation';
import { RiskRecordTagAssignment } from './RiskRecordTagAssignment';
import { RiskRecordTagUnassignment } from './RiskRecordTagUnassignment';
import { SaveActionPath } from './SaveActionPath';
import { SaveActionPathNote } from './SaveActionPathNote';
import { SaveAlert } from './SaveAlert';
import { SaveAlertConfiguration } from './SaveAlertConfiguration';
import { SaveCode } from './SaveCode';
import { SaveCodeCategory } from './SaveCodeCategory';
import { SaveCommonCriteria } from './SaveCommonCriteria';
import { SaveEvent } from './SaveEvent';
import { SaveTag } from './SaveTag';
import { ScheduleExport } from './ScheduleExport';

/**
 * mutations
 *
 * @type {{RiskRecordActionPathDisassociation: ((function({}=): Promise<*>)|*), SaveActionPath: ((function({}=):
 *   Promise<{}>)|*), SaveActionPathNote: ((function({}=): Promise<*>)|*), SaveCode: ((function({}=): Promise<{}>)|*),
 *   SaveEvent: ((function({}=): Promise<{}>)|*), SaveTag: ((function({}=): Promise<{}>)|*), SaveCodeCategory:
 *   ((function({}=): Promise<{}>)|*), Logger: ((function(*): Promise<{}>)|*), CopyActionPath: ((function({}=):
 *   Promise<*>)|*), SaveCommonCriteria: ((function({}=): Promise<{}>)|*), RiskRecordActionPathAssociation:
 *   ((function({}=): Promise<*>)|*), RiskRecordTagUnassignment: ((function({}=): Promise<*>)|*), ReEvaluateActionPath:
 *   ((function({}=): Promise<*>)|*), RiskRecordTagAssignment: ((function({}=): Promise<*>)|*), ScheduleExport:
 *   ((function({}=): Promise<*|string>)|*), DeleteActionPath: ((function({}=): Promise<*>)|*), SaveAlert:
 *   ((function({}=): Promise<{}>)|*), SaveAlertConfiguration: ((function({}=): Promise<{}>)|*)}}
 */
export const mutations = {
  CopyActionPath,
  DeleteActionPath,
  Logger,
  ReEvaluateActionPath,
  RiskRecordActionPathAssociation,
  RiskRecordActionPathDisassociation,
  RiskRecordTagAssignment,
  RiskRecordTagUnassignment,
  SaveActionPath,
  SaveActionPathNote,
  SaveAlert,
  SaveAlertConfiguration,
  SaveCode,
  SaveCodeCategory,
  SaveCommonCriteria,
  SaveEvent,
  SaveTag,
  ScheduleExport,
};

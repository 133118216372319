import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import { chartColors } from '@src/components/Chart/includes/chartColors';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isUndefined, shuffle } from 'lodash';
import { Scatter } from 'react-chartjs-2';
import { Visibility } from '@src/components/Visibility';
import { getTooltipFooter, getTooltipLabel, getTooltipTitle } from '../../includes/functions';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

/**
 * ScatterChart
 *
 * A scatter chart is a graphical representation of data points plotted on a two-dimensional coordinate system,
 * typically used to display relationships between two variables.
 *
 * @param props
 * @returns {React.JSX.Element|`\nValue: ${string}\nPercentage: ${*}%\n`|*}
 * @constructor
 */
export const ScatterChart = (props) => {
  const { data, legendLimit, showLegend } = props;

  const [datasets, setDatasets] = useState([]);

  /**
   * Defines an array of colors and patterns to use for rendering the datasets.
   *
   * @type {Array<CanvasPattern>}
   */
  const patterns = useMemo(() => {
    return shuffle(chartColors).map((colorName) => {
      return themeConfiguration?.theme?.colors?.[`${colorName}`];
    });
  }, [themeConfiguration]);

  /**
   * Configure the labels and datasets to be used by the chart.
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const theDataset = [];

      data?.forEach((item) => {
        const dataset = {
          label: item?.name,
          data: [
            {
              x: item?.value,
              y: item?.yvalue,
            },
          ],
          backgroundColor: patterns,
        };

        theDataset.push(dataset);
      });

      if (theDataset !== datasets) {
        setDatasets(theDataset);
      }
    }
  }, [data]);

  return (
    <ErrorHandler location="src/components/Chart/components/ScatterChart/ScatterChart.jsx">
      <Visibility>
        <Scatter
          options={{
            animation: {
              duration: 0, // general animation time
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: isUndefined(showLegend) ? !isUndefined(legendLimit) && datasets.length <= legendLimit : true,
              },
              tooltip: {
                callbacks: {
                  label(args) {
                    return getTooltipLabel(args, data);
                  },
                  title(args) {
                    return getTooltipTitle(args, data);
                  },
                  footer(args) {
                    return getTooltipFooter(args, data);
                  },
                },
              },
            },
          }}
          data={{
            datasets,
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

ScatterChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  legendLimit: PropTypes.number,
  showLegend: PropTypes.bool,
};

ScatterChart.defaultProps = {
  legendLimit: 10,
  showLegend: true,
};

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { RepeatableFields } from '@src/components/RepeatableFields';
import { Tabs as AbyssTabs } from '@abyss/web/ui/Tabs';
import { Styles } from './includes/styles';

/**
 * Tabs
 *
 * Displays a list of tabs to configure alerts by type.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Tabs = (props) => {
  const { currentTab, setCurrentTab, tabs, form } = props;

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/AlertConfig/components/Tabs/Tabs.jsx">
      <Styles>
        <AbyssTabs grow={false}>
          {tabs?.map((tab) => {
            return (
              <AbyssTabs.Tab
                key={tab?.title}
                label={tab?.label}
                subText={tab?.subText}
                active={currentTab}
                onTabChange={setCurrentTab}
              >
                <Grid>
                  <Grid.Col span={{ xs: '100%' }}>
                    <RepeatableFields
                      accessor={tab?.accessor}
                      defaultValues={tab?.defaultValues}
                      disabledFields={tab?.disabledFields}
                      fields={tab?.fields}
                      form={form}
                      placeholderValues={tab?.placeholderValues}
                      values={tab?.values}
                    />
                  </Grid.Col>
                  <Grid.Col span={{ xs: '100%' }}>
                    <Layout.Group alignLayout="right">
                      <Button type="submit" variant="solid">
                        Save
                      </Button>
                    </Layout.Group>
                  </Grid.Col>
                </Grid>
              </AbyssTabs.Tab>
            );
          })}
        </AbyssTabs>
      </Styles>
    </ErrorHandler>
  );
};

Tabs.propTypes = {
  currentTab: PropTypes.string,
  setCurrentTab: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      defaultValues: PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      }),
      disabledFields: PropTypes.arrayOf(PropTypes.string),
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          accessor: PropTypes.string,
          component: PropTypes.elementType,
          defaultValue: PropTypes.string,
          disabled: PropTypes.bool,
          label: PropTypes.string,
          name: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              value: PropTypes.string,
            })
          ),
          placeholder: PropTypes.string,
          required: PropTypes.bool,
          type: PropTypes.string,
        })
      ),
      label: PropTypes.string,
      placeholderValues: PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      }),
      subText: PropTypes.string,
      values: PropTypes.shape({
        [PropTypes.string]: PropTypes.string,
      }),
    })
  ),
  form: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
};

Tabs.defaultProps = {
  currentTab: '',
  setCurrentTab: () => {},
  tabs: [],
  form: {},
};

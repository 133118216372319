import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { RiskRecords as Table } from '@src/common/tables';

/**
 * RiskRecords
 *
 * Provides the user with a screen to browse the risk records found based on the entrance criteria (filters) specified.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const RiskRecords = (props) => {
  const { actionPath, currentStep, form } = props;

  const criteria = form?.getValues('criteria');

  if (!criteria) {
    return null;
  }

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/RiskRecords/RiskRecords.jsx">
      <Grid>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <Flex direction="column" alignContent="flex-start">
            <Flex direction="row" alignItems="center">
              <Heading offset={1}>{currentStep?.label}</Heading>
            </Flex>
            <p>{currentStep?.description}</p>
          </Flex>
        </Grid.Col>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <Table
            allowExport
            currentEntity={{
              id: actionPath?.id,
              name: actionPath?.name,
              type: 'actionPath',
            }}
            criteria={criteria}
            showAnalyzeRisks
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

RiskRecords.propTypes = {
  actionPath: PropTypes.shape({
    criteria: PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
      })
    ),
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  currentStep: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
};

RiskRecords.defaultProps = {
  actionPath: {
    criteria: [],
    id: '',
    name: '',
  },
  currentStep: {
    label: '',
    description: '',
  },
  form: {},
};

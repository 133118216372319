import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { AdditionalCriteriaWidget } from '@src/common/widgets/AdditionalCriteria';
import { CommonCriteriaWidget } from '@src/common/widgets/CommonCriteria';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { useCommonCriteria } from '@src/hooks/useCommonCriteria';
import { useCommonCriteriaVersions } from '@src/hooks/useCommonCriteriaVersions';
import { ExitCriteriaMethodField } from './components/fields/ExitCriteriaMethod';

/**
 * ExitCriteria
 *
 * Provides the user with a screen to specify a list of filters to find risk records by.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ExitCriteria = (props) => {
  const { currentStep, form, mode, actionPath } = props;

  const { fetch: fetchCriteria, criteriaList } = useCommonCriteria();
  const { fetch: fetchCriteriaVersions, versionsList } = useCommonCriteriaVersions();

  const exitCriteriaMethodValue = form.getValues('exitCriteriaMethod');

  /**
   * retrieve common criteria and common criteria versions saved to the action path
   */
  useEffect(() => {
    if (!isUndefined(actionPath?.criteria) && !isEmpty(actionPath?.criteria)) {
      fetchCriteria(actionPath?.criteria);
      fetchCriteriaVersions(actionPath?.criteria);
    }
  }, [actionPath]);

  /**
   * loop through criteriaList and versionsList to determine if the versions are a mismatch
   * @type {*[]}
   */
  const needsUpdate = useMemo(() => {
    const items = {};

    if (!isEmpty(criteriaList) && !isEmpty(versionsList)) {
      criteriaList
        ?.filter((item) => {
          return item?.isActive;
        })
        .forEach((criteria) => {
          const version = versionsList.find((item) => {
            return criteria?.id === item?.commonCriteriaId;
          });

          if (!isUndefined(version) && criteria?.activeVersionNbr !== version?.version) {
            items[criteria?.id] = {
              from: version,
              to: criteria?.activeCommonCriteriaVersion,
            };
          }
        });
    }

    return items;
  }, [criteriaList, versionsList, actionPath]);

  /**
   * loop through criteriaList and versionsList to determine if the versions have been deactivated
   * @type {*[]}
   */
  const needsRemoval = useMemo(() => {
    const items = {};

    if (!isEmpty(criteriaList) && !isEmpty(versionsList)) {
      criteriaList.forEach((criteria) => {
        const version = versionsList.find((item) => {
          return criteria?.id === item?.commonCriteriaId;
        });

        if (!isUndefined(version) && criteria?.isActive === false) {
          items[criteria?.id] = {
            criteria,
            version,
          };
        }
      });
    }

    return items;
  }, [criteriaList, versionsList, actionPath]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/ExitCriteria/ExitCriteria.jsx">
      <Grid>
        <Grid.Col css={{ paddingTop: 'var(--abyss-space-lg)' }} span={{ xs: '100%' }}>
          <Layout.Stack space={0} grow>
            <Heading offset={1}>{currentStep?.label}</Heading>
            <p>{currentStep?.description}</p>
          </Layout.Stack>
        </Grid.Col>

        <Grid.Col span={{ xs: '100%' }}>
          <Layout.Group>
            <ExitCriteriaMethodField form={form} />
          </Layout.Group>
        </Grid.Col>

        {exitCriteriaMethodValue === 'EXIT_CRITERIA_MET' && [
          <Grid.Col span={{ xs: '100%' }}>
            <CommonCriteriaWidget
              form={form}
              filterKey="criteria.exit.common"
              context="exit"
              showActions={mode === 'edit'}
              needsUpdate={needsUpdate}
              needsRemoval={needsRemoval}
            />
          </Grid.Col>,
          <Grid.Col span={{ xs: '100%' }}>
            <AdditionalCriteriaWidget form={form} filterKey="criteria.exit.additional" />
          </Grid.Col>,
        ]}
      </Grid>
    </ErrorHandler>
  );
};

ExitCriteria.propTypes = {
  currentStep: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
    setValue: PropTypes.func,
  }),
  mode: PropTypes.string,
  actionPath: PropTypes.shape({
    criteria: PropTypes.shape({
      entrance: PropTypes.shape({
        commonIds: PropTypes.arrayOf(PropTypes.string),
        commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
      }),
      exit: PropTypes.shape({
        commonIds: PropTypes.arrayOf(PropTypes.string),
        commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
};

ExitCriteria.defaultProps = {
  currentStep: {},
  form: {},
  mode: '',
  actionPath: {},
};

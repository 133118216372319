import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Card } from '@abyss/web/ui/Card';
import { config } from '@abyss/web/tools/config';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { AssignTags, AssociatedEids, AttachActionPaths, RiskCode, RiskScore } from './components';

/**
 * Details
 *
 * Displays a summary of the EID search results.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Details = (props) => {
  const { eid, ireRiskRecord, riskCodes, associatedEids, refetchRiskRecord, actionPaths, tags } = props;

  const [isAssociatedEidsOpen, setIsAssociatedEidsOpen] = useState(false);
  const [isAttachActionPathsOpen, setIsAttachActionPathsOpen] = useState(false);
  const [isAssignTagsOpen, setIsAssignTagsOpen] = useState(false);

  const { roles } = useCurrentUser();

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Details/Details.jsx">
      <Card size="small" css={{ padding: 'var(--abyss-space-lg)', boxShadow: 'none' }}>
        <Grid>
          <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '60%' }} css={{ margin: '0px auto' }}>
            <Grid>
              <Grid.Col span={{ xs: '25%' }} css={{ borderRight: '1px solid var(--abyss-colors-gray4);' }}>
                <Layout.Stack alignLayout="center">
                  <div>EID</div>
                  <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                    <Text fontWeight="bold">{eid}</Text>
                  </div>
                </Layout.Stack>
              </Grid.Col>

              <Grid.Col span={{ xs: '25%' }} css={{ borderRight: '1px solid var(--abyss-colors-gray4);' }}>
                <Layout.Stack alignLayout="center">
                  <div>Risk Score</div>
                  <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                    <RiskScore riskCode={ireRiskRecord?.riskScore} />
                  </div>
                </Layout.Stack>
              </Grid.Col>

              <Grid.Col span={{ xs: '25%' }} css={{ borderRight: '1px solid var(--abyss-colors-gray4);' }}>
                <Layout.Stack alignLayout="center">
                  <div>Risk Code</div>
                  <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                    <Text fontWeight="bold">
                      <RiskCode riskCode={ireRiskRecord?.riskScore} riskCodes={riskCodes} />
                    </Text>
                  </div>
                </Layout.Stack>
              </Grid.Col>

              <Grid.Col span={{ xs: '25%' }}>
                <Flex
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                  direction="column"
                  css={{ height: '100%' }}
                >
                  <DropdownMenu
                    outline
                    label="Actions"
                    after={<IconSymbol icon="keyboard_arrow_down" />}
                    menuItems={[
                      {
                        title: 'Link to HCM',
                        icon: <IconSymbol icon="open_in_new" variant="outlined" />,
                        onClick: async () => {
                          window?.open(`${config('HCM_URL')}/${eid}/`, '_blank').focus();
                        },
                      },
                      {
                        title: "View Associated EID's",
                        icon: <IconSymbol icon="visibility" variant="outlined" />,
                        onClick: () => {
                          setIsAssociatedEidsOpen(true);
                        },
                        disabled: isUndefined(associatedEids) || isEmpty(associatedEids),
                      },
                      {
                        title: 'Attach Action Paths',
                        icon: <IconSymbol icon="attach_file" variant="outlined" />,
                        onClick: () => {
                          setIsAttachActionPathsOpen(true);
                        },
                        disabled: !roles.includes('State.Write'),
                      },
                      {
                        title: 'Assign Tags',
                        icon: <IconSymbol icon="sell" variant="outlined" />,
                        onClick: () => {
                          setIsAssignTagsOpen(true);
                        },
                        disabled: !roles.includes('State.Write'),
                      },
                    ]}
                  />
                </Flex>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Card>

      {isAssociatedEidsOpen && (
        <AssociatedEids
          associatedEids={associatedEids}
          isOpen={isAssociatedEidsOpen}
          setIsOpen={setIsAssociatedEidsOpen}
        />
      )}

      {roles.includes('State.Write') && isAttachActionPathsOpen && (
        <AttachActionPaths
          eid={eid}
          isOpen={isAttachActionPathsOpen}
          recordActionPaths={actionPaths}
          refetchRiskRecord={refetchRiskRecord}
          setIsOpen={setIsAttachActionPathsOpen}
        />
      )}

      {roles.includes('State.Write') && isAssignTagsOpen && (
        <AssignTags
          eid={eid}
          isOpen={isAssignTagsOpen}
          recordTags={tags}
          refetchRiskRecord={refetchRiskRecord}
          setIsOpen={setIsAssignTagsOpen}
        />
      )}
    </ErrorHandler>
  );
};

Details.propTypes = {
  eid: PropTypes.string.isRequired,
  ireRiskRecord: PropTypes.shape({
    riskScore: PropTypes.number,
  }),
  riskCodes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.number,
      description: PropTypes.string,
    })
  ),
  associatedEids: PropTypes.arrayOf(PropTypes.string),
  refetchRiskRecord: PropTypes.func,
  actionPaths: PropTypes.arrayOf(
    PropTypes.shape({
      actionPath: PropTypes.string,
      actionPathId: PropTypes.string,
    })
  ),
  tags: PropTypes.arrayOf(PropTypes.string),
};

Details.defaultProps = {
  ireRiskRecord: {},
  riskCodes: [],
  associatedEids: [],
  refetchRiskRecord: () => {},
  actionPaths: [],
  tags: [],
};

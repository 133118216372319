import PropTypes from 'prop-types';
import React from 'react';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Alert } from '@abyss/web/ui/Alert';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Indicator } from '@abyss/web/ui/Indicator';
import { isEmpty } from 'lodash';
import { Widget } from '@src/components/Widget';
import { RecommendationResponses } from './components/RecommendationResponses';
import { Styles } from './includes/styles';
import { Details } from './components/Details';

/**
 * ActionPaths
 *
 * Displays affiliated action paths with the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ActionPaths = (props) => {
  const { actionPaths, refetchRiskRecord, eid } = props;

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/ActionPaths/ActionPaths.jsx">
      <Styles>
        <Widget
          title="Action Paths"
          description="History of Action Paths EID has been assigned to"
          collapsible
          collapsed={false}
        >
          {isEmpty(actionPaths) ? (
            <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}>
              <Alert title="There are no actions paths for this risk record." variant="info" />
            </Grid.Col>
          ) : (
            <Accordion type="multiple" isCollapsible>
              {actionPaths.map((actionPath) => {
                return (
                  <Accordion.Item key={`${actionPath?.name}`} value={actionPath?.name}>
                    <Accordion.Trigger>
                      <Accordion.Header>
                        {actionPath?.manualAssociation === true ? (
                          <div style={{ width: 'auto' }}>
                            <Indicator label="M" showZero={false} withBorder color="var(--abyss-colors-warning1)">
                              <div style={{ marginRight: 'var(--abyss-space-sm)' }}> {actionPath?.name}</div>
                            </Indicator>
                          </div>
                        ) : (
                          actionPath?.name
                        )}
                      </Accordion.Header>
                    </Accordion.Trigger>
                    <Accordion.Content>
                      <Details actionPath={actionPath} refetchRiskRecord={refetchRiskRecord} eid={eid} />
                      <RecommendationResponses actionPath={actionPath} />
                    </Accordion.Content>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          )}
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

ActionPaths.propTypes = {
  actionPaths: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      manualAssociation: PropTypes.bool,
    })
  ),
  refetchRiskRecord: PropTypes.func,
  eid: PropTypes.string,
};

ActionPaths.defaultProps = {
  actionPaths: [],
  refetchRiskRecord: () => {},
  eid: '',
};

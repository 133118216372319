import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import stc from 'string-to-color';
import { abbrNum } from '@src/includes/functions';
import { AbyssTheme as themeConfiguration } from '@src/client';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isUndefined, map } from 'lodash';
import { Line } from 'react-chartjs-2';
import { Visibility } from '@src/components/Visibility';

const stringToColor = stc;

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

/**
 * LineChart
 *
 * Displays the risk trend report data in a line chart.
 *
 * @TODO - Refactor this chart to be more dynamic and reusable. Currently specific for tag history dashboard.
 *
 * @param props
 * @returns {React.JSX.Element|*|string}
 * @constructor
 */
export const LineChart = (props) => {
  const { data, isLogarithmicScale } = props;

  const chartRef = useRef(null);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  /**
   * Define the labels for the chart. (x axis)
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const theLabels = map(data, 'date');

      if (theLabels !== labels) {
        setLabels(theLabels);
      }
    }
  }, [data]);

  /**
   * Define the datasets for the chart. (y axis)
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const theDatasets = [];

      const items = {};

      data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (!key.includes('-count') && key !== 'date') {
            if (isUndefined(items[key])) {
              items[key] = [];
            }
            items[key].push(item[`${key}-count`]);
          }
        });
      });

      Object.keys(items).forEach((key) => {
        const color = stringToColor(key);
        const dataset = {
          label: key,
          data: items[key],
          borderColor: color,
          backgroundColor: color,
          borderJoinStyle: 'bevel',
          borderWidth: 2,
          pointRadius: 3,
        };

        theDatasets.push(dataset);
      });

      if (theDatasets !== datasets) {
        setDatasets(theDatasets);
      }
    }
  }, [data]);

  return (
    <ErrorHandler location="src/components/Chart/components/LineChart/LineChart.jsx">
      <Visibility>
        <Line
          ref={chartRef}
          data={{
            labels,
            datasets,
          }}
          options={{
            layout: {
              padding: {
                top: 36,
              },
            },
            animation: false,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'bottom',
                align: 'middle',
                title: {
                  display: true,
                  text: 'Tags',
                },
              },
              tooltip: {
                callbacks: {
                  label() {
                    return '';
                  },
                  title() {
                    return '';
                  },
                  footer(context) {
                    const tag = data?.[context?.[0]?.dataIndex];

                    if (isUndefined(tag)) {
                      return '';
                    }

                    const count = data?.[context?.[0]?.dataIndex]?.[`${context?.[0]?.dataset?.label}-count`];
                    const increase = tag?.[context?.[0]?.dataset?.label]?.increase;
                    const decrease = tag?.[context?.[0]?.dataset?.label]?.decrease;
                    const date = tag?.date;

                    return `Tag: : ${String(context?.[0]?.dataset?.label)}\nDate: ${dayjs(date).format(
                      'MM/DD/YYYY'
                    )}\nCount: ${Number(count).toLocaleString('en-US')}\nIncrease: ${Number(increase).toLocaleString(
                      'en-US'
                    )}\nDecrease: ${Number(decrease).toLocaleString('en-US')}`;
                  },
                },
              },
            },
            scales: {
              y: {
                stacked: false,
                border: { color: themeConfiguration?.theme?.colors?.gray6, dash: [5, 5] },
                grid: {
                  color: themeConfiguration?.theme?.colors?.gray3,
                },
                ticks: {
                  // Include a dollar sign in the ticks
                  callback(value) {
                    return abbrNum(value);
                  },
                },
                title: {
                  display: true,
                  text: 'Count',
                },
                type: isLogarithmicScale ? 'logarithmic' : 'linear',
              },
              x: {
                border: { color: themeConfiguration?.theme?.colors?.gray6, dash: [5, 5] },
                grid: {
                  color: themeConfiguration?.theme?.colors?.gray3,
                },
                title: {
                  display: true,
                  text: 'Date Range',
                },
              },
            },
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

LineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLogarithmicScale: PropTypes.bool,
};

LineChart.defaultProps = {
  isLogarithmicScale: false,
};

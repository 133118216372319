import PropTypes from 'prop-types';
import React from 'react';
import { ApiProvider } from '@src/context/Api';
import { PrintProvider } from '@abyss/web/ui/PrintProvider';
import { RoutesProvider } from '@src/context/Routes';
import { VisibilityProvider } from '@src/context/Visibility';

/**
 * ApplicationProvider
 *
 * Handles setting the context to be used globally throughout the application.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ApplicationProvider = (props) => {
  const { children } = props;

  return (
    <ApiProvider>
      <RoutesProvider>
        <PrintProvider>
          <VisibilityProvider>{children}</VisibilityProvider>
        </PrintProvider>
      </RoutesProvider>
    </ApiProvider>
  );
};

ApplicationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-table-root': {
    '.abyss-table-head': {
      '.abyss-table-header': {
        'th:first-child': {
          flex: 'none !important',
          width: '48px !important',
          minWidth: '48px !important',
        },
        'th:nth-of-type(2)': {
          flex: 'auto !important',
          width: '20% !important',
          minWidth: '20% !important',
        },
        'th:last-child': {
          flex: 'none !important',
          width: 'calc(100% - 20% - 48px) !important',
          minWidth: 'calc(100% - 20% - 48px) !important',
        },
      },
    },
    '.abyss-table-body': {
      '.abyss-table-row': {
        'td:first-child': {
          flex: 'none !important',
          width: '48px !important',
          minWidth: '48px !important',
        },
        'td:nth-of-type(2)': {
          flex: 'auto !important',
          width: '20% !important',
          minWidth: '20% !important',
        },
        'td:last-child': {
          flex: 'none !important',
          width: 'calc(100% - 20% - 48px) !important',
          minWidth: 'calc(100% - 20% - 48px) !important',
        },
      },
    },
  },
  '.hasActions': {
    '.abyss-table-root': {
      '.abyss-table-head': {
        '.abyss-table-header': {
          'th:nth-of-type(3)': {
            flex: 'none !important',
            width: 'calc(100% - 20% - 48px - 148px) !important',
            minWidth: 'calc(100% - 20% - 48px - 148px) !important',
          },
          'th:last-child': {
            flex: 'none !important',
            width: '148px !important',
            minWidth: '148px !important',
          },
        },
      },
      '.abyss-table-body': {
        '.abyss-table-row': {
          'td:nth-of-type(3)': {
            flex: 'none !important',
            width: 'calc(100% - 20% - 48px - 148px) !important',
            minWidth: 'calc(100% - 20% - 48px - 148px) !important',
          },
          'td:last-child': {
            flex: 'none !important',
            width: '148px !important',
            minWidth: '148px !important',
          },
        },
      },
    },
  },
});

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isUndefined } from 'lodash';

/**
 * ListCodes
 *
 * Retrieves a list of codes from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ListCodes = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {
        'x-api-endpoint': '/code-lists/categories',
      },
      params: {},
    };

    const thePayload = { ...payload?.[1] };

    if (!isUndefined(payload?.[0])) {
      requestArgs.params = { ...requestArgs.params, ...{ queryKey: payload[0] } };
    }

    if (!isUndefined(payload?.[1]?.page)) {
      requestArgs.params = { ...requestArgs.params, ...{ page: payload?.[1]?.page } };
      delete thePayload?.page;
    }

    if (!isUndefined(payload?.[1]?.size)) {
      requestArgs.params = { ...requestArgs.params, ...{ size: payload?.[1]?.size } };
      delete thePayload?.size;
    }

    if (!isUndefined(payload?.[1]?.sort)) {
      requestArgs.params = { ...requestArgs.params, ...{ sort: payload?.[1]?.sort } };
      delete thePayload?.sort;
    }

    if (!isUndefined(thePayload?.categoryCode)) {
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.categoryCode}`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/ListCodes.js -> ListCodes() -> error:', theError);

    throw error;
  }
};

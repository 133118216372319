/**
 * getTooltipTitle
 *
 * Get the title for the tooltip.
 *
 * @param args
 * @param data
 * @returns {*}
 */
export const getTooltipTitle = (args = {}, data = []) => {
  const { dataIndex } = args[0];
  const currentData = data[dataIndex];
  return currentData?.yaxisDesc;
};

/**
 * getTooltipLabel
 *
 * Get the label for the tooltip.
 *
 * @param args
 * @param data
 * @returns {*}
 */
export const getTooltipLabel = (args = {}, data = []) => {
  const { dataIndex } = args;
  const currentData = data[dataIndex];
  return currentData?.name;
};

/**
 * getTooltipFooter
 *
 * Get the footer for the tooltip.
 *
 * @param args
 * @param data
 * @returns {`\nValue: ${string}\nPercentage: ${*}%\n`}
 */
export const getTooltipFooter = (args = {}, data = []) => {
  const { dataIndex } = args[0];
  const currentData = data[dataIndex];

  return `\nValue: ${Number(currentData?.value).toLocaleString('en-US')}\nPercentage: ${currentData?.perc}%\n`;
};

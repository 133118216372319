import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { DeactivationModal } from '../../modals/Deactivation';

/**
 * Button: Deactivate
 *
 * This button is used to deactivate the selected version of the entity.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Deactivate = (props) => {
  const { activatedVersion, currentEntity, form, refetch, setFocusedEntity, setShowAll } = props;

  const [isOpen, setIsOpen] = useState(false);

  const selectedVersionValue = form?.watch('version');

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/buttons/Deactivate/Deactivate.jsx">
      <Button
        variant="destructive"
        onClick={() => {
          setIsOpen(true);
        }}
        isDisabled={activatedVersion?.version !== selectedVersionValue}
      >
        Deactivate
      </Button>
      {isOpen && (
        <DeactivationModal
          currentEntity={currentEntity}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          refetch={refetch}
          setFocusedEntity={setFocusedEntity}
          setShowAll={setShowAll}
        />
      )}
    </ErrorHandler>
  );
};

Deactivate.propTypes = {
  activatedVersion: PropTypes.shape({
    version: PropTypes.string,
  }),
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isActive: PropTypes.bool,
  }),
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isDirty: PropTypes.bool,
      isValid: PropTypes.bool,
    }),
    watch: PropTypes.func,
  }),
  refetch: PropTypes.func,
  setFocusedEntity: PropTypes.func,
  setShowAll: PropTypes.func,
};

Deactivate.defaultProps = {
  activatedVersion: {},
  currentEntity: {},
  form: {},
  refetch: () => {},
  setFocusedEntity: () => {},
  setShowAll: () => {},
};

import PropTypes from 'prop-types';
import React from 'react';
import { Flex } from '@abyss/web/ui/Flex';
import { isEmpty } from 'lodash';
import { Label } from '@abyss/web/ui/Label';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { motion } from 'framer-motion';
import { Text } from '@abyss/web/ui/Text';

/**
 * Loader
 *
 * Reusable loader component
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Loader = (props) => {
  const { height, message, verticalAlignment, width, backgroundColor } = props;

  const css = {
    position: 'relative',
    backgroundColor,
  };

  if (isEmpty(height)) {
    css.height = '100vh !important';
  }

  if (isEmpty(width)) {
    css.width = '100vw !important';
  }

  return (
    <motion.div
      animate="open"
      variants={{
        open: { opacity: 1 },
        closed: { opacity: 0 },
      }}
      initial={{ opacity: 0 }}
    >
      <Flex
        justify={!isEmpty(verticalAlignment) ? verticalAlignment : 'center'}
        alignItems="center"
        alignContent="center"
        direction="column"
        css={css}
      >
        <Flex justify="center" alignItems="center" alignContent="center">
          <LoadingSpinner size="$lg" isLoading ariaLoadingLabel="Loading..." />
          <Flex
            css={{ marginLeft: 'var(--abyss-space-lg)' }}
            alignItems="flex-start"
            alignContent="flex-start"
            direction="column"
          >
            <Label
              data-testid="loader-container-abyss-flex-root"
              className="xs-margin-bottom"
              size="$lg"
              css={{ marginBottom: 'var(--abyss-space-xs)' }}
            >
              Loading...
            </Label>
            <Text size="$sm">{!isEmpty(message) ? message : 'Your request is being processed.'}</Text>
          </Flex>
        </Flex>
      </Flex>
    </motion.div>
  );
};

Loader.propTypes = {
  backgroundColor: PropTypes.string,
  height: PropTypes.string,
  message: PropTypes.string,
  verticalAlignment: PropTypes.string,
  width: PropTypes.string,
};

Loader.defaultProps = {
  backgroundColor: 'var(--abyss-colors-white)',
  height: '',
  message: '',
  verticalAlignment: '',
  width: '',
};

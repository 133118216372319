export const chartColors = [
  'primaryDvz1',
  'primaryDvz2',
  'primaryDvz3',
  'purpleDvz1',
  'purpleDvz2',
  'purpleDvz3',
  'redDvz1',
  'redDvz2',
  'redDvz3',
  'sapphireDvz1',
  'sapphireDvz2',
  'sapphireDvz3',
  'secondaryDvz1',
  'secondaryDvz2',
  'secondaryDvz3',
  'statusDvz1',
  'statusDvz2',
  'statusDvz3',
  'supporting1',
  'supporting2',
  'supporting3',
  'supporting4',
  'supporting5',
  'supporting6',
  'tangerineDvz1',
  'tangerineDvz2',
  'tangerineDvz3',
];

import PropTypes from 'prop-types';
import React from 'react';
import { Button as AbyssButton } from '@abyss/web/ui/Button';
import { isUndefined } from 'lodash';
import { styled } from '@abyss/web/tools/styled';

/**
 * Button
 *
 * Still uses Abyss buttons, but overrides the default Abyss styling to better match UHG branding.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Button = (props) => {
  const { children, variant } = props;

  const defaultProps = {
    children,
  };

  if (isUndefined(variant)) {
    defaultProps.variant = 'solid';
  }

  const newProps = { ...props, ...defaultProps };

  let button = {
    border: 'unset !important',
    borderRadius: 'unset !important',
    borderStyle: 'solid !important',
    borderWidth: '2px !important',
    transition: 'all .33s ease-in-out !important',
  };

  let buttonHover = {};
  let buttonText = {};
  let buttonTextHover = {};
  let icon = {};
  let iconHover = {};

  if (newProps?.variant === 'solid') {
    button = {
      ...button,
      ...{
        backgroundColor: '#0066f5 !important',
        borderColor: '#0066f5 !important',
        color: '#ffffff !important',
      },
    };

    buttonHover = {
      ...buttonHover,
      ...{
        backgroundColor: '#ffffff !important',
        color: '#0066f5 !important',
      },
    };

    buttonText = {
      ...buttonText,
      ...{
        color: '#ffffff !important',
      },
    };

    buttonTextHover = {
      ...buttonTextHover,
      ...{
        color: '#0066f5 !important',
      },
    };

    icon = {
      ...icon,
      ...{
        color: '#ffffff !important',
      },
    };

    iconHover = {
      ...iconHover,
      ...{
        color: '#0066f5 !important',
      },
    };
  }

  if (newProps?.variant === 'outline') {
    button = {
      ...button,
      ...{
        backgroundColor: '#ffffff !important',
        borderColor: '#0066f5 !important',
        color: '#0066f5 !important',
      },
    };

    buttonHover = {
      ...buttonHover,
      ...{
        backgroundColor: '#0066f5 !important',
        color: '#ffffff !important',
      },
    };

    buttonText = {
      ...buttonText,
      ...{
        color: '#0066f5 !important',
      },
    };

    buttonTextHover = {
      ...buttonTextHover,
      ...{
        color: '#ffffff !important',
      },
    };

    icon = {
      ...icon,
      ...{
        color: '#0066f5 !important',
      },
    };

    iconHover = {
      ...iconHover,
      ...{
        color: '#ffffff !important',
      },
    };
  }

  if (newProps?.variant === 'ghost') {
    button = {
      ...button,
      ...{
        fontWeight: 'normal !important',
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
        color: 'black !important',
      },
    };

    buttonHover = {
      ...buttonHover,
      ...{
        backgroundColor: '#F3F3F3 !important',
        color: 'black !important',
      },
    };

    buttonText = {
      ...buttonText,
      ...{
        color: 'black !important !important',
      },
    };

    buttonTextHover = {
      ...buttonTextHover,
      ...{
        color: 'black !important !important',
      },
    };

    icon = {
      ...icon,
      ...{
        color: 'black !important',
      },
    };

    iconHover = {
      ...iconHover,
      ...{
        color: 'black !important',
      },
    };
  }

  if (newProps?.variant === 'destructive') {
    button = {
      ...button,
      ...{
        backgroundColor: 'var(--abyss-colors-button-color-background-root-destructive-default) !important',
        borderColor: 'var(--abyss-colors-button-color-border-root-destructive) !important',
        color: 'var(--abyss-colors-button-color-text-root-destructive-default) !important',
      },
    };

    buttonHover = {
      ...buttonHover,
      ...{
        backgroundColor: 'var(--abyss-colors-button-color-background-root-destructive-hover) !important',
        color: 'var(--abyss-colors-button-color-text-root-destructive-hover) !important',
      },
    };

    buttonText = {
      ...buttonText,
      ...{
        color: 'var(--abyss-colors-button-color-text-root-destructive-default) !important',
      },
    };

    buttonTextHover = {
      ...buttonTextHover,
      ...{
        color: 'var(--abyss-colors-button-color-text-root-destructive-hover) !important',
      },
    };

    icon = {
      ...icon,
      ...{
        color: 'var(--abyss-colors-button-color-text-root-destructive-default) !important',
      },
    };

    iconHover = {
      ...iconHover,
      ...{
        color: 'var(--abyss-colors-button-color-text-root-destructive-hover) !important',
      },
    };
  }

  let Styles;

  if (!['solid', 'outline', 'ghost', 'destructive'].includes(variant)) {
    Styles = styled('div', {
      'a, button': {
        '.abyss-insert-element-before': {
          paddingRight: 'var(--abyss-space-xs) !important',
        },
        '.abyss-insert-element-after': {
          paddingLeft: 'var(--abyss-space-xs) !important',
        },
      },
    });
  } else {
    Styles = styled('div', {
      'a, button': {
        ...button,
        '.abyss-insert-element-before': {
          paddingRight: 'var(--abyss-space-xs) !important',
        },
        '.abyss-insert-element-after': {
          paddingLeft: 'var(--abyss-space-xs) !important',
        },
        '.abyss-icon': icon,
        '& span': buttonText,
        '&:hover': {
          ...buttonHover,
          '& span': buttonTextHover,
          '& .abyss-icon': iconHover,
        },
      },
      'button[class*="isDisabled-true"]': {
        cursor: 'not-allowed',
        backgroundColor: 'var(--abyss-colors-button-color-background-root-isDisabled-true) !important',
        color: 'var(--abyss-colors-button-color-text-root-isDisabled-true) !important',
        borderColor: 'var(--abyss-colors-button-color-border-root-isDisabled-true) !important',
        '& span, .abyss-icon': {
          color: 'var(--abyss-colors-button-color-text-root-isDisabled-true) !important',
        },
      },
    });
  }

  return (
    <Styles>
      <AbyssButton {...newProps}>{children}</AbyssButton>
    </Styles>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['solid', 'outline', 'ghost']),
};

Button.defaultProps = {
  variant: 'solid',
};

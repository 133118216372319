import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FilterCriteria } from '@src/components/FilterCriteria';
import { Table as TableComponent } from '@src/components/Table-static';
import PropTypes from 'prop-types';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { Layout } from '@abyss/web/ui/Layout';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Button } from '@src/components/Button';
import { isEmpty, isNull, isUndefined, merge } from 'lodash';
import { useSave } from '@src/routes/private/ActionPaths/components/Wizard/hooks/useSave/useSave';
import { useApi } from '@src/context/Api';
import { Styles } from './includes/styles';
import configuration from './includes/configuration.json';

const WithActions = (props) => {
  const { children } = props;

  return <div className="hasActions">{children}</div>;
};

WithActions.propTypes = {
  children: PropTypes.node,
};

WithActions.defaultProps = {
  children: null,
};

/**
 * Table: CommonCriteria
 *
 * Displays a list of common criteria filters within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows, filterKey, form, showActions, needsUpdate, needsRemoval, context } = props;

  const [checked, setChecked] = useState({});

  const commonIds = form?.getValues(`criteria.${context}.commonIds`);

  const { clearApiCache } = useApi();
  const handleSave = useSave();

  /**
   * set initial checked state with the rows that have been checked previously from api
   */
  useEffect(() => {
    if (rows) {
      rows.forEach((row) => {
        setChecked((previousState) => {
          return {
            ...previousState,
            ...{
              [row?.id]: commonIds.includes(row?.id),
            },
          };
        });
      });
    }
  }, [rows]);

  /**
   * theRows
   *
   * Merges the rows with the checked state and the needsUpdate and needsRemoval state.
   */
  const theRows = useMemo(() => {
    const items = rows?.map((row) => {
      return {
        ...row,
        ...{
          action: null,
          actionDetails: {},
          checkbox: checked[row?.id] || false,
        },
      };
    });

    if (!isEmpty(needsUpdate)) {
      rows?.forEach((row, index) => {
        if (!isUndefined(needsUpdate[row?.id])) {
          items[index].action = 'update';
          items[index].actionDetails = needsUpdate[row?.id];
          items[index].checkbox = true;
        }
      });
    }

    if (!isEmpty(needsRemoval)) {
      Object.values(needsRemoval).forEach((item) => {
        const theRow = items.find((row) => {
          return row.id === item?.criteria?.id && row?.activeCommonCriteriaVersion?.id === item?.version?.id;
        });

        if (isUndefined(theRow)) {
          items.unshift({
            ...{
              action: 'remove',
              actionDetails: item,
              checkbox: true,
            },
            ...item?.criteria,
          });
        }
      });
    }

    return items;
  }, [rows, checked, needsRemoval, needsUpdate]);

  /**
   * set form values when checked state changes
   */
  useEffect(() => {
    const commonCriteriaIds = Object.keys(checked).filter((commonCriteriaId) => {
      return checked[commonCriteriaId] === true;
    });

    const commonCriteriaVersionIds = theRows
      ?.filter((row) => {
        return commonCriteriaIds.includes(row?.id);
      })
      .map((row) => {
        let theId = row?.activeCommonCriteriaVersion?.id;

        if (!isUndefined(row?.actionDetails?.from?.version?.id)) {
          theId = row?.actionDetails?.from?.version?.id;
        }

        return theId;
      });

    if (!isEmpty(needsRemoval)) {
      Object.values(needsRemoval).forEach((item) => {
        const theRow = theRows.find((row) => {
          return row.id === item?.criteria?.id && row?.activeCommonCriteriaVersion?.id === item?.version?.id;
        });

        if (isUndefined(theRow)) {
          commonCriteriaIds.push(item?.criteria?.id);
          commonCriteriaVersionIds.push(item?.version?.id);
        }
      });
    }

    form.setValue(`criteria.${context}.commonIds`, commonCriteriaIds, { shouldDirty: true });
    form.setValue(`criteria.${context}.commonCriteriaVersionsIds`, commonCriteriaVersionIds, { shouldDirty: true });
  }, [theRows, checked, needsRemoval]);

  /**
   * renderCellCheckbox
   *
   * Displays a checkbox in a cell indicating whether the common criteria filters are applied.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellCheckbox = (args = {}) => {
    const { row } = args;

    return (
      <Checkbox
        label=""
        isChecked={row?.original?.checkbox || false}
        onChange={(event) => {
          setChecked((previousState) => {
            return { ...previousState, ...{ [row?.original?.id]: event?.target?.checked } };
          });
        }}
      />
    );
  };

  /**
   * renderCellName
   *
   * Displays the name of the common criteria filter.
   *
   * @param args
   * @returns {*}
   */
  const renderCellName = (args = {}) => {
    const { row, cell } = args;

    return (
      <Tooltip
        align="center"
        position="right"
        content={
          <Layout.Stack space={0} alignLayout="left" alignItems="left" grow>
            <p>
              <strong>Name:</strong>
              <br />
              {row?.original?.name}
            </p>
            {row?.original?.action === 'update' && (
              <p>
                <strong>Version In-Use:</strong>
                <br />
                {row?.original?.actionDetails?.from?.version}
              </p>
            )}

            {row?.original?.isActive === false ? (
              <p>
                <strong>Status:</strong>
                <br />
                Inactive
              </p>
            ) : (
              <p>
                <strong>Activated Version:</strong>
                <br />
                {row?.original?.activeVersionNbr}
              </p>
            )}

            <p>
              <strong>Last Modified On:</strong>
              <br />
              {dayjs(row?.original?.lastModifiedDate).format('MM/DD/YYYY, HH:mm:ss')}
            </p>
            <p>
              <strong>Last Modified By:</strong>
              <br />
              {row?.original?.lastModifiedBy}
            </p>
          </Layout.Stack>
        }
      >
        <span>{cell?.value}</span>
      </Tooltip>
    );
  };

  /**
   * renderCellCriteria
   *
   * Displays the common criteria filters.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellCriteria = (args = {}) => {
    const { row } = args;

    let theFilters = [];

    if (row?.original?.activeCommonCriteriaVersion?.criteria) {
      theFilters = row?.original?.activeCommonCriteriaVersion?.criteria;
    }

    if (row?.original?.actionDetails?.version?.criteria) {
      theFilters = row?.original?.actionDetails?.version?.criteria;
    }

    if (row?.original?.actionDetails?.from?.criteria) {
      theFilters = row?.original?.actionDetails?.from?.criteria;
    }

    return <FilterCriteria filters={theFilters} showLabel={false} />;
  };

  /**
   * renderCellActions
   *
   * Displays a button to update the common criteria filter from the latest activated version.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellActions = (args = {}) => {
    const { row } = args;

    return (
      <Layout.Group alignLayout="center" space={0}>
        {isNull(row?.original?.action) && (
          <Button variant="solid" isDisabled>
            Update
          </Button>
        )}

        {row?.original?.action === 'update' && (
          <Button
            variant="solid"
            onClick={async () => {
              const formValues = form?.getValues();

              const payload = { ...formValues };

              payload.criteria.entrance.commonCriteriaVersionsIds =
                payload?.criteria?.entrance?.commonCriteriaVersionsIds.map((id) => {
                  let theId = id;

                  if (id === row?.original?.actionDetails?.from?.id) {
                    theId = row?.original?.actionDetails?.to?.id;
                  }

                  return theId;
                });

              await handleSave(payload);
              window.location.reload(); // @TODO temporary fix to refetch the common criteria versions
            }}
          >
            Update
          </Button>
        )}
        {row?.original?.action === 'remove' && (
          <Button
            variant="destructive"
            onClick={async () => {
              const formValues = form?.getValues();

              const payload = { ...formValues };

              payload.criteria.entrance.commonIds = payload?.criteria?.entrance?.commonIds.filter((id) => {
                return id !== row?.original?.actionDetails?.criteria.id;
              });

              payload.criteria.entrance.commonCriteriaVersionsIds =
                payload?.criteria?.entrance?.commonCriteriaVersionsIds.filter((id) => {
                  return id !== row?.original?.actionDetails?.version.id;
                });

              await handleSave(payload);
              await clearApiCache();
            }}
          >
            Remove
          </Button>
        )}
      </Layout.Group>
    );
  };

  /**
   * Columns for the table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns
      ?.map((column) => {
        const theColumn = column;

        if (theColumn.accessor === 'checkbox') {
          theColumn.Cell = renderCellCheckbox;
        }

        if (theColumn.Header === 'Name') {
          theColumn.Cell = renderCellName;
        }

        if (theColumn.Header === 'Criteria') {
          theColumn.Cell = renderCellCriteria;
        }

        if (theColumn.Header === 'Actions') {
          theColumn.Cell = renderCellActions;
        }

        return theColumn;
      })
      .filter((column) => {
        if (column.Header === 'Actions') {
          if (showActions === false) {
            return false;
          }

          if (showActions === true && isEmpty(needsUpdate) && isEmpty(needsRemoval)) {
            return false;
          }
        }

        return true;
      });
  }, [showActions, needsUpdate, needsRemoval]);

  return (
    <ErrorHandler location="src/common/widgets/CommonCriteria/components/Table/Table.jsx">
      <Styles>
        {showActions === false || (showActions === true && isEmpty(needsUpdate) && isEmpty(needsRemoval)) ? (
          <TableComponent
            {...{
              rows: theRows,
              columns,
              configuration,
              dataKey: `actionPaths-${filterKey}`,
            }}
          />
        ) : (
          <WithActions>
            <TableComponent
              {...{
                rows: theRows,
                columns,
                configuration: !isEmpty(needsRemoval)
                  ? merge({}, configuration, {
                      initialState: {
                        sortBy: [
                          {
                            desc: false,
                            id: 'actions',
                          },
                        ],
                      },
                    })
                  : configuration,
                dataKey: `actionPaths-${filterKey}`,
              }}
            />
          </WithActions>
        )}
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      activeVersionNbr: PropTypes.number,
      lastModifiedDate: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      activeCommonCriteriaVersion: PropTypes.shape({
        id: PropTypes.string,
        criteria: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      }),
      action: PropTypes.string,
      actionDetails: PropTypes.shape({
        fromId: PropTypes.string,
        toId: PropTypes.string,
        version: PropTypes.shape({
          criteria: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
              value: PropTypes.string,
            })
          ),
        }),
        criteria: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      }),
      checkbox: PropTypes.bool,
    })
  ),
  filterKey: PropTypes.string,
  form: PropTypes.shape({
    getValues: PropTypes.func,
    setValue: PropTypes.func,
  }),
  showActions: PropTypes.bool,
  needsUpdate: PropTypes.shape({
    id: PropTypes.shape({
      fromId: PropTypes.string,
      toId: PropTypes.string,
      version: PropTypes.shape({
        criteria: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  needsRemoval: PropTypes.arrayOf(
    PropTypes.shape({
      criteria: PropTypes.shape({
        id: PropTypes.string,
      }),
      version: PropTypes.shape({
        id: PropTypes.string,
      }),
    })
  ),
  context: PropTypes.string,
};

Table.defaultProps = {
  rows: [],
  filterKey: '',
  form: {},
  showActions: true,
  needsUpdate: {},
  needsRemoval: [],
  context: '',
};

import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Text } from '@abyss/web/ui/Text';

/**
 * RiskScore
 *
 * Displays a letter representing the risk score with a color that corresponds to the risk code.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RiskScore = (props) => {
  const { riskCode } = props;

  const codes = riskCode?.split('-');
  const letter = codes.shift().toUpperCase();

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Details/components/RiskScore/RiskScore.jsx">
      {letter === 'R' && (
        <Text fontWeight="bold" color={themeConfiguration?.theme?.colors?.error1}>
          <div>{letter} (Red)</div>
        </Text>
      )}

      {letter === 'Y' && (
        <Text fontWeight="bold" color={themeConfiguration?.theme?.colors?.warning1}>
          <div>{letter} (Yellow)</div>
        </Text>
      )}

      {letter === 'G' && (
        <Text fontWeight="bold" color={themeConfiguration?.theme?.colors?.success1}>
          {letter} (Green)
        </Text>
      )}
    </ErrorHandler>
  );
};

RiskScore.propTypes = {
  riskCode: PropTypes.string,
};

RiskScore.defaultProps = {
  riskCode: '',
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isUndefined } from 'lodash';

/**
 * SaveAlert
 *
 * Saves a single alert with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveAlert = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/alerts',
      },
      data: {},
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ mutationKey: payload[0] } };
    }

    if (!isUndefined(payload?.[1]?.id) && !isEmpty(payload?.[1]?.id)) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${payload?.[1]?.id}`;
    }

    if (!isUndefined(payload?.[1]?.assignedUser)) {
      requestArgs.data.assignedUser = payload?.[1]?.assignedUser;
    }

    if (!isUndefined(payload?.[1]?.notes)) {
      requestArgs.data.notes = payload?.[1]?.notes;
    }

    if (!isUndefined(payload?.[1]?.status)) {
      requestArgs.data.status = payload?.[1]?.status;
    }

    if (!isUndefined(payload?.[1]?.triggeredAlertId)) {
      requestArgs.data.triggeredAlertId = payload?.[1]?.triggeredAlertId;
    }

    if (!isUndefined(payload?.[1]?.id)) {
      requestArgs.data.id = payload?.[1]?.id;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveAlert.js -> SaveAlert() -> error:', theError);

    throw error;
  }
};

import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { isEmpty } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';

/**
 * Tooltip: Sources
 *
 * @TODO - Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RiskCodes = (props) => {
  const { codeDetails } = props;

  return (
    <Layout.Stack space={themeConfiguration?.theme?.space?.xs} alignLayout="left" alignItems="left" grow>
      {!isEmpty(codeDetails) &&
        codeDetails.map((code) => {
          return (
            <div
              style={{
                padding: 'var(--abyss-space-sm)',
              }}
            >
              {String(code?.codeId).length === 1 ? `0${code?.codeId}` : code.codeId} - {code?.codeDesc}
            </div>
          );
        })}
    </Layout.Stack>
  );
};

RiskCodes.propTypes = {
  codeDetails: PropTypes.arrayOf(
    PropTypes.shape({
      codeId: PropTypes.string,
      codeDesc: PropTypes.string,
    })
  ),
};

RiskCodes.defaultProps = {
  codeDetails: [],
};

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@src/components/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { isEqual, isNull } from 'lodash';
import { useSave } from '../../../hooks/useSave/useSave';

/**
 * Modal: Activation
 *
 * This modal is used to activate the selected version of the entity.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Activation = (props) => {
  const {
    currentEntity,
    isOpen,
    setIsOpen,
    refetch,
    formValues,
    selectedVersionValue,
    activatedVersion,
    setFocusedEntity,
    commonCriteriaVersions
  } = props;

  const handleActivation = useSave('activate');
  const handleSave = useSave('update');
  const handleCreateVersion = useSave('createVersion');

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = () => {
    setIsOpen(false);
  };

  const inactiveToActive = isEqual(currentEntity?.activeCommonCriteriaVersion?.criteria, formValues?.filters) || (isNull(currentEntity?.activeCommonCriteriaVersion) && currentEntity?.activeVersionNbr === -1);
  const versionChange = (activatedVersion?.version !== selectedVersionValue);
  let content;

  if (inactiveToActive) {
    content = <React.Fragment><div>Are you sure you want to activate: <strong>{currentEntity?.name}</strong></div></React.Fragment>;
  } else if (versionChange) {
    content = <React.Fragment><div>Do you want to activate <strong>{currentEntity?.name} v{selectedVersionValue}</strong>?</div><div style={{ marginTop: '4px' }}>This will deactivate v{activatedVersion.version} and make v{selectedVersionValue} the current active version.</div></React.Fragment>;
  } else {
    content = <React.Fragment><div>You made updates to <strong>{currentEntity?.name}</strong>. Do you want to activate a new version  v{commonCriteriaVersions} </div></React.Fragment>
  }

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/modals/Activation/Activation.jsx">
      <Modal title="Activate Common Criteria" isOpen={isOpen} onClose={handleClose}>
        <Modal.Section>{content}</Modal.Section>
        <Modal.Section>
          <Divider height={1} />
          <Layout.Group alignLayout="right">
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="solid"
              onClick={async () => {
                if (inactiveToActive) {
                  await handleActivation(
                    {
                      ...currentEntity,
                      ...formValues,
                      ...{
                        isActive: true,
                        activeVersionNbr: formValues?.version,
                      },
                    },
                    refetch
                  );
                } else if (versionChange) {
                  await handleSave({ ...currentEntity, ...formValues }, refetch);
                } else {
                  await handleCreateVersion({ ...currentEntity, ...formValues }, refetch);
                }
                setFocusedEntity(currentEntity?.name);
              }}
            >
              Activate
            </Button>
          </Layout.Group>
        </Modal.Section>
      </Modal>
    </ErrorHandler>
  );
};

Activation.propTypes = {
  currentEntity: PropTypes.shape({
    name: PropTypes.string,
    activeCommonCriteriaVersion: PropTypes.shape({
      criteria: PropTypes.shape({
        filters: PropTypes.shape({
          entrance: PropTypes.shape({
            common: PropTypes.shape({
              ids: PropTypes.arrayOf(PropTypes.string),
            }),
          }),
          exit: PropTypes.shape({
            common: PropTypes.shape({
              ids: PropTypes.arrayOf(PropTypes.string),
            }),
          }),
        }),
      }),
    }),
    activeVersionNbr: PropTypes.number,
  }),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  refetch: PropTypes.func,
  formValues: PropTypes.shape({
    version: PropTypes.number,
    filters: PropTypes.shape({
      entrance: PropTypes.shape({
        common: PropTypes.shape({
          ids: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
      exit: PropTypes.shape({
        common: PropTypes.shape({
          ids: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
    }),
  }),
  commonCriteriaVersions: PropTypes.number,
  selectedVersionValue: PropTypes.number,
  activatedVersion: PropTypes.shape({
    version: PropTypes.number,
  }),
  setFocusedEntity: PropTypes.func,
};

Activation.defaultProps = {
  currentEntity: {},
  isOpen: false,
  setIsOpen: () => { },
  refetch: () => { },
  formValues: {},
  selectedVersionValue: 0,
  commonCriteriaVersions: 0,
  activatedVersion: {},
  setFocusedEntity: () => {},
};

import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { useForm } from '@abyss/web/hooks/useForm';

/**
 * Filters
 *
 * Displays a form to filter the data displayed in the table.
 *
 * @returns {Element}
 * @constructor
 */
export const Filters = (props) => {
  const { assets, queries, setTheQueries } = props;

  const defaultValues = {
    codeCategories: [],
  };

  const form = useForm({ defaultValues });
  const { isSubmitting, isValid } = form?.formState;

  /**
   * Mapping data loaded from API to the form state.
   */
  useEffect(() => {
    if (
      !isEmpty(queries) &&
      !isUndefined(assets?.ListCodeCategories?.data?.content?.[0]?.categoryCode) &&
      !isEmpty(assets?.ListCodeCategories?.data?.content?.[0]?.categoryCode)
    ) {
      const selectedCategoryCodes = form?.getValues('codeCategories');

      if (isEmpty(selectedCategoryCodes)) {
        const categoryCode = assets?.ListCodeCategories?.data?.content?.[0]?.categoryCode;

        form?.reset(
          { codeCategories: [categoryCode] },
          {
            keepDirty: false,
            keepDirtyValues: false,
            keepErrors: false,
            keepIsValid: false,
            keepSubmitCount: true,
            keepTouched: false,
            keepValues: false,
          }
        );
      }
    }
  }, [assets, queries]);

  /**
   * handleSubmit
   *
   * Retrieves the filtered data from the remote API.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    (submittedValues) => {
      if (!isSubmitting && isValid) {
        const theQueries = [];

        submittedValues?.codeCategories?.forEach((categoryCode) => {
          theQueries.push({
            key: `ListCodes-${categoryCode}`,
            args: {
              page: 0,
              size: 9999,
              sort: 'codeId,asc',
              categoryCode,
            },
          });
        });

        setTheQueries(theQueries);
      }
    },
    [isSubmitting, isValid]
  );

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/Codes/List/components/Table/components/Filters/Filters.jsx">
      <FormProvider state={form} autoComplete="off" highlighted onSubmit={handleSubmit}>
        <Layout.Group alignLayout="left" alignItems="bottom">
          <SelectInputMulti
            label="Category Code"
            model="codeCategories"
            placeholder="Select Category Code(s)"
            width="300px"
            maxListHeight="350px"
            selectAll
            isSearchable
            options={
              assets?.ListCodeCategories?.data?.content?.map((item) => {
                return {
                  label: item?.categoryCode,
                  value: item?.categoryCode,
                };
              }) || []
            }
          />

          <Button variant="solid" type="submit">
            Apply
          </Button>
        </Layout.Group>
      </FormProvider>
    </ErrorHandler>
  );
};

Filters.propTypes = {
  assets: PropTypes.shape({
    ListCodeCategories: PropTypes.shape({
      data: PropTypes.shape({
        content: PropTypes.arrayOf(
          PropTypes.shape({
            categoryCode: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  queries: PropTypes.shape({
    key: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        codeId: PropTypes.string,
        categoryCode: PropTypes.string,
        code: PropTypes.string,
        isValid: PropTypes.bool,
        enabled: PropTypes.bool,
      })
    ),
  }),
  setTheQueries: PropTypes.func,
};

Filters.defaultProps = {
  assets: {},
  queries: {},
  setTheQueries: () => {},
};

import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { HorizontalBarChart } from './components/HorizontalBarChart';
import { Styles } from './includes/styles';

/**
 * Widget: RiskCodes
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RiskCodes = (props) => {
  const { data, riskCodes } = props;

  return (
    <ErrorHandler location="src/common/widgets/RiskCodes/RiskCodes.jsx">
      <Styles>
        <Widget title="Risk Codes" description="Count of Enterprise Ids associated with IRE risk code">
          <HorizontalBarChart data={data} riskCodes={riskCodes} />
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

RiskCodes.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      countTotal: PropTypes.number,
      riskCode: PropTypes.string,
    })
  ),
  riskCodes: PropTypes.arrayOf(PropTypes.string),
};

RiskCodes.defaultProps = {
  data: [],
  riskCodes: [],
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { every, isEmpty, isUndefined } from 'lodash';
import { dayjs } from '@abyss/web/tools/dayjs';

/**
 * filterEntranceCriteria
 *
 * if all filters are empty, remove them
 *
 * @param criteria
 * @returns {*[]}
 */
const filterCriteria = (criteria = []) => {
  let theCriteria = [];

  if (!isUndefined(criteria) && !isEmpty(criteria)) {
    const removeFilters = every(Object.values(criteria?.[0]), isEmpty);

    if (removeFilters === false) {
      theCriteria = criteria.map((filter) => {
        const theFilter = { ...filter };

        if (['LAST_MODIFIED_DATE', 'CREATED_DATE'].includes(filter?.column)) {
          theFilter.value = dayjs(filter?.value).format('YYYY-MM-DD');
        }

        if (filter?.column?.toLowerCase().includes('count')) {
          theFilter.value = Number(filter?.value);
        }

        return theFilter;
      });
    }
  }

  return theCriteria;
};

/**
 * GetRiskRecordsCount
 *
 * Retrieves count of risk records based on entrance criteria.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetRiskRecordsCount = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': `/risk-records/count/entranceAndExit`,
      },
      params: { queryKey: theQueryKey },
      data: {},
    };

    requestArgs.data.criteria = {
      entrance: {},
      exit: {},
    };

    const additionalEntranceCriteria = filterCriteria(thePayload?.criteria?.entrance?.additional);

    if (!isEmpty(additionalEntranceCriteria)) {
      requestArgs.data.criteria.entrance.additional = additionalEntranceCriteria;
    }

    if (!isEmpty(thePayload?.criteria?.entrance?.commonIds)) {
      requestArgs.data.criteria.entrance.commonIds = thePayload?.criteria?.entrance?.commonIds;
    }

    if (!isEmpty(thePayload?.criteria?.entrance?.commonCriteriaVersionsIds)) {
      requestArgs.data.criteria.entrance.commonCriteriaVersionsIds =
        thePayload?.criteria?.entrance?.commonCriteriaVersionsIds;
    }

    const additionalExitCriteria = filterCriteria(thePayload?.criteria?.exit?.additional);

    if (!isEmpty(additionalExitCriteria)) {
      requestArgs.data.criteria.exit.additional = additionalExitCriteria;
    }

    if (!isEmpty(thePayload?.criteria?.exit?.commonIds)) {
      requestArgs.data.criteria.exit.commonIds = thePayload?.criteria?.exit?.commonIds;
    }

    if (!isEmpty(thePayload?.criteria?.exit?.commonCriteriaVersionsIds)) {
      requestArgs.data.criteria.exit.commonCriteriaVersionsIds = thePayload?.criteria?.exit?.commonCriteriaVersionsIds;
    }

    if (isEmpty(requestArgs?.data?.criteria?.entrance)) {
      delete requestArgs?.data?.criteria?.entrance;
    }

    if (isEmpty(requestArgs?.data?.criteria?.exit)) {
      delete requestArgs?.data?.criteria?.exit;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/GetRiskRecordsCount.js -> GetRiskRecordsCount() -> error:', theError);

    throw error;
  }
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isUndefined } from 'lodash';

/**
 * RiskRecordActionPathDisassociation
 *
 * Disassociates EID's from Action Paths and vice versa.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const RiskRecordActionPathDisassociation = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/risk-records/manual/disassociate',
      },
      data: payload?.[1],
    };

    if (!isUndefined(payload?.[0])) {
      requestArgs.params = { ...{ mutationKey: payload?.[0] } };
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error(
      'src/requests/mutations/RiskRecordActionPathDisassociation.js -> RiskRecordActionPathDisassociation() -> error:',
      theError
    );

    throw error;
  }
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { orderBy } from 'lodash';
import { SourcesTooltip } from '@src/common/tooltips';
import { Table as TableComponent } from '@src/components/Table-query';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Displays a list of actions within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { error, isLoading, requestArgs, requestFunction, requestKey, rows, totalPages, totalRecords } = props;

  /**
   * renderCellLastUpdated
   *
   * Displays the date and time of when the action was last updated within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellLastUpdated = ({ cell }) => {
    return dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * renderCellStatus
   *
   * Displays the current status of the action within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellStatus = ({ cell }) => {
    if (cell?.value === true) {
      return (
        <Badge
          variant="success"
          outline
          icon={<IconSymbol icon="check_circle" size="$sm" color="$success1" variant="filled" />}
        >
          Valid
        </Badge>
      );
    }
    return (
      <Badge
        variant="error"
        outline
        icon={<IconSymbol icon="error_outline" size="$sm" color="$error1" variant="filled" />}
      >
        Invalid
      </Badge>
    );
  };

  /**
   * renderCellAllowedSources
   *
   * Displays a concatenated/truncated list of allowed sources within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellAllowedSources = ({ row, cell }) => {
    if (row?.original?.allowableSystems?.length > 1) {
      const sources = row?.original?.allowableSystems;

      return (
        <Tooltip placement="auto" content={<SourcesTooltip sources={sources} />}>
          <span className="truncate">{sources.join(', ')}</span>
        </Tooltip>
      );
    }

    return cell.value;
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.Header === 'Last Updated') {
        column.Cell = renderCellLastUpdated;
      }

      if (column.Header === 'Status') {
        column.Cell = renderCellStatus;
      }

      if (column.Header === 'Allowed Source(s)') {
        column.Cell = renderCellAllowedSources;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Actions/screens/List/components/Table/Table.jsx">
      <TableComponent
        {...{
          columns,
          configuration,
          error,
          isLoading,
          requestArgs,
          requestFunction,
          requestKey,
          rows,
          totalPages,
          totalRecords,
        }}
      />
    </ErrorHandler>
  );
};

Table.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      allowableSystems: PropTypes.arrayOf(PropTypes.string),
      lastModifiedDate: PropTypes.string,
      status: PropTypes.bool,
    })
  ),
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  error: null,
  isLoading: false,
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  totalPages: 1,
  totalRecords: 0,
};

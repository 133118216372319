import React, { useEffect } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { Widget } from '@src/components/Widget';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from '@abyss/web/ui/Grid';
import { Loader } from '@src/components/Loader';
import { Table } from './components/Table';
import { Styles } from './includes/styles';

/**
 * Widget: CommonCriteria
 *
 * Displays a list of common criteria filters to apply to an api request.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const CommonCriteria = (props) => {
  const { actionPath, filterKey, form, isCollapsible, showActions, needsUpdate, needsRemoval, context } = props;

  const { useApiQuery } = useApi();

  const [ListCommonCriteria, { data, isLoading, isFetching }] = useApiQuery('ListCommonCriteria');

  /**
   * Fetch common criteria from the API.
   */
  useEffect(() => {
    if (isUndefined(data)) {
      ListCommonCriteria({
        page: 0,
        size: 9999,
        sort: 'name,asc',
        isActive: true,
      });
    }
  }, [data, actionPath]);

  return (
    <ErrorHandler location="src/common/widgets/CommonCriteria/CommonCriteria.jsx">
      <Styles>
        <Widget
          icon="settings"
          title="Common Criteria"
          backgroundColor="var(--abyss-colors-gray1)"
          collapsible={isCollapsible}
        >
          <Grid>
            {isUndefined(data) || isLoading || isFetching ? (
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <Loader backgroundColor="transparent" height="100%" width="100%" />
              </Grid.Col>
            ) : (
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <Table
                  rows={data?.content || []}
                  form={form}
                  filterKey={filterKey}
                  context={context}
                  showActions={showActions}
                  needsUpdate={needsUpdate}
                  needsRemoval={needsRemoval}
                />
              </Grid.Col>
            )}
          </Grid>
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

CommonCriteria.propTypes = {
  actionPath: PropTypes.string,
  filterKey: PropTypes.string,
  form: PropTypes.shape({}),
  isCollapsible: PropTypes.bool,
  showActions: PropTypes.bool,
  needsUpdate: PropTypes.bool,
  needsRemoval: PropTypes.bool,
  context: PropTypes.string,
};

CommonCriteria.defaultProps = {
  actionPath: '',
  filterKey: '',
  form: {},
  isCollapsible: true,
  showActions: true,
  needsUpdate: true,
  needsRemoval: true,
  context: '',
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  position: 'relative',
  height: 'inherit',
  minHeight: 'inherit',
  maxHeight: 'inherit',
  '#chart': {
    position: 'relative',
    height: 'inherit',
    minHeight: 'inherit',
    maxHeight: 'inherit',
    overflow: 'hidden',
    paddingTop: 'var(--abyss-space-md)',
    paddingBottom: 'var(--abyss-space-sm)',
  },
  '.apexcharts-svg': {
    overflow: 'visible',
  },
});

import React, { useEffect, useMemo, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty } from 'lodash';
import { Loader } from '@src/components/Loader';
import { useApi } from '@src/context/Api';
import PropTypes from 'prop-types';
import { ActionPaths } from './components/ActionPaths';
import { Attribute } from './components/Attribute';
import { Dates } from './components/Dates';
import { Details } from './components/Details';
import { Eimp } from './components/Eimp';
import { EventTimeline } from './components/EventTimeline';
import { RemediationEvents } from './components/RemediationEvents';
import { Tags } from './components/Tags';
import { TrustedValueHistory } from './components/TrustedValueHistory';

/**
 * Results
 *
 * Displays a wealth of information regarding the results of an EID.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Results = (props) => {
  const { riskRecord, refetchRiskRecord } = props;

  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const theAssets = ['ListRiskCodes', 'ListTags'];
  const { useApiQueries } = useApi();
  const assets = useApiQueries(theAssets);

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((assetKey) => {
          const asset = assets[assetKey];
          return !(!asset?.isLoading && !asset?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * check if the EID has a DOB attribute
   */
  const hasDOB = useMemo(() => {
    return riskRecord?.ireRiskRecord?.remediationFindings?.find((attribute) => {
      return attribute.attributeType === 'dob';
    });
  }, [riskRecord?.ireRiskRecord?.remediationFindings]);

  /**
   * check if the EID has an SSN attribute
   */
  const hasSSN = useMemo(() => {
    return riskRecord?.ireRiskRecord?.remediationFindings?.find((attribute) => {
      return attribute.attributeType === 'ssn';
    });
  }, [riskRecord?.ireRiskRecord?.remediationFindings]);

  if (isLoadingAssets) {
    return (
      <Grid.Col span={{ xs: '100%' }}>
        <Loader verticalAlignment="top" />
      </Grid.Col>
    );
  }

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/Results.jsx">
      <Grid id="eidSearchResults" css={{ padding: 0 }}>
        <Grid.Col span={{ xs: '100%' }}>
          <Details
            riskRecord={riskRecord}
            eid={riskRecord?.eid}
            ireRiskRecord={riskRecord?.ireRiskRecord}
            riskCodes={assets?.ListRiskCodes?.data}
            associatedEids={riskRecord?.associatedEID}
            refetchRiskRecord={refetchRiskRecord}
            actionPaths={riskRecord?.recordActionPaths}
            tags={riskRecord?.tags}
          />
        </Grid.Col>

        <Grid.Col span={{ xs: '100%' }}>
          <EventTimeline eid={riskRecord?.eid} />
        </Grid.Col>

        {hasDOB && (
          <Grid.Col span={{ xs: '50%' }}>
            <Attribute attributeType="dob" ireRiskRecord={riskRecord?.ireRiskRecord} />
          </Grid.Col>
        )}

        {hasSSN && (
          <Grid.Col span={{ xs: '50%' }}>
            <Attribute attributeType="ssn" ireRiskRecord={riskRecord?.ireRiskRecord} />
          </Grid.Col>
        )}

        <Grid.Col span={{ xs: '100%' }}>
          <ActionPaths
            actionPaths={riskRecord?.recordActionPaths}
            eid={riskRecord?.eid}
            refetchRiskRecord={refetchRiskRecord}
          />
        </Grid.Col>

        <Grid.Col span={{ xs: '100%' }}>
          <Eimp
            trustedRecords={riskRecord?.identityInformation?.eimpSourceRecords?.trustedEIMPRecords || []}
            untrustedRecords={riskRecord?.identityInformation?.eimpSourceRecords?.untrustedEIMPRecords || []}
            changeData={riskRecord?.identityInfo?.entityChange}
          />
        </Grid.Col>

        <Grid.Col span={{ xs: '100%' }}>
          <Tags
            tags={assets?.ListTags?.data || []}
            addedTags={riskRecord?.tagModificationDetail?.currentTagList || []}
            removedTags={riskRecord?.tagModificationDetail?.removedTagList || []}
          />
        </Grid.Col>

        <Grid.Col span={{ xs: '100%' }}>
          <RemediationEvents remediationEvents={riskRecord?.remediation?.remediationEvents} />
        </Grid.Col>

        <Grid.Col span={{ xs: '100%' }}>
          <TrustedValueHistory eid={riskRecord?.eid} />
        </Grid.Col>

        <Grid.Col span={{ xs: '50%' }}>
          <Dates
            data={{
              streamReceived: riskRecord?.streamTimeReceived,
              lastModifiedDate: riskRecord?.lastModifiedDate,
              createdDate: riskRecord?.createdDate,
              replayStreamReceived: riskRecord?.replayStreamTimeReceived,
            }}
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

Results.propTypes = {
  riskRecord: PropTypes.shape({
    eid: PropTypes.string,
    ireRiskRecord: PropTypes.shape({
      remediationFindings: PropTypes.arrayOf(
        PropTypes.shape({
          attributeType: PropTypes.string,
        })
      ),
    }),
    associatedEID: PropTypes.arrayOf(PropTypes.string),
    recordActionPaths: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    identityInformation: PropTypes.shape({
      eimpSourceRecords: PropTypes.shape({
        trustedEIMPRecords: PropTypes.arrayOf(PropTypes.string),
        untrustedEIMPRecords: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    identityInfo: PropTypes.shape({
      entityChange: PropTypes.string,
    }),
    tagModificationDetail: PropTypes.shape({
      currentTagList: PropTypes.arrayOf(PropTypes.string),
      removedTagList: PropTypes.arrayOf(PropTypes.string),
    }),
    remediation: PropTypes.shape({
      remediationEvents: PropTypes.arrayOf(PropTypes.string),
    }),
    streamTimeReceived: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    createdDate: PropTypes.string,
    replayStreamTimeReceived: PropTypes.string,
  }),
  refetchRiskRecord: PropTypes.func,
};

Results.defaultProps = {
  riskRecord: {},
  refetchRiskRecord: () => {},
};

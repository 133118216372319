import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isUndefined } from 'lodash';

/**
 * GetRiskDashboardStatistics
 *
 * Retrieves a single risk record from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetRiskDashboardStatistics = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {
        'x-api-endpoint': `/dashboard/risk`,
      },
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ queryKey: payload[0] }, ...payload[1] };
      requestArgs.headers['x-api-endpoint'] += `/${payload[1]?.date}`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error(
      'src/requests/queries/GetRiskDashboardStatistics.js -> GetRiskDashboardStatistics() -> error:',
      theError
    );

    throw error;
  }
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-modal-footer': {
    '.abyss-layout-group': {
      paddingTop: 'var(--abyss-space-sm)',
    },
  },
  '.abyss-modal-section-root': {
    position: 'relative',
  },
  '.abyss-checkbox-root': {
    marginTop: 'var(--abyss-space-md)',
  },
  '.abyss-checkbox-label': {
    fontWeight: 'bold !important',
  },
});

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isUndefined, omit } from 'lodash';

/**
 * ListCommonCriteriaVersions
 *
 * Retrieves a list of common criteria versions from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ListCommonCriteriaVersions = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = omit(payload?.[1], ['page', 'size', 'sort']);

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {
        'x-api-endpoint': '/criteria',
      },
      params: { queryKey: theQueryKey },
    };

    if (!isUndefined(payload?.[1]?.page)) {
      requestArgs.params.page = payload?.[1]?.page;
    }

    if (!isUndefined(payload?.[1]?.size)) {
      requestArgs.params.size = payload?.[1]?.size;
    }

    if (!isUndefined(payload?.[1]?.sort)) {
      requestArgs.params.sort = payload?.[1]?.sort;
    }

    if (!isEmpty(thePayload)) {
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}/version`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error(
      'src/requests/queries/ListCommonCriteriaVersions.js -> ListCommonCriteriaVersions() -> error:',
      theError
    );

    throw error;
  }
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-indicator-root': {
    '.abyss-indicator-container': {
      background: 'var(--abyss-colors-warning2) !important',
      borderColor: 'var(--abyss-colors-warning2) !important',
      '.abyss-indicator-label': {
        color: 'var(--abyss-colors-warning1) !important',
        fontWeight: 'bold',
      },
    },
  },
});

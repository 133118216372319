import { ACTIONS } from './actions';
import { updateComponent } from './reducers';

/**
 * reducer
 *
 * @TODO Needs description
 *
 * @param state
 * @param action
 * @returns {{}}
 */
export function reducer(state = {}, action = {}) {
  switch (action?.type) {
    case ACTIONS?.UPDATE_COMPONENT:
      return updateComponent(state, action?.payload);
    default:
      console.error('UNKNOWN ACTION SPECIFIED!!', 'src/context/Visibility/includes/reducer.js');
      return state;
  }
}

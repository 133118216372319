import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  fieldset: {
    background: 'var(--abyss-colors-error2)',
    border: '1px solid var(--abyss-colors-error1)',
    borderRadius: '4px',
    padding: 'var(--abyss-space-lg) var(--abyss-space-md)',
    legend: {
      background: 'var(--abyss-colors-error2)',
      border: '1px solid var(--abyss-colors-error1)',
      borderRadius: '4px',
      color: 'var(--abyss-colors-error1)',
      fontWeight: 'var(--abyss-fontWeights-bold)',
      padding: '2px 8px',
      h6: {
        color: 'var(--abyss-colors-error1) !important',
        whiteSpace: 'nowrap',
        fontSize: '0.875rem !important',
      },
    },
    '.abyss-flex-root': {
      '.item': {
        textAlign: 'center',
        minWidth: 'calc(100% / 3)',
      },
      h4: {
        color: 'var(--abyss-colors-black) !important',
        marginTop: 'var(--abyss-space-sm)',
      },
    },
  },
});

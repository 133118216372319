import PropTypes from 'prop-types';
import React from 'react';
import { Actions as Table } from '@src/common/tables';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { Styles } from './includes/styles';
import { Description } from './components/Description';

/**
 * Widget: RemediationActions
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RemediationActions = (props) => {
  const { assignments, remediationType, scopeCode } = props;

  return (
    <ErrorHandler location="src/common/widgets/RemediationActions/RemediationActions.jsx">
      <Styles>
        <Widget
          title="Remediation Actions"
          description={<Description scopeCode={scopeCode || ''} remediationType={remediationType || ''} />}
        >
          <Table rows={assignments || []} />
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

RemediationActions.propTypes = {
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      status: PropTypes.string,
      type: PropTypes.string,
      source: PropTypes.string,
      remediationType: PropTypes.string,
      scopeCode: PropTypes.string,
    })
  ),
  remediationType: PropTypes.string,
  scopeCode: PropTypes.string,
};

RemediationActions.defaultProps = {
  assignments: [],
  remediationType: '',
  scopeCode: '',
};

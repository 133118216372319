import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { DateInput } from '@abyss/web/ui/DateInput';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Styles } from './includes/styles';

/**
 * DateRange
 *
 * Displays a date range input component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const DateRange = (props) => {
  const { defaultEndDate, defaultStartDate, isClearable, isRequired, maximumDate, minimumDate, onChange } = props;

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  /**
   * Sync component state with user supplied call back functions.
   */
  useEffect(() => {
    try {
      if (!isUndefined(onChange)) {
        (async () => {
          await onChange({ startDate, endDate });
        })();
      }
    } catch (error) {
      console.error('src/components/DateRange/DateRange.jsx -> onChange() -> error:', error);
    }
  }, [startDate, endDate]);

  return (
    <ErrorHandler location="src/components/DateRange/DateRange.jsx">
      <Styles>
        <Layout.Group space={themeConfiguration?.theme?.space?.md} alignLayout="left" alignItems="bottom" grow>
          <DateInput
            model="dateRange.start"
            label="Date Range"
            isClearable={isClearable}
            value={startDate}
            validators={{ required: isRequired }}
            subText=""
            onChange={(date = '') => {
              setStartDate(date);
            }}
            onClear={() => {
              return setStartDate(null);
            }}
            excludeDate={(date) => {
              const timestamp = date.getTime();
              const endDateTimestamp = new Date(endDate).getTime();
              return timestamp >= endDateTimestamp;
            }}
            minimumDate={minimumDate}
            maximumDate={maximumDate}
          />
          <DateInput
            model="dateRange.end"
            label=""
            hideLabel
            isClearable={isClearable}
            value={endDate}
            validators={{ required: true }}
            subText=""
            onChange={(date = '') => {
              setEndDate(date);
            }}
            onClear={() => {
              return setEndDate(null);
            }}
            excludeDate={(date) => {
              const timestamp = date.getTime();
              const startDateTimestamp = new Date(startDate).getTime();
              return timestamp <= startDateTimestamp;
            }}
            minimumDate={minimumDate}
            maximumDate={maximumDate}
          />
        </Layout.Group>
      </Styles>
    </ErrorHandler>
  );
};

DateRange.propTypes = {
  defaultEndDate: PropTypes.instanceOf(Date),
  defaultStartDate: PropTypes.instanceOf(Date),
  isClearable: PropTypes.bool,
  isRequired: PropTypes.bool,
  maximumDate: PropTypes.instanceOf(Date),
  minimumDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};

DateRange.defaultProps = {
  defaultEndDate: null,
  defaultStartDate: null,
  isClearable: true,
  isRequired: false,
  maximumDate: null,
  minimumDate: null,
  onChange: null,
};

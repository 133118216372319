import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isUndefined } from 'lodash';

/**
 * ScheduleExport
 *
 * @TODO Needs description.
 *
 * @param payload
 * @returns {Promise<any|string>}
 * @constructor
 */
export const ScheduleExport = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': `/action-paths`,
      },
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ mutationKey: payload[0] } };
    }

    if (!isUndefined(payload?.[1]?.actionPathId) && !isEmpty(payload?.[1]?.actionPathId)) {
      requestArgs.headers['x-api-endpoint'] += `/${payload?.[1]?.actionPathId}/extracts`;
    }

    if (isEmpty(payload?.[1]?.maxRecords)) {
      requestArgs.data = {
        maxRecords: 1000000,
      };
    } else {
      requestArgs.data = payload?.[1]?.maxRecords;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/ScheduleExport.js -> ScheduleExport() -> error:', theError);

    throw error;
  }
};

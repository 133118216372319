import PropTypes from 'prop-types';
import React from 'react';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';

/**
 * NavIconTitle
 *
 * Renders an icon and title for usage in a navigation component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const NavIconTitle = (props) => {
  const { iconName, title } = props;

  return (
    <div className="nav-icon-wrap">
      <IconMaterial icon={iconName} />
      <Text>{title}</Text>
    </div>
  );
};

NavIconTitle.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

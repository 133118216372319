import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-table-root': {
    '.abyss-table-head': {
      '.abyss-table-header': {
        'th:first-child': {
          flex: 'none !important',
          width: '52px !important',
          minWidth: '52px !important',
        },
        'th:nth-of-type(2)': {
          flex: 'auto !important',
          width: '15% !important',
          minWidth: '15% !important',
        },
        'th:nth-of-type(3)': {
          flex: 'auto !important',
          width: '15% !important',
          minWidth: '15% !important',
        },
        'th:nth-of-type(4)': {
          flex: 'auto !important',
          width: '50% !important',
          minWidth: '50% !important',
        },
        'th:last-child': {
          flex: 'none !important',
          width: '150px !important',
          minWidth: '150px !important',
        },
      },
    },
    '.abyss-table-body': {
      '.abyss-table-row': {
        'td:first-child': {
          flex: 'none !important',
          width: '52px !important',
          minWidth: '52px !important',
        },
        'td:nth-of-type(2)': {
          flex: 'auto !important',
          width: '15% !important',
          minWidth: '15% !important',
        },
        'td:nth-of-type(3)': {
          flex: 'auto !important',
          width: '15% !important',
          minWidth: '15% !important',
        },
        'td:nth-of-type(4)': {
          flex: 'auto !important',
          width: '50% !important',
          minWidth: '50% !important',
        },
        'td:last-child': {
          flex: 'none !important',
          width: '150px !important',
          minWidth: '150px !important',
        },
      },
    },
  },
});

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';

/**
 * ReEvaluateActionPath
 *
 * Re-trigger the activation of the action path and will go and find records that meet the entrance criteria that are
 * not currently on the Action Path.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ReEvaluateActionPath = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'PUT',
      headers: {
        'x-api-endpoint': `/action-paths/re-evaluate/${thePayload?.actionPathId}`,
      },
      params: { mutationKey: theMutationKey },
      data: {},
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/ReEvaluateActionPath.js -> ReEvaluateActionPath() -> error:', theError);

    throw error;
  }
};

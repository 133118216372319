import PropTypes from 'prop-types';
import React from 'react';

/**
 * EventType
 *
 * Displays the event type description based on the event type code.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const EventType = (props) => {
  const { eventTypeCode, eventTypes } = props;

  const eventType = eventTypes?.find((type) => {
    return type?.codeId === eventTypeCode;
  });

  return <div>{eventType?.codeDesc}</div>;
};

EventType.propTypes = {
  eventTypeCode: PropTypes.string,
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      codeId: PropTypes.string,
      codeDesc: PropTypes.string,
    })
  ),
};

EventType.defaultProps = {
  eventTypeCode: '',
  eventTypes: [],
};

import PropTypes from 'prop-types';
import React from 'react';
import stc from 'string-to-color';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { hexToRgb } from '@src/includes/functions';

const stringToColor = stc;

/**
 * LegendHeight
 *
 * Adds spacing between the legend and the chart.
 *
 * @type {{id: string, beforeInit(*): void}}
 */
const LegendHeight = {
  id: 'LegendHeight',
  beforeInit(chart) {
    const origFit = chart.legend.fit;

    // eslint-disable-next-line no-param-reassign
    chart.legend.fit = function fit() {
      origFit.bind(chart.legend)();
      this.height += 50;
    };
  },
};

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LegendHeight);

/**
 * chart: Donut
 *
 * Displays a donut chart.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Donut = (props) => {
  const { labels, data } = props;

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/View/components/Chart/Donut.jsx">
      <div
        style={{
          height: '100%',
          margin: '0px auto',
          maxHeight: '325px',
          maxWidth: '520px',
          minHeight: '260px',
          position: 'relative',
        }}
      >
        <Doughnut
          options={{
            animation: {
              duration: 0, // general animation time
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'top',
                labels: {
                  boxHeight: 16,
                  boxWidth: 16,
                  useBorderRadius: false,
                  generateLabels: (chart) => {
                    return chart.data.labels.map((label, i) => {
                      return {
                        text: label,
                        fillStyle: chart.data.datasets[0].backgroundColor[i],
                        hidden: chart.data.datasets[0].data[i] === 0 ? true : !chart.getDataVisibility(i),
                        index: i,
                      };
                    });
                  },
                },
              },
            },
          }}
          data={{
            labels: labels || [],
            datasets: [
              {
                label: '# of records',
                data:
                  Object.keys(data)?.map((key) => {
                    const item = data[key];
                    return item?.amount;
                  }) || [],
                backgroundColor: labels?.map((label) => {
                  return hexToRgb(stringToColor(label), 1.0);
                }),
                borderColor: labels?.map((label) => {
                  return hexToRgb(stringToColor(label), 1.0);
                }),
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>
    </ErrorHandler>
  );
};

Donut.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      type: PropTypes.string,
      percent: PropTypes.number,
    })
  ),
};

Donut.defaultProps = {
  labels: [],
  data: [],
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';

/**
 * GetActionPathTrackerStatus
 *
 * Retrieves the status information of the action path tracker from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetActionPathTrackerStatus = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {
        'x-api-endpoint': `/action-paths/${thePayload?.actionPathId}/tracker/latest`,
      },
      params: {
        queryKey: theQueryKey,
      },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error(
      'src/requests/queries/GetActionPathTrackerStatus.js -> GetActionPathTrackerStatus() -> error:',
      theError
    );

    throw error;
  }
};

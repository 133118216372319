import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-flex-root': {
    position: 'relative',
    width: '100vw !important',
    height: '100vh !important',
    background: 'var(--abyss-colors-white)',
    '.abyss-box-root': {
      position: 'relative',
      '.logo': {
        paddingLeft: 'var(--abyss-space-lg)',
        paddingRight: 'var(--abyss-space-lg)',
        width: '100%',
        height: 'auto',
      },
      '.abyss-card-root': {
        position: 'relative',
        width: '100%',
        borderRadius: '0px',
        borderColor: 'var(--abyss-colors-gray4)',
        marginTop: 'var(--abyss-space-md)',
        paddingTop: 'var(--abyss-space-xl)',
        paddingBottom: 'var(--abyss-space-xl)',
        paddingLeft: 'var(--abyss-space-lg)',
        paddingRight: 'var(--abyss-space-lg)',
        '.abyss-divider-root': {
          backgroundColor: 'var(--abyss-colors-gray3)',
          marginTop: 'var(--abyss-space-md)',
          marginBottom: 'var(--abyss-space-lg)',
        },
        '.abyss-layout-stack': {
          textAlign: 'center',
        },
        '.abyss-link-root': {
          marginTop: 'var(--abyss-space-xl)',
        },
      },
    },
  },
});

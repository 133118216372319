import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-text-input-root': {
    marginRight: 'var(--abyss-space-md)',
    "*[class*='isInvalid-true']": {
      bottom: 'auto',
    },
    '.abyss-text-input-right-add-on': {
      padding: 0,
      fontSize: 'var(--abyss-fontSizes-sm)',
      lineHeight: '100%',
      border: 'none',
      background: 'none',
    },
    '.abyss-text-input-right-element': {
      '&>div': {
        position: 'relative',
        height: '100%',
      },
      button: {
        height: '100%',
        borderTopWidth: '1px !important',
        borderBottomWidth: '1px !important',
      },
    },
  },
});

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';

/**
 * Logger
 *
 * Used to output a console message server-side so that Data Dog can capture the output.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const Logger = async (payload) => {
  try {
    const remoteResponse = await Axios.request({
      baseURL: config('API_URL'),
      url: '/logger',
      method: 'POST',
      data: payload,
    });

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/Logger.js -> Logger() -> error:', theError);

    throw error;
  }
};

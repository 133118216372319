import { config } from '@abyss/web/tools/config';
import { isUndefined } from 'lodash';
import { Axios } from '@src/context/Api/includes/Axios';

/**
 * ListViews
 *
 * Retrieves a list of aggregation views from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<*>}
 * @constructor
 */
export const ListViews = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {
        'x-api-endpoint': '/aggregation/playground',
      },
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { ...{ queryKey: payload[0] }, ...payload[1] };
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/ListViews.js -> ListViews() -> error:', theError);

    throw error;
  }
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty, isUndefined } from 'lodash';
import { Widget } from '@src/components/Widget';
import { Table } from './components/Table';

/**
 * Tags
 *
 * Displays affiliated tags with the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Tags = (props) => {
  const { addedTags, removedTags, tags } = props;

  const [rows, setRows] = useState([]);

  /**
   * Set the rows for the table.
   */
  useEffect(() => {
    const theTags = [
      ...addedTags.map((addedTag) => {
        const items = addedTag?.tag?.split('-');

        return {
          category: items?.[0],
          name: items?.[1],
          lastModified: addedTag?.lastAddedDate,
          status: 'Added',
          description: '',
        };
      }),
      ...removedTags.map((removedTag) => {
        const items = removedTag?.tag?.split('-');

        return {
          category: items?.[0],
          name: items?.[1],
          lastModified: removedTag?.lastRemovedDate,
          status: 'Removed',
          description: '',
        };
      }),
    ];

    const theRows = theTags?.map((theTag) => {
      const theRow = { ...theTag };

      if (!isUndefined(tags?.tagsList?.[theTag?.category])) {
        const matchedTag = tags?.tagsList?.[theTag?.category].find((tag) => {
          return tag?.categoryCode === theTag?.category && tag?.code === theTag?.name;
        });

        if (!isUndefined(matchedTag)) {
          theRow.description = matchedTag?.categoryDesc;
        }
      }

      return theRow;
    });

    if (theRows !== rows) {
      setRows(theRows);
    }
  }, [addedTags, removedTags, tags]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Tags/Tags.jsx">
      <Widget title="Tags" description="All Tags triggered by EID" collapsible collapsed>
        {isEmpty(rows) ? (
          <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}>
            <Alert title="There are no tags affiliated with this risk record." variant="info" />
          </Grid.Col>
        ) : (
          <Table rows={rows} />
        )}
      </Widget>
    </ErrorHandler>
  );
};

Tags.propTypes = {
  addedTags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string,
      lastAddedDate: PropTypes.string,
    })
  ),
  removedTags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string,
      lastRemovedDate: PropTypes.string,
    })
  ),
  tags: PropTypes.shape({
    tagsList: PropTypes.shape({
      categoryCode: PropTypes.string,
      code: PropTypes.string,
      categoryDesc: PropTypes.string,
    }),
  }),
};

Tags.defaultProps = {
  addedTags: [],
  removedTags: [],
  tags: { tagsList: {} },
};

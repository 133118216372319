import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@src/components/Button';
import { config } from '@abyss/web/tools/config';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Styles } from './includes/styles';

/**
 * DeletionModal
 *
 * Provides the user with a modal to confirm the deletion of an action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const DeletionModal = (props) => {
  const { isOpen, setIsOpen, actionPath, setActionPath, handleDeletion } = props;

  const router = useRouter();

  /**
   * handleDelete
   *
   * Sends the api request to delete the action path.
   *
   * @returns {Promise<void>}
   */
  const handleDelete = async () => {
    try {
      setIsOpen(false);
      setActionPath({});
      await handleDeletion(actionPath);
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/ActionPaths/screens/List/components/DeletionModal/DeletionModal.jsx -> handleSave() -> error:',
        theError
      );

      throw error;
    }
  };

  /**
   * handleClose
   *
   * Highlights the row in the table for the action path which was requested to activate, but the user backed out. The
   * goal is to make it easy to identify which row in the table the user attempted to take action on, in-case they
   * change their mind and want to continue with the same one again.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    try {
      setIsOpen(false);
      setActionPath({});
      router?.navigate(`/`);
      router?.navigate(`/action-paths?highlight=${actionPath?.id}`);
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/ActionPaths/screens/List/components/DeletionModal/DeletionModal.jsx -> handleClose() -> error:',
        theError
      );

      throw error;
    }
  };
  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/List/components/DeletionModal/DeletionModal.jsx">
      <Modal
        title="Confirm Deletion"
        isOpen={isOpen}
        onClose={handleClose}
        footer={
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="solid" onClick={handleDelete}>
                Delete
              </Button>
            </Layout.Group>
          </Modal.Section>
        }
      >
        <Styles>
          <Modal.Section>
            Are you sure you want to delete action path: <strong>{actionPath?.name}</strong> ?
          </Modal.Section>
        </Styles>
      </Modal>
    </ErrorHandler>
  );
};

DeletionModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  actionPath: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
  setActionPath: PropTypes.func,
  handleDeletion: PropTypes.func,
};

DeletionModal.defaultProps = {
  isOpen: false,
  setIsOpen: () => {},
  actionPath: {},
  setActionPath: () => {},
  handleDeletion: () => {},
};

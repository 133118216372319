import PropTypes from 'prop-types';
import React from 'react';
import { config } from '@abyss/web/tools/config';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';

/**
 * ViewSharePoint
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ViewSharePoint = (props) => {
  const { exportDetails, currentEntity } = props;

  const sharePointEnvironments = {
    prod: 'remhub',
    stage: 'remhub-stg',
    dev: 'remhub-dev',
    local: 'remhub-dev',
    'local:dev': 'remhub-dev',
    'local:build': 'remhub-dev',
  };

  const sharePointDirectoryUrl = `https://uhgazure.sharepoint.com/teams/RemHubReports/Shared%20Documents/General/${encodeURIComponent(
    `${sharePointEnvironments[config('APP_ENV')]}/${currentEntity?.name}`
  )}`;

  const isDisabled = exportDetails?.status === 'exported';

  return (
    <ErrorHandler location="src/common/tables/RiskRecords/components/Header/components/ViewSharePoint/ViewSharePoint.jsx">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        before={
          <IconMaterial
            icon="visibility"
            color={isDisabled ? 'var(--abyss-colors-gray5)' : 'var(--abyss-colors-interactive1)'}
          />
        }
        href={sharePointDirectoryUrl}
        isDisabled={isDisabled}
        openNewWindow
        hideIcon
        onClick={(event) => {
          if (isDisabled) {
            event?.preventDefault();
          }
        }}
      >
        View on SharePoint
      </Link>
    </ErrorHandler>
  );
};

ViewSharePoint.propTypes = {
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  exportDetails: PropTypes.shape({
    status: PropTypes.string,
  }),
};

ViewSharePoint.defaultProps = {
  currentEntity: {},
  exportDetails: {},
};

import { isEmpty, isUndefined, orderBy } from 'lodash';

/**
 * getCategoryOptions
 *
 * This function is used to get the category options for dropdown.
 *
 * @param data
 * @returns {Array|*[]}
 */
export function getCategoryOptions(data = {}) {
  const tagCategoryOptions = [];

  if (isEmpty(data)) {
    return tagCategoryOptions;
  }

  Object.keys(data).forEach((categoryCode) => {
    data[categoryCode].forEach((tag) => {
      const tagCategoryItem = {
        label: `${tag?.categoryDesc} (${tag?.categoryCode})`,
        value: tag?.categoryCode,
      };
      const exists = tagCategoryOptions.find((item) => {
        return item.value === tag?.categoryCode;
      });

      if (isUndefined(exists)) {
        tagCategoryOptions.push(tagCategoryItem);
      }
    });
  });

  return orderBy(tagCategoryOptions, ['label'], ['asc']);
}

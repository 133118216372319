import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from '@src/components/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { motion } from 'framer-motion';
import { Text } from '@abyss/web/ui/Text';
import { Visibility } from '@src/components/Visibility';
import { WithCard } from '../WithCard';

/**
 * Widget
 *
 * The logic for the Widget component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const TheWidget = (props) => {
  const {
    backgroundColor,
    button,
    children,
    collapsed,
    collapsible,
    description,
    icon,
    isFullscreen,
    menu,
    setIsFullscreen,
    title,
    withCard,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(collapsed || false);

  return (
    <motion.div
      animate="open"
      variants={{
        open: { opacity: 1 },
        closed: { opacity: 0 },
      }}
      initial={{ opacity: 0 }}
    >
      <div className="widget">
        <WithCard withCard={withCard} backgroundColor={backgroundColor}>
          <Flex
            className="widget-header"
            justify="space-between"
            alignItems={isEmpty(description) ? 'center' : 'flex-start'}
          >
            <Layout.Group alignItems="top">
              {!isEmpty(icon) && (
                <div>
                  <IconSymbol icon={icon} variant="outlined" color="inherit" />
                </div>
              )}
              <Layout.Stack alignItems="left" space={0}>
                <Heading offset={5}>{title}</Heading>
                {!isEmpty(description) && (
                  <div>
                    <Text>{description}</Text>
                  </div>
                )}
              </Layout.Stack>
            </Layout.Group>

            <Layout.Group className="widget-options">
              {!isUndefined(button) && button}
              {!isEmpty(menu) && (
                <div>
                  <DropdownMenu
                    label="options"
                    hideLabel
                    after={<IconSymbol icon="more_vert" color="$gray7" variant="outlined" />}
                    menuItems={menu || []}
                    css={{
                      '.abyss-dropdown-menu-trigger': {
                        backgroundColor: 'none',
                        padding: 0,
                        margin: 0,
                      },
                    }}
                  />
                </div>
              )}
              {collapsible === true && (
                <Button
                  variant="ghost"
                  rounded
                  size="$sm"
                  onClick={() => {
                    return setIsCollapsed(!isCollapsed);
                  }}
                  css={{
                    border: '1px solid var(--abyss-colors-interactive1) !important',
                    borderRadius: '4px !important',
                    margin: 0,
                  }}
                >
                  {isCollapsed ? (
                    <IconSymbol icon="expand_less" variant="outlined" />
                  ) : (
                    <IconSymbol icon="expand_more" variant="outlined" />
                  )}
                </Button>
              )}
              <Visibility
                accessor="FullScreenWidgets"
                enabledEnvironments={[]}
                disabledEnvironments={['Local', 'Development', 'Stage', 'Production']}
              >
                <div>
                  <button type="button">
                    <IconSymbol
                      icon="fullscreen"
                      css={{
                        cursor: 'pointer',
                        backgroundColor: 'none',
                        padding: 'var(--abyss-space-sm)',
                        margin: 0,
                      }}
                      onClick={() => {
                        return setIsFullscreen(!isFullscreen);
                      }}
                    />
                  </button>
                </div>
              </Visibility>
            </Layout.Group>
          </Flex>
          {!isCollapsed && (
            <React.Fragment>
              <Divider className="widget-divider" />
              <div className="widget-body">
                <motion.div
                  animate="open"
                  variants={{
                    open: { opacity: 1 },
                    closed: { opacity: 0 },
                  }}
                  initial={{ opacity: 0 }}
                >
                  {children}
                </motion.div>
              </div>
            </React.Fragment>
          )}
        </WithCard>
      </div>
    </motion.div>
  );
};

TheWidget.propTypes = {
  backgroundColor: PropTypes.string,
  button: PropTypes.node,
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  collapsible: PropTypes.bool,
  description: PropTypes.string,
  icon: PropTypes.string,
  isFullscreen: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onChange: PropTypes.func,
      radios: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  setIsFullscreen: PropTypes.func,
  title: PropTypes.string.isRequired,
  withCard: PropTypes.bool,
};

TheWidget.defaultProps = {
  backgroundColor: '',
  button: null,
  children: null,
  collapsed: false,
  collapsible: false,
  description: '',
  icon: '',
  isFullscreen: false,
  menu: [],
  setIsFullscreen: () => {},
  withCard: true,
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isUndefined } from 'lodash';

/**
 * GetActionPathCount
 *
 * Retrieves count of risk records affiliated with an action path.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetActionPathCount = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {
        'x-api-endpoint': `/action-paths`,
      },
    };

    if (!isUndefined(payload)) {
      requestArgs.params = { queryKey: payload[0] };
      requestArgs.headers['x-api-endpoint'] += `/${payload[1]?.actionPathId}/count`;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/GetActionPathCount.js -> GetActionPathCount() -> error:', theError);

    throw error;
  }
};

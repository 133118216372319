import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.flexGrid': {
    display: 'flex !important',
    flexDirection: 'row !important',
    flexWrap: 'wrap !important ',
    justifyContent: 'flex-start',
    alignItems: 'stretch !important',
    alignContent: 'stretch !important',
  },
  '.grow': {
    display: 'flex !important',
    flex: '1 1 33.33% !important',
    width: '33.33%',
    flexWrap: 'wrap !important',
    maxWidth: 'unset !important',
    flexBasis: 'unset !important',
    '> div': {
      width: '100%',
      height: '100%',
      '.widget': {
        width: '100%',
        height: '100%',
        '.widget-inner': {
          width: '100%',
          height: '100%',
        },
      },
    },
  },
});

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { Badge } from '@abyss/web/ui/Badge';
import { Chart } from '@src/components/Chart';
import { chartTypes } from '@src/components/Chart/includes/chartTypes';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { isEmpty, isUndefined, orderBy } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Loader } from '@src/components/Loader';
import { motion } from 'framer-motion';
import { Text } from '@abyss/web/ui/Text';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { useApi } from '@src/context/Api';
import { useForm } from '@abyss/web/hooks/useForm';
import { useRoutesContext } from '@src/context/Routes';
import { Widget } from '@src/components/Widget';
import { Styles } from './includes/styles';
import { generateTableRowsFromData } from './includes/functions';
import { Filters } from './components';

/**
 * TagHistory
 *
 * Provides a screen to visualize a risk trend report.
 *
 * @returns {Element}
 * @constructor
 */
export const TagHistory = () => {
  const [currentChartType, setCurrentChartType] = useState('CANDLESTICK_CHART');
  const [isLogarithmicScale, setIsLogarithmicScale] = useState(false);

  const { currentRoute } = useRoutesContext();

  const defaultValues = {
    dateRange: {
      start: dayjs(new Date()).subtract(2, 'weeks').format('MM/DD/YYYY'),
      end: dayjs(new Date()).format('MM/DD/YYYY'),
    },
    frequency: 'DAYS',
    tagVariantThreshold: 10000,
    tagVariantThresholdType: 'fixed',
    tags: [],
    tagCategories: [],
  };

  const [previouslySubmittedValues, setPreviouslySubmittedValues] = useState({});
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const theAssets = ['ListChronoUnits', 'ListTags'];

  const { useApiQuery, useApiQueries } = useApi();

  const [GetTagHistory, { data, isLoading, isFetching }] = useApiQuery('GetTagHistory');

  const assets = useApiQueries(theAssets);

  const form = useForm({ defaultValues });

  const { isSubmitting, isValid } = form?.formState;

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((queryKey) => {
          const query = assets[queryKey];
          return !(!query?.isLoading && !query?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * handleSubmit
   *
   * Calls a remote API to save the form data into a database.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    (submittedValues) => {
      if (!isSubmitting && isValid) {
        const payload = {
          beginDate: dayjs(submittedValues?.dateRange?.start).format('YYYY-MM-DD'),
          endDate: dayjs(submittedValues?.dateRange?.end).format('YYYY-MM-DD'),
          timeUnit: String(submittedValues?.frequency),
        };

        if (!isEmpty(submittedValues?.tags)) {
          payload.tagList = submittedValues?.tags;
        }

        if (!isEmpty(submittedValues?.tagCategories)) {
          payload.tagCategoryList = submittedValues?.tagCategories;
        }

        if (submittedValues?.tagVariantThresholdType === 'fixed') {
          payload.minimumDeviation = Number(submittedValues?.tagVariantThreshold);
        }

        if (submittedValues?.tagVariantThresholdType === 'percentage') {
          payload.minimumDeviationPercentage = Number(submittedValues?.tagVariantThreshold);
        }

        GetTagHistory(payload);

        if (previouslySubmittedValues !== submittedValues) {
          setPreviouslySubmittedValues(submittedValues);
        }
      }
    },
    [isSubmitting, isValid]
  );

  /**
   * If the assets have loaded and the form has not been submitted, submit the form with the default values.
   */
  useEffect(() => {
    if (!isLoadingAssets && isEmpty(previouslySubmittedValues)) {
      handleSubmit(defaultValues);
    }
  }, [isLoadingAssets, previouslySubmittedValues]);

  /**
   * tableColumns
   *
   * Generates the columns for the data table.
   *
   * @type {[{Header: string, accessor: string}]}
   */
  const tableColumns = useMemo(() => {
    const theColumns = [
      {
        Header: 'Date',
        accessor: 'date',
        sticky: '0px',
      },
    ];

    orderBy(data?.tagInfoList, ['tag'], ['asc']).forEach((tagInfo) => {
      theColumns.push({
        Header: tagInfo?.tag,
        accessor: `${tagInfo?.tag}-count`,
        /* eslint-disable react/prop-types */
        Cell: ({ cell, row }) => {
          const colors = {
            red: 'error',
            green: 'success',
            black: 'neutral',
          };

          const totalCount = cell?.value;
          const decreaseCount = row?.original?.[tagInfo?.tag]?.decrease;
          const increaseCount = row?.original?.[tagInfo?.tag]?.increase;

          return (
            <Layout.Stack
              css={{ margin: 'var(--abyss-space-xs) 0px' }}
              space={0}
              alignLayout="right"
              alignItems="right"
            >
              <div>
                <Text>{Number(totalCount).toLocaleString('en-US')}</Text>
              </div>
              <Badge
                variant={decreaseCount > 0 ? colors.green : colors.black}
                css={{
                  backgroundColor: 'transparent !important',
                  paddingRight: '0px !important',
                  textAlign: 'right',
                }}
              >
                {`${decreaseCount > 0 ? '-' : ''}${Number(decreaseCount).toLocaleString('en-US')}`}
              </Badge>
              <Badge
                variant={increaseCount > 0 ? colors.red : colors.black}
                css={{ backgroundColor: 'transparent !important', paddingRight: '0px !important' }}
              >
                {`${increaseCount > 0 ? '+' : ''}${Number(increaseCount).toLocaleString('en-US')}`}
              </Badge>
            </Layout.Stack>
          );
        },
      });
    });

    return theColumns;
  }, [data]);

  /**
   * tableRows
   *
   * Generates the rows for the data table.
   *
   * @type {*[]}
   */
  const tableRows = useMemo(() => {
    return generateTableRowsFromData(data);
  }, [data]);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/TagHistory/TagHistory.jsx">
      <motion.div
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <Styles>
          <FormProvider state={form} autoComplete="off" highlighted onSubmit={handleSubmit}>
            <Grid>
              <Grid.Col span={{ xs: '100%' }}>
                <Heading offset={0}>{currentRoute?.screenTitle}</Heading>
              </Grid.Col>
              {isLoadingAssets ? (
                <Grid.Col span={{ xs: '100%' }}>
                  <Loader verticalAlignment="top" />
                </Grid.Col>
              ) : (
                <React.Fragment>
                  <Grid.Col span={{ xs: '100%' }}>
                    <Filters form={form} assets={assets} previouslySubmittedValues={previouslySubmittedValues} />
                  </Grid.Col>
                  <Grid.Col span={{ xs: '100%' }}>
                    {isLoading || isFetching || isSubmitting ? (
                      <Loader verticalAlignment="top" />
                    ) : (
                      <React.Fragment>
                        {isUndefined(data) || (!isUndefined(data) && isEmpty(data?.tagInfoList)) ? (
                          <Alert title="No data found with specified filters." variant="info" />
                        ) : (
                          <Widget
                            title="Tag Trend Report"
                            description="Count and variation of tags over time."
                            menu={[
                              {
                                title: 'View as...',
                                label: 'View as...',
                                onChange: setCurrentChartType,
                                value: currentChartType,
                                radios: [
                                  {
                                    label: chartTypes?.CANDLESTICK_CHART?.label,
                                    value: 'CANDLESTICK_CHART',
                                  },
                                  {
                                    label: chartTypes?.DATA_TABLE?.label,
                                    value: 'DATA_TABLE',
                                  },
                                  {
                                    label: chartTypes?.LINE_CHART?.label,
                                    value: 'LINE_CHART',
                                  },
                                ],
                              },
                            ]}
                          >
                            {['CANDLESTICK_CHART', 'LINE_CHART'].includes(currentChartType) && (
                              <ToggleSwitch
                                label="Logarithmic Scale"
                                isChecked={isLogarithmicScale}
                                onChange={(event) => {
                                  return setIsLogarithmicScale(event?.target?.checked);
                                }}
                              />
                            )}
                            <Chart
                              type={currentChartType}
                              data={currentChartType === 'CANDLESTICK_CHART' ? data : tableRows}
                              tableConfiguration={{
                                initialColumns: tableColumns,
                                initialState: {
                                  sortBy: [
                                    {
                                      id: 'date',
                                      desc: true,
                                    },
                                  ],
                                },
                                stickyHeaders: true,
                              }}
                              legendLimit={15}
                              isLogarithmicScale={isLogarithmicScale}
                              maxHeight="calc(100vh - 500px) !important"
                              minHeight="600px !important"
                            />
                          </Widget>
                        )}
                      </React.Fragment>
                    )}
                  </Grid.Col>
                </React.Fragment>
              )}
            </Grid>
          </FormProvider>
        </Styles>
      </motion.div>
    </ErrorHandler>
  );
};

import fieldConfiguration from '@src/routes/private/ActionPaths/components/Wizard/includes/fields.json';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { fieldValidator } from '@src/includes/validation';
import { Flex } from '@abyss/web/ui/Flex';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, isNil, isUndefined, merge, orderBy } from 'lodash';
import { Link } from '@abyss/web/ui/Link';
import { NumberInput } from '@abyss/web/ui/NumberInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Table as TableComponent } from '@src/components/Table';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useCurrentEnvironment } from '@src/hooks/useCurrentEnvironment';
import { Styles } from './includes/styles';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * @TODO - Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { remove, form, handleReset, sources, chronoUnits, actions, dataKey, fields, isLoading } = props;

  const currentEnvironment = useCurrentEnvironment();

  const assignments = form.getValues('remediation.assignments');

  const {
    actionId: fieldAction,
    impactedSource: fieldUntrustedSource,
    retryAttempts: fieldRetryAttempts,
    retryIntervalUnit: fieldRetryIntervalUnit,
    retryInterval: fieldRetryInterval,
  } = fieldConfiguration?.remediation?.assignments;

  /**
   * validateField
   *
   * @TODO - Needs description.
   *
   * @param index
   * @param field
   */
  const validateField = (index, field) => {
    form.validate(
      `remediation[assignments][${index}][${field}]`,
      () => {},
      () => {}
    );
  };

  /**
   * validateRetryInterval
   *
   * Validates the retryIntervalUnit and retryInterval fields.
   *
   * @param assignment
   * @param index
   */
  const validateRetryInterval = (assignment, index) => {
    if (assignment?.retryAttempts !== '0') {
      if (
        !isEmpty(assignment?.retryAttempts) &&
        isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.retryAttempts)
      ) {
        validateField(index, 'retryIntervalUnit');
        if (
          !isEmpty(assignment?.retryIntervalUnit) &&
          isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.retryIntervalUnit)
        ) {
          validateField(index, 'retryInterval');
        }
      }
    }
  };

  /**
   * Validate all fields when values change.
   */
  useEffect(() => {
    if (!isNil(assignments) && !isEmpty(assignments)) {
      assignments?.forEach((assignment, index) => {
        validateField(index, 'impactedSource');
        if (
          !isEmpty(assignment?.impactedSource) &&
          isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.impactedSource)
        ) {
          validateField(index, 'actionId');
          if (
            !isEmpty(assignment?.actionId) &&
            isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.actionId)
          ) {
            validateField(index, 'retryAttempts');
          }
          validateRetryInterval(assignment, index);
        }
      });
    }
  }, [assignments, form?.formState?.errors]);

  /**
   * untrustedSourceOptions
   *
   * used for untrusted source selectable options dropdown when adding actions
   */
  const untrustedSourceOptions = useMemo(() => {
    const options = [];

    if (!isEmpty(sources)) {
      sources.forEach((source) => {
        options.push({
          value: source?.codeId,
          label: source?.codeDesc,
        });
      });
    }

    orderBy(options, ['label'], ['asc']);

    // @TODO: temporary - remove when ready (Feature: ExitCriteria)
    if (['Local', 'Development'].includes(currentEnvironment)) {
      options.unshift({
        value: 'ENTITY',
        label: 'Entity',
      });
    }
    // @TODO: temporary - remove when ready (Feature: ExitCriteria)

    return options;
  }, [sources]);

  /**
   * renderCellUntrustedSource
   *
   * @TODO Needs description.
   */
  const renderCellUntrustedSource = (args) => {
    const { row } = args;
    const { index } = row;

    return (
      <SelectInput
        {...fieldUntrustedSource}
        model={`remediation[assignments][${index}][impactedSource]`}
        options={untrustedSourceOptions}
        onChange={(selectedSource) => {
          const selectedAction = form?.getValues(`remediation[assignments][${index}][actionId]`);

          if (!isNil(selectedAction) && !isEmpty(selectedAction)) {
            const theAction = actions?.content?.find((action) => {
              return action?.id === selectedAction && action?.allowableSystems?.includes(selectedSource);
            });

            if (isUndefined(theAction)) {
              form?.setValue(`remediation[assignments][${index}][actionId]`, '');
            }
          }

          validateField(index, 'impactedSource');
          validateField(index, 'actionId');
        }}
        validators={{
          ...fieldUntrustedSource?.validators,
          ...{
            validate: {
              customValidator: (value) => {
                return fieldValidator(fieldUntrustedSource, value);
              },
            },
          },
        }}
      />
    );
  };

  /**
   * renderCellAction
   *
   * @TODO Needs description.
   */
  const renderCellAction = (args) => {
    const { row } = args;
    const { index } = row;

    return (
      <SelectInput
        {...fieldAction}
        model={`remediation[assignments][${index}][actionId]`}
        options={
          actions?.content
            ?.filter((action) => {
              const selectedSource = form?.getValues(`remediation[assignments][${index}][impactedSource]`);

              if (isEmpty(action?.allowableSystems)) {
                return true;
              }

              return action?.allowableSystems?.includes(selectedSource);
            })
            .map((action) => {
              return {
                value: action?.id,
                label: action?.name,
              };
            }) || []
        }
        onChange={() => {
          validateField(index, 'impactedSource');
          validateField(index, 'actionId');
          validateField(index, 'retryAttempts');
        }}
        isDisabled={
          !isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.impactedSource) ||
          isEmpty(row?.original?.impactedSource)
        }
        validators={{
          ...fieldAction?.validators,
          ...{
            validate: {
              customValidator: (value) => {
                return fieldValidator(fieldAction, value);
              },
            },
          },
        }}
      />
    );
  };

  /**
   * renderCellRetryAttempts
   *
   * @TODO Needs description.
   */
  const renderCellRetryAttempts = (args) => {
    const { row } = args;
    const { index } = row;

    return (
      <NumberInput
        {...fieldRetryAttempts}
        model={`remediation[assignments][${index}][retryAttempts]`}
        onChange={() => {
          if (assignments?.[index]?.retryAttempts === '0') {
            form?.resetField(`remediation[assignments][${index}][retryIntervalUnit]`);
            form?.resetField(`remediation[assignments][${index}][retryInterval]`);
          }

          validateField(index, 'impactedSource');
          validateField(index, 'actionId');
          validateField(index, 'retryAttempts');

          if (assignments?.[index]?.retryAttempts !== '0') {
            validateField(index, 'retryIntervalUnit');
          }
        }}
        isDisabled={
          !isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.impactedSource) ||
          isEmpty(row?.original?.impactedSource) ||
          !isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.actionId) ||
          isEmpty(row?.original?.actionId)
        }
        validators={{
          ...fieldRetryAttempts?.validators,
          ...{
            validate: {
              customValidator: (value) => {
                return fieldValidator(fieldRetryAttempts, value);
              },
            },
          },
        }}
      />
    );
  };

  /**
   * renderCellRetryInterval
   *
   * @TODO Needs description.
   */
  const renderCellRetryInterval = (args) => {
    const { row } = args;
    const { index } = row;

    return (
      <TextInput
        {...fieldRetryInterval}
        model={`remediation[assignments][${index}][retryInterval]`}
        id={`remediation-assignments-${index}-retryInterval`}
        className="remediation-assignments-retryInterval"
        onChange={() => {
          validateField(index, 'impactedSource');
          validateField(index, 'actionId');
          validateField(index, 'retryAttempts');

          if (row?.original?.retryAttempts !== '0') {
            validateField(index, 'retryIntervalUnit');
            validateField(index, 'retryInterval');
          }
        }}
        isDisabled={
          !isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.impactedSource) ||
          isEmpty(row?.original?.impactedSource) ||
          !isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.actionId) ||
          isEmpty(row?.original?.actionId) ||
          !isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.retryAttempts) ||
          isEmpty(row?.original?.retryAttempts) ||
          !isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.retryIntervalUnit) ||
          isEmpty(row?.original?.retryIntervalUnit) ||
          row?.original?.retryAttempts === '0'
        }
        validators={{
          ...fieldRetryInterval.validators,
          ...{
            validate: {
              customValidator: (value) => {
                return fieldValidator(fieldRetryInterval, value);
              },
            },
          },
        }}
        leftAddOn={
          <SelectInput
            {...fieldRetryIntervalUnit}
            model={`remediation[assignments][${index}][retryIntervalUnit]`}
            id={`remediation-assignments-${index}-retryIntervalUnit`}
            className="remediation-assignments-retryIntervalUnit"
            options={chronoUnits
              .map((chronoUnit) => {
                return {
                  value: chronoUnit?.codeId,
                  label: chronoUnit?.codeDesc,
                };
              })
              .filter((chronoUnit) => {
                return chronoUnit?.value !== 'SECONDS';
              })}
            onChange={() => {
              validateField(index, 'impactedSource');
              validateField(index, 'actionId');
              validateField(index, 'retryAttempts');

              if (assignments?.[index]?.retryAttempts !== '0') {
                validateField(index, 'retryIntervalUnit');
                validateField(index, 'retryInterval');
              }
            }}
            isDisabled={
              !isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.impactedSource) ||
              isEmpty(row?.original?.impactedSource) ||
              !isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.actionId) ||
              isEmpty(row?.original?.actionId) ||
              !isUndefined(form?.formState?.errors?.remediation?.assignments?.[index]?.retryAttempts) ||
              isEmpty(row?.original?.retryAttempts) ||
              row?.original?.retryAttempts === '0'
            }
            validators={{
              ...fieldRetryIntervalUnit?.validators,
              ...{
                validate: {
                  customValidator: (value) => {
                    return fieldValidator(fieldRetryIntervalUnit, value);
                  },
                },
              },
            }}
          />
        }
      />
    );
  };

  /**
   * renderCellRetryActions
   *
   * @TODO Needs description.
   */
  const renderCellActions = (args) => {
    const { rows, row } = args;
    const { index } = row;

    return (
      <React.Fragment>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          variant="custom"
          id="removeField"
          onClick={() => {
            if (rows.length === 1) {
              handleReset();
            } else {
              remove(index);
            }
          }}
        >
          <Flex alignItems="center">
            <div>
              <IconSymbol icon="cancel" variant="filled" />
              <IconSymbol icon="cancel" variant="outlined" />
            </div>
            <div>Remove</div>
          </Flex>
        </Link>
      </React.Fragment>
    );
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'Untrusted Source') {
        column.Cell = renderCellUntrustedSource;
      }

      if (column.Header === 'Action') {
        column.Cell = renderCellAction;
      }

      if (column.Header === 'Retry Attempts') {
        column.Cell = renderCellRetryAttempts;
      }

      if (column.Header === 'Retry Interval') {
        column.Cell = renderCellRetryInterval;
      }

      if (column.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      return column;
    });
  }, []);

  /**
   * reset
   *
   * This function is intended to be passed to the common table component. It will reset the form state fields
   *
   * @param values
   */
  const reset = useCallback(
    (values) => {
      let theValues = values;

      if (isEmpty(values)) {
        theValues = assignments;
      }

      form?.resetField('remediation.assignments', { defaultValue: theValues });
      theValues.forEach((formFields, index) => {
        Object.keys(formFields).forEach((column) => {
          form.setValue(`remediation[assignments][${index}][${column}]`, formFields[column]);
        });
      });
    },
    [assignments]
  );

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/RemediateRecords/components/Table/Table.jsx">
      {!isEmpty(fields) && !isEmpty(assignments) ? (
        <Styles>
          <TableComponent
            {...{
              columns,
              rows: assignments,
              dataKey,
              reset,
              configuration: merge({}, configuration, {
                isLoading,
                data: assignments,
                reorderRows: true,
                enableGroupBy: false,
              }),
            }}
          />
        </Styles>
      ) : (
        <React.Fragment />
      )}
    </ErrorHandler>
  );
};

Table.propTypes = {
  actions: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        allowableSystems: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
  chronoUnits: PropTypes.arrayOf(
    PropTypes.shape({
      codeId: PropTypes.string,
      codeDesc: PropTypes.string,
    })
  ),
  dataKey: PropTypes.string,
  fields: PropTypes.shape({
    actionId: PropTypes.string,
    impactedSource: PropTypes.string,
    retryAttempts: PropTypes.string,
    retryInterval: PropTypes.string,
    retryIntervalUnit: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
    resetField: PropTypes.func,
    setValue: PropTypes.func,
    formState: PropTypes.shape({
      errors: PropTypes.shape({
        remediation: PropTypes.shape({
          assignments: PropTypes.arrayOf(
            PropTypes.shape({
              actionId: PropTypes.string,
              impactedSource: PropTypes.string,
              retryAttempts: PropTypes.string,
              retryInterval: PropTypes.string,
              retryIntervalUnit: PropTypes.string,
            })
          ),
        }),
      }),
    }),
    validate: PropTypes.func,
  }),
  handleReset: PropTypes.func,
  isLoading: PropTypes.bool,
  remove: PropTypes.func,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      codeId: PropTypes.string,
      codeDesc: PropTypes.string,
    })
  ),
};

Table.defaultProps = {
  actions: {},
  chronoUnits: [],
  dataKey: '',
  fields: {},
  form: {},
  handleReset: () => {},
  isLoading: false,
  remove: () => {},
  sources: [],
};

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@src/components/Button';
import { DateRange } from '@src/components/DateRange';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { fieldValidator } from '@src/includes/validation';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty } from 'lodash';
import { isEqual } from 'lodash/lang';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Styles } from './includes/styles';
import { getCategoryOptions } from './includes/functions';
import fields from './includes/fields.json';

const { frequency, tags, tagCategories, tagVariantThreshold, tagVariantThresholdType } = fields;

/**
 * Filters
 *
 * Provides form fields to control the data returned
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Filters = (props) => {
  const { form, assets, previouslySubmittedValues } = props;

  const [radioValue, setRadioValue] = useState('tags');

  const frequencyValue = form?.getValues('frequency');

  /**
   * tags.options
   *
   * This is a memoized value that is used to populate the tags options for the select input.
   *
   * @type {*[]}
   */
  tags.options = useMemo(() => {
    const tagOptions = [];

    if (!isEmpty(assets?.ListTags?.data?.tagsList)) {
      Object.keys(assets?.ListTags?.data?.tagsList).forEach((categoryCode) => {
        const tagOption = {
          section: '',
          items: [],
        };
        assets?.ListTags?.data?.tagsList[categoryCode].forEach((tag) => {
          if (isEmpty(tagOption.section)) {
            tagOption.section = tag?.categoryDesc;
          }
          const tagItem = {
            label: `${tag?.code} (${tag?.categoryCode})`,
            value: tag?.tag,
          };
          if (!tagOption.items.includes(tagItem)) {
            tagOption.items.push(tagItem);
          }
        });

        if (!tagOptions.includes(tagOption)) {
          tagOptions.push(tagOption);
        }
      });
    }

    return tagOptions;
  }, [assets?.ListTags?.data?.tagsList]);

  /**
   * tagCategories.options
   *
   * This is a memoized value that is used to populate the tagCategories options for the select input.
   *
   * @type {*[]}
   */
  tagCategories.options = useMemo(() => {
    return getCategoryOptions(assets?.ListTags?.data?.tagsList);
  }, [assets?.ListTags?.data?.tagsList]);

  /**
   *
   */
  const minimumDate = useMemo(() => {
    let date;

    if (['WEEKS', 'MONTHS'].includes(frequencyValue)) {
      date = dayjs(new Date()).subtract(2, 'year').format('MM/DD/YYYY');
    } else {
      date = dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY');
    }

    return date;
  }, [frequencyValue]);

  /**
   * Date range restrictions.
   */
  useEffect(() => {
    const dateFrom = form?.getValues('dateRange.start');

    if (['WEEKS', 'MONTHS'].includes(frequencyValue)) {
      // Only allow maximum of 2 years in the past
      if (
        !dayjs(dateFrom).isBetween(
          dayjs(new Date()).subtract(2, 'years').format('MM/DD/YYYY'),
          dayjs(new Date()).format('MM/DD/YYYY'),
          'day'
        )
      ) {
        form?.setValue('dateRange.start', dayjs(new Date()).subtract(2, 'years').format('MM/DD/YYYY'));
      }
    }

    if (frequencyValue === 'DAYS') {
      // Only allow maximum of 6 months in the past
      if (
        !dayjs(dateFrom).isBetween(
          dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY'),
          dayjs(new Date()).format('MM/DD/YYYY'),
          'day'
        )
      ) {
        form?.setValue('dateRange.start', dayjs(new Date()).subtract(6, 'months').format('MM/DD/YYYY'));
      }
    }
  }, [frequencyValue]);

  /**
   * Validates the fields
   */
  useEffect(() => {
    if (radioValue === 'tags') {
      form?.validate(
        `tags`,
        () => {},
        () => {}
      );
    }

    if (radioValue === 'tagCategories') {
      form?.validate(
        `tagCategories`,
        () => {},
        () => {}
      );
    }

    form?.validate(
      `tagVariantThreshold`,
      () => {},
      () => {}
    );

    form?.validate(
      `frequency`,
      () => {},
      () => {}
    );

    form?.validate(
      `dateRange.start`,
      () => {},
      () => {}
    );

    form?.validate(
      `dateRange.end`,
      () => {},
      () => {}
    );
  }, [radioValue]);

  const formValues = form?.getValues();

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/TagHistory/components/Filters/Filters.jsx">
      <Styles>
        <Grid css={{ position: 'relative' }}>
          <Grid.Col span={{ xs: '50%', sm: '50%', md: '50%', lg: '25%' }}>
            <div id="tags">
              <RadioGroup
                display="row"
                label=""
                onChange={(event) => {
                  const value = event?.target?.value;

                  if (value === 'tags') {
                    form?.setValue('tagCategories', []);
                  }

                  if (value === 'tagCategories') {
                    form?.setValue('tags', []);
                  }

                  return setRadioValue(value);
                }}
                value={radioValue}
                size="sm"
              >
                <RadioGroup.Radio label={tags?.label} value={tags?.model} />
                <RadioGroup.Radio label={tagCategories?.label} value={tagCategories?.model} />
              </RadioGroup>

              {radioValue === 'tags' && (
                <SelectInputMulti
                  {...tags}
                  label=""
                  onChange={() => {
                    form?.validate(
                      `tags`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              )}
              {radioValue === 'tagCategories' && (
                <SelectInputMulti
                  {...tagCategories}
                  label=""
                  onChange={() => {
                    form?.validate(
                      `tagCategories`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              )}
            </div>
          </Grid.Col>
          <Grid.Col span={{ xs: '50%', sm: '50%', md: '50%', lg: '25%' }}>
            <TextInput
              {...tagVariantThreshold}
              onChange={() => {
                form?.validate(
                  `tagVariantThreshold`,
                  () => {},
                  () => {}
                );
              }}
              validators={{
                ...tagVariantThreshold?.validators,
                ...{
                  validate: {
                    customValidator: (value) => {
                      return fieldValidator(tagVariantThreshold, value);
                    },
                  },
                },
              }}
              leftAddOn={
                <SelectInput
                  {...tagVariantThresholdType}
                  options={[
                    {
                      value: 'fixed',
                      label: 'Fixed',
                    },
                    {
                      value: 'percentage',
                      label: 'Percentage',
                    },
                  ]}
                />
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: '50%', sm: '50%', md: '50%', lg: '25%' }}>
            <SelectInput
              {...frequency}
              options={assets?.ListChronoUnits?.data
                ?.map((chronoUnit) => {
                  return {
                    value: chronoUnit?.codeId,
                    label: chronoUnit?.codeDesc,
                  };
                })
                .filter((chronoUnit) => {
                  return ['DAYS', 'WEEKS', 'MONTHS'].includes(chronoUnit?.value);
                })}
              onChange={() => {
                form?.validate(
                  `frequency`,
                  () => {},
                  () => {}
                );
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ xs: '50%', sm: '50%', md: '50%', lg: '25%' }}>
            <Grid css={{ paddingLeft: 0 }}>
              <Grid.Col span={{ xs: '75%' }} css={{ paddingRight: 0, paddingLeft: 0 }}>
                <DateRange
                  defaultStartDate={form?.getValues('dateRange.start')}
                  defaultEndDate={form?.getValues('dateRange.end')}
                  minimumDate={minimumDate}
                  maximumDate={dayjs(new Date()).format('MM/DD/YYYY')}
                  onChange={(event = {}) => {
                    form?.setValue('dateRange.start', String(event?.startDate), {
                      shouldValidate: true,
                      shouldDirty: true,
                    });

                    form?.setValue('dateRange.end', String(event?.endDate), {
                      shouldValidate: true,
                      shouldDirty: true,
                    });

                    form?.validate(
                      `dateRange.start`,
                      () => {},
                      () => {}
                    );

                    form?.validate(
                      `dateRange.end`,
                      () => {},
                      () => {}
                    );
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: '25%' }}>
                <Flex
                  justify="flex-start"
                  alignItems="flex-end"
                  alignContent="flex-end"
                  direction="row"
                  css={{ height: '100%' }}
                >
                  <Button
                    type="submit"
                    variant="solid"
                    isDisabled={isEqual(formValues, previouslySubmittedValues) || !isEmpty(form?.formState?.errors)}
                  >
                    Apply
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

Filters.propTypes = {
  assets: PropTypes.shape({
    ListChronoUnits: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeDesc: PropTypes.string,
          codeId: PropTypes.string,
        })
      ),
    }),
    ListTags: PropTypes.shape({
      data: PropTypes.shape({
        tagsList: PropTypes.shape({
          categoryCode: PropTypes.arrayOf(
            PropTypes.shape({
              categoryDesc: PropTypes.string,
              code: PropTypes.string,
              tag: PropTypes.string,
            })
          ),
        }),
      }),
    }),
  }),
  form: PropTypes.shape({
    formState: PropTypes.shape({
      errors: PropTypes.shape({
        dateRange: PropTypes.shape({
          end: PropTypes.string,
          start: PropTypes.string,
        }),
        frequency: PropTypes.string,
        tagCategories: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.arrayOf(PropTypes.string),
        tagVariantThreshold: PropTypes.string,
        tagVariantThresholdType: PropTypes.string,
      }),
    }),
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    validate: PropTypes,
  }),
  previouslySubmittedValues: PropTypes.shape({
    dateRange: PropTypes.shape({
      end: PropTypes.string,
      start: PropTypes.string,
    }),
    frequency: PropTypes.string,
    tagCategories: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
    tagVariantThreshold: PropTypes.string,
    tagVariantThresholdType: PropTypes.string,
  }),
};

Filters.defaultProps = {
  assets: {
    ListChronoUnits: {
      data: [],
    },
    ListTags: {
      data: {
        tagsList: {},
      },
    },
  },
  form: {
    formState: {
      errors: {},
    },
    getValues: () => {},
    setValue: () => {},
    validate: () => {},
  },
  previouslySubmittedValues: {
    dateRange: {
      end: '',
      start: '',
    },
    frequency: '',
    tagCategories: [],
    tags: [],
    tagVariantThreshold: '',
    tagVariantThresholdType: '',
  },
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '*[class*="isInvalid-true"]': {
    bottom: '-13px',
  },
  '#tags': {
    marginTop: '-5px',
    '.abyss-radio-label': {
      fontWeight: 'bold',
    },
    '.abyss-select-input-multi-root': {
      marginTop: 0,
    },
  },
  '.tagVariantThreshold.abyss-text-input-left-add-on': {
    padding: 0,
  },
  '.tagVariantThresholdType': {
    border: 'none',
    '&.abyss-select-input-root': {
      width: '105px',
    },
    "*[class*='isInvalid-true']": {
      position: 'absolute',
      top: 'calc(100% + 1px)',
      right: 'unset',
      bottom: 'unset',
      marginRight: 'var(--abyss-space-lg)',
      padding: '4px',
      zIndex: '999',
      backgroundColor: 'var(--abyss-colors-error2)',
      border: '1px solid var(--abyss-colors-error1)',
    },
  },
});

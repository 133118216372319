import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { isEmpty } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';

/**
 * Tooltip: Sources
 *
 * @TODO - Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Sources = (props) => {
  const { sources } = props;

  return (
    <Layout.Stack space={themeConfiguration?.theme?.space?.xs} alignLayout="left" alignItems="left" grow>
      {!isEmpty(sources) &&
        sources.map((source) => {
          return (
            <div
              style={{
                padding: 'var(--abyss-space-sm)',
              }}
            >
              {source}
            </div>
          );
        })}
    </Layout.Stack>
  );
};

Sources.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.string),
};

Sources.defaultProps = {
  sources: [],
};

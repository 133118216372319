import { config } from '@abyss/web/tools/config';

/**
 * useCurrentEnvironment
 *
 * Gets the current environment of the application.
 *
 * @returns {*}
 */
export const useCurrentEnvironment = () => {
  const environments = {
    prod: 'Production',
    stage: 'Stage',
    dev: 'Development',
    local: 'Local',
    'local:dev': 'Local',
    'local:build': 'Local',
  };

  return environments[config('APP_ENV')];
};

import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  marginTop: 'var(--abyss-space-lg)',
  marginBottom: 'var(--abyss-space-xl)',
  '.abyss-card-root': {
    backgroundColor: 'white',
  },
  '.abyss-grid-col .abyss-grid-col': {
    borderRight: '1px solid var(--abyss-colors-gray4)',
    paddingLeft: 'var(--abyss-space-xl)',
    paddingRight: 'var(--abyss-space-xl)',
  },
  '.abyss-grid-col:last-child': {
    borderRight: 'none',
  },
});

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isUndefined } from 'lodash';

/**
 * SaveEvent
 *
 * Saves a single event with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveEvent = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/events',
      },
      data: {},
    };

    const thePayload = {
      ...payload?.[1],
    };

    if (!isUndefined(payload?.[0])) {
      requestArgs.params = { ...{ mutationKey: payload?.[0] } };
    }

    if (!isUndefined(thePayload?.eventId) && !isEmpty(thePayload?.eventId)) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.eventId}`;
    }

    if (isUndefined(thePayload?.eventTrackerStatus)) {
      thePayload.eventTrackerStatus = 'INACTIVE';
    }

    requestArgs.data = { ...thePayload };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveEvent.js -> SaveEvent() -> error:', theError);

    throw error;
  }
};

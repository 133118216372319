import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '#app-header': {
    backgroundColor: '#ffffff',
    zIndex: '9999',
    '.abyss-page-header-container.sticky': {
      height: '52px',
      '.abyss-page-header-vertical-rule': {
        height: '30px',
      },
    },
    '.abyss-nav-menu-list li:last-child': {
      borderLeft: '1px solid var(--abyss-colors-gray3)',
    },
  },
  '.remhub-header': {
    '.abyss-page-header-logo': {
      flex: 'initial',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '.abyss-page-header-vertical-rule': {
      height: '40px',
    },
    '.abyss-nav-menu-scroll-button-left, .abyss-nav-menu-scroll-button-right': {
      display: 'none',
    },
    '.abyss-nav-menu-menu button:hover, .abyss-nav-menu-menu button[data-state="open"], .abyss-nav-menu-link-root a:hover':
      {
        backgroundColor: 'var(--abyss-colors-background)',
        borderRadius: '4px',
        '.abyss-icon': {
          fill: 'var(--abyss-colors-foreground)',
        },
      },
    '.nav-icon-wrap': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '+ .abyss-nav-menu-menu-trigger-icon-container svg': {
        width: '18px',
        height: '18px',
      },
      '.abyss-text-root': {
        fontSize: 'var(--abyss-fontSizes-sm)',
        fontWeight: 'var(--abyss-fontWeights-medium)',
        color: 'var(--abyss-colors-foreground)',
      },
      '.abyss-icon': {
        fill: 'var(--abyss-colors-foreground)',
      },
    },
    '.abyss-nav-menu-menu.active, .abyss-nav-menu-menu.active button:hover, .abyss-nav-menu-menu.active button[data-state="open"], .abyss-nav-menu-link-root.active, .abyss-nav-menu-link-root.active a:hover':
      {
        '.abyss-icon': {
          fill: 'var(--abyss-colors-interactive1)',
        },
        '.abyss-text-root': {
          color: 'var(--abyss-colors-interactive1)',
        },
      },
  },
});

export const SubNavStyles = styled('div', {
  '.abyss-nav-menu-columns-root': {
    padding: 'var(--abyss-space-md)',
  },
  '.abyss-nav-menu-link-root': {
    display: 'block',
  },
  '.abyss-nav-menu-item-description': {
    display: 'none',
  },
  '.abyss-nav-menu-link': {
    minHeight: 'initial',
    padding: 'var(--abyss-space-sm) var(--abyss-space-sm)',
  },
  '.abyss-nav-menu-item-title': {
    marginBottom: '0',
    lineHeight: '1',
    fontSize: 'var(--abyss-fontSizes-sm)',
    color: 'var(--abyss-colors-foreground)',
  },
  '.active .abyss-nav-menu-item-title': {
    color: 'var(--abyss-colors-interactive1)',
  },
});

export const LogoutStyles = styled('div', {
  '.abyss-nav-menu-columns-root': {
    padding: 'var(--abyss-space-md)',
  },
  '.abyss-nav-menu-link-root': {
    display: 'block',
  },
  '.abyss-nav-menu-item-description': {
    display: 'none',
  },
  '.abyss-nav-menu-link': {
    minHeight: 'initial',
    padding: 'var(--abyss-space-sm) var(--abyss-space-sm)',
  },
  '.abyss-nav-menu-item-title': {
    marginBottom: '0',
    lineHeight: '1',
    fontSize: 'var(--abyss-fontSizes-sm)',
  },
});

import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { RecordActivity } from './components/RecordActivity';

/**
 * ExpansionRow
 *
 * Expands a table row on click.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ExpansionRow = (props) => {
  const { row } = props;

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/TrustedValueHistory/components/Table/components/ExpansionRow/ExpansionRow.jsx">
      <Grid css={{ margin: 0 }}>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <RecordActivity row={row} addedRecords={row?.addedRecords || []} removedRecords={row?.removedRecords || []} />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

ExpansionRow.propTypes = {
  row: PropTypes.shape({
    addedRecords: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    removedRecords: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }),
};

ExpansionRow.defaultProps = {
  row: {
    addedRecords: [],
    removedRecords: [],
  },
};

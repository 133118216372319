import React, { useEffect, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Loader } from '@src/components/Loader';
import { motion } from 'framer-motion';
import { SearchResults } from './components/SearchResults';
import { SearchForm } from './components/forms/Search';
import { useAssets } from './hooks/useAssets';
import { useSearch } from './hooks/useSearch';

/**
 * RiskAnalysis
 *
 * Screen designed to identify, assess, and mitigate potential risks, aiming to minimize negative impacts and
 * maximize opportunities. Leads to the action path wizard.
 *
 * @returns {Element}
 * @constructor
 */
export const RiskAnalysis = () => {
  const [searchFilters, setSearchFilters] = useState({});
  const [isReset, setIsReset] = useState(true);

  const { data: assets, isLoading: isLoadingAssets } = useAssets();
  const { count, results, isLoading: isLoadingSearchResults, fetch: fetchSearchResults } = useSearch();

  useEffect(() => {
    setIsReset(!!isLoadingSearchResults);
  }, [isLoadingSearchResults]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/RiskAnalysis/RiskAnalysis.jsx">
      <motion.div
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <Grid>
          <Grid.Col span={{ xs: '100%' }}>
            <Heading offset={0}>Risk Analysis</Heading>
          </Grid.Col>
          {isLoadingAssets ? (
            <Grid.Col span={{ xs: '100%' }}>
              <Loader verticalAlignment="top" />
            </Grid.Col>
          ) : (
            [
              <Grid.Col span={{ xs: '100%' }}>
                <SearchForm
                  assets={assets}
                  handleSearch={fetchSearchResults}
                  isLoadingSearchResults={isLoadingSearchResults}
                  searchFilters={searchFilters}
                  setSearchFilters={setSearchFilters}
                  setIsReset={setIsReset}
                />
              </Grid.Col>,
              <Grid.Col span={{ xs: '100%' }}>
                {isLoadingSearchResults ? (
                  <Loader verticalAlignment="top" />
                ) : (
                  <React.Fragment>
                    {isReset === false && (
                      <SearchResults assets={assets} results={results} count={count} searchFilters={searchFilters} />
                    )}
                  </React.Fragment>
                )}
              </Grid.Col>,
            ]
          )}
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { abbrNum } from '@src/includes/functions';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { Styles } from './includes/styles';

/**
 * RedEntities
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RedEntities = (props) => {
  const { data } = props;

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/RiskDashboard/components/RedEntities/RedEntities.jsx">
      <Styles>
        <fieldset>
          <legend>
            <Heading offset={5}>Red Entities</Heading>
          </legend>
          <Flex justify="space-evenly" alignContent="center" direction="row">
            <Tooltip content={`Total: ${Number(data?.countTotal).toLocaleString('en-US')}`}>
              <div className="item">
                <small>Total</small>
                <Heading offset={3}>{abbrNum(data?.countTotal)}</Heading>
              </div>
            </Tooltip>
            <Tooltip content={`Total: ${Number(data?.countNoActiveActionPath).toLocaleString('en-US')}`}>
              <div className="item">
                <small>No Action Path</small>
                <Heading offset={3}>{abbrNum(data?.countNoActiveActionPath)}</Heading>
              </div>
            </Tooltip>
            <Tooltip content={`Total: ${Number(data?.countActionPathActive).toLocaleString('en-US')}`}>
              <div className="item">
                <small>In Action Path</small>
                <Heading offset={3}>{abbrNum(data?.countActionPathActive)}</Heading>
              </div>
            </Tooltip>
          </Flex>
        </fieldset>
      </Styles>
    </ErrorHandler>
  );
};

RedEntities.propTypes = {
  data: PropTypes.shape({
    countTotal: PropTypes.number,
    countNoActiveActionPath: PropTypes.number,
    countActionPathActive: PropTypes.number,
  }),
};

RedEntities.defaultProps = {
  data: {},
};

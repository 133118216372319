import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { every, isEmpty, isUndefined, omit } from 'lodash';
import { dayjs } from '@abyss/web/tools/dayjs';

/**
 * filterCriteria
 *
 * if all filters are empty, remove them
 *
 * @param filters
 * @returns {*[]}
 */
const filterCriteria = (filters = []) => {
  let theCriteria = [];

  if (!isUndefined(filters) && !isEmpty(filters)) {
    const removeFilters = every(Object.values(filters?.[0]), isEmpty);

    if (removeFilters === false) {
      theCriteria = filters.map((filter) => {
        const theFilter = { ...filter };

        if (['LAST_MODIFIED_DATE', 'CREATED_DATE'].includes(filter?.column)) {
          theFilter.value = dayjs(filter?.value).format('YYYY-MM-DD');
        }

        if (filter?.column?.toLowerCase().includes('count')) {
          theFilter.value = Number(filter?.value);
        }

        return theFilter;
      });
    }
  }

  return theCriteria;
};

/**
 * SaveCommonCriteria
 *
 * Saves a set of common criteria filter with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveCommonCriteria = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/criteria',
      },
      params: { mutationKey: theMutationKey },
      data: {},
    };

    const criteria = filterCriteria(thePayload?.filters);

    if (thePayload?.action === 'saveName') {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}`;
      requestArgs.data = omit(thePayload, ['filters', 'action']);
    }

    if (thePayload?.action === 'createVersion') {
      requestArgs.method = 'POST';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}/version`;
      requestArgs.data = { criteria };
    }

    if (['activate', 'deactivate', 'update'].includes(thePayload?.action)) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}`;
      requestArgs.data = omit(thePayload, ['action', 'filters', 'version']);
    }

    if (thePayload?.action === 'update') {
      requestArgs.data.activeCommonCriteriaVersion = { criteria };
      requestArgs.data.activeVersionNbr = thePayload?.version;
    }

    if (thePayload?.action === 'create') {
      requestArgs.data = omit(thePayload, ['id', 'filters', 'action']);
      requestArgs.data.criteria = criteria;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveCommonCriteria.js -> SaveCommonCriteria() -> error:', theError);

    throw error;
  }
};

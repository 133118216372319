import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-card-root': {
    overflowY: 'auto',
    overflowX: 'visible',
    overscrollBehaviorY: 'contain',
    overscrollBehaviorX: 'none',
    '.abyss-card-section': {
      overflowY: 'auto',
      overflowX: 'visible',
      overscrollBehaviorY: 'contain',
      overscrollBehaviorX: 'none',
      position: 'relative',
      padding: 0,
      '.pagination': {
        visibility: 'hidden',
        display: 'none',
        position: 'absolute',
        top: 'calc(50% - 51px)',
        right: 'calc(var(--abyss-space-xs) * -1)',
        '.abyss-pagination-root': {
          flexDirection: 'column',
          border: 'none',
          height: 'auto',
        },
        button: {
          margin: 'var(--abyss-space-xs) 0px',
          marginRight: 'calc(var(--abyss-space-xs) + 1.5px)',
          padding: 'var(--abyss-space-xs)',
          backgroundColor: '#ffffff',
          border: '1px solid #0066f5',
          color: '#0066f5',
          fill: '#0066f5',
          height: 'auto',
          borderRadius: '0px',
          transition: 'background-color .33s ease-in-out,border-color .33s ease-in-out',
          '&:hover': {
            backgroundColor: '#0066f5',
            transition: 'background-color .33s ease-in-out,border-color .33s ease-in-out',
            color: '#ffffff',
            fill: '#ffffff',
            '.abyss-pagination-icon-left, .abyss-pagination-icon-right': {
              color: '#ffffff',
            },
          },
          '.abyss-pagination-icon-left, .abyss-pagination-icon-right': {
            color: '#0066f5',
            fill: '#0066f5',
            transform: 'rotate(90deg)',
            '&:hover': {
              color: '#ffffff',
              fill: '#ffffff',
            },
          },
        },
        '.abyss-pagination-abbreviated-container, .abyss-pagination-divider': {
          display: 'none',
          visibility: 'hidden',
        },
      },
    },
    '&:hover .abyss-card-section .pagination': {
      visibility: 'visible',
      display: 'flex',
    },
  },
});

import PropTypes from 'prop-types';
import React from 'react';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as AbyssTable } from '@abyss/web/ui/Table';

/**
 * Table
 *
 * Data containing date/time information about a risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { data } = props;

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/Dates/components/Table/Table.jsx">
      <div className="static-table">
        <AbyssTable.Container title="">
          <AbyssTable.TableBody>
            <AbyssTable.Row>
              <AbyssTable.Column>Stream Received</AbyssTable.Column>
              <AbyssTable.Cell>{dayjs(data?.streamReceived).format('MM/DD/YYYY, HH:mm:ss')}</AbyssTable.Cell>
            </AbyssTable.Row>

            <AbyssTable.Row>
              <AbyssTable.Column>Last Modified Date</AbyssTable.Column>
              <AbyssTable.Cell>{dayjs(data?.lastModifiedDate).format('MM/DD/YYYY, HH:mm:ss')}</AbyssTable.Cell>
            </AbyssTable.Row>

            <AbyssTable.Row>
              <AbyssTable.Column>Created Date</AbyssTable.Column>
              <AbyssTable.Cell>{dayjs(data?.createdDate).format('MM/DD/YYYY, HH:mm:ss')}</AbyssTable.Cell>
            </AbyssTable.Row>

            <AbyssTable.Row>
              <AbyssTable.Column>Replay Stream Received</AbyssTable.Column>
              <AbyssTable.Cell>{dayjs(data?.replayStreamReceived).format('MM/DD/YYYY, HH:mm:ss')}</AbyssTable.Cell>
            </AbyssTable.Row>
          </AbyssTable.TableBody>
        </AbyssTable.Container>
      </div>
    </ErrorHandler>
  );
};

Table.propTypes = {
  data: PropTypes.shape({
    streamReceived: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    createdDate: PropTypes.string,
    replayStreamReceived: PropTypes.string,
  }),
};

Table.defaultProps = {
  data: {},
};

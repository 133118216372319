import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Card } from '@abyss/web/ui/Card';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';

/**
 * NotFound
 *
 * This component is used to display a not found message.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const NotFound = (props) => {
  const { icon, title, message, button } = props;

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/NotFound/NotFound.jsx">
      <Layout.Group
        alignLayout="center"
        css={{
          padding: `${Number(themeConfiguration?.theme?.space?.lg?.replace('px', '')) * 4}px 0px`,
        }}
      >
        <Card
          css={{
            borderStyle: 'dashed',
            boxShadow: 'none',
          }}
        >
          <Layout.Stack
            css={{
              padding: `${Number(themeConfiguration?.theme?.space?.lg?.replace('px', '')) * 2}px ${
                Number(themeConfiguration?.theme?.space?.xl?.replace('px', '')) * 4
              }px`,
            }}
            space={0}
          >
            <IconSymbol icon={icon} variant="outlined" color="var(--abyss-colors-gray5)" size="48px" />
            <div>
              <strong>{title}</strong>
            </div>
            <div>{message}</div>
            <div style={{ marginTop: 'var(--abyss-space-lg)' }}>{button}</div>
          </Layout.Stack>
        </Card>
      </Layout.Group>
    </ErrorHandler>
  );
};

NotFound.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  button: PropTypes.element,
};

NotFound.defaultProps = {
  icon: '',
  title: '',
  message: '',
  button: null,
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, merge, orderBy } from 'lodash';
import { Link } from '@abyss/web/ui/Link';
import { NumberInput } from '@abyss/web/ui/NumberInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Table as TableComponent } from '@src/components/Table-static';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';
import configuration from './includes/configuration.json';

const Components = {
  NumberInput,
  SelectInput,
};

/**
 * Table
 *
 * Provides the user with a table to specify repeatable fields to search risk records by.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { accessor, disabledFields, fields, remove, repeatableFields } = props;

  /**
   * renderCellField
   *
   * @TODO Needs description.
   *
   * @param args
   * @returns {JSX.Element}
   */
  const renderCell = (args) => {
    const { row } = args;
    const { index } = row;

    const theField = fields?.find((field) => {
      return field?.model === args?.column?.id;
    });

    const TheComponent = Components[theField?.component];

    return (
      <TheComponent
        {...{
          ...theField,
          ...{
            model: `repeatableFields[${accessor}][${index}][${theField?.model}]`,
            isDisabled: disabledFields?.[index]?.includes(theField?.model),
          },
        }}
      />
    );
  };

  /**
   * renderCellActions
   *
   * @TODO Needs description.
   *
   * @param args
   * @returns {JSX.Element}
   */
  const renderCellActions = (args) => {
    const { row } = args;
    const { index } = row;

    if (index === 0) {
      return null;
    }

    return (
      <React.Fragment>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          variant="custom"
          className="removeRow"
          onClick={() => {
            remove(index);
          }}
        >
          <Flex alignItems="center">
            <div>
              <IconSymbol icon="cancel" variant="filled" />
              <IconSymbol icon="cancel" variant="outlined" />
            </div>
            <div>Remove</div>
          </Flex>
        </Link>
      </React.Fragment>
    );
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    const theColumns = orderBy(fields, ['order'], ['asc'])?.map((field) => {
      return {
        Header: field?.label,
        accessor: field?.model,
        Cell: renderCell,
        order: field?.order,
        disableSortBy: true,
      };
    });

    theColumns.push({
      Header: ' ',
      accessor: 'actions',
      Cell: renderCellActions,
      disableSortBy: true,
    });

    return theColumns;
  }, [fields]);

  return (
    <ErrorHandler location="src/components/RepeatableFields/components/Table/Table.jsx">
      <Visibility>
        {!isEmpty(fields) && !isEmpty(repeatableFields) ? (
          <Styles>
            <TableComponent
              {...{
                columns,
                rows: repeatableFields,
                dataKey: accessor,
                configuration: merge({}, configuration, {
                  data: repeatableFields,
                  reorderRows: false,
                }),
              }}
            />
          </Styles>
        ) : (
          <React.Fragment />
        )}
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  accessor: PropTypes.string.isRequired,
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remove: PropTypes.func.isRequired,
  repeatableFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Table.defaultProps = {
  disabledFields: [],
};

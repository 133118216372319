import annotationPlugin from 'chartjs-plugin-annotation';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { abbrNum } from '@src/includes/functions';
import { AbyssTheme as themeConfiguration } from '@src/client';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isArray, isEmpty, isUndefined } from 'lodash';
import { Line } from 'react-chartjs-2';
import flagIconHover from './includes/flagIconHover.svg';
import flagIcon from './includes/flagIcon.svg';

ChartJS.register(annotationPlugin, CategoryScale, Legend, LinearScale, LineElement, PointElement, Title, Tooltip);

const colors = {
  C360: '#2f4f4f',
  CATAMARAN: '#228b22',
  CIP: '#4b0082',
  CSP_FACETS: '#800000',
  FARO: '#ff8c00',
  GPS: '#00ff00',
  HSID: '#00ffff',
  ICUE: '#0000ff',
  IMDM: '#ff00ff',
  IRIS: '#efef00',
  RX_BOOK1: '#a0bff6',
  RX_BOOK2: '#ceab8b',
  TRANSUNION: '#ff0000',
};

/**
 * LineChart
 *
 * Displays the risk trend report data in a line chart.
 *
 * @param props
 * @returns {React.JSX.Element|*|string}
 * @constructor
 */
export const LineChart = (props) => {
  const { data, selectedEvents, eventTypes } = props;

  const chartRef = useRef(null);
  const iconRef = useRef(null);
  const iconHoverRef = useRef(null);

  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [annotations, setAnnotations] = useState({});
  const [events, setEvents] = useState([]);

  /**
   * Configure the chart labels and data sets from the api data.
   */
  useEffect(() => {
    const theEvents = [];

    if (isArray(selectedEvents) && !isEmpty(selectedEvents)) {
      selectedEvents.forEach((selectedEvent) => {
        const event = data?.events?.find((item) => {
          return item?.eventId === selectedEvent;
        });

        const theEventType = eventTypes?.find((eventType) => {
          return eventType?.codeId === event?.eventTypeCode;
        });

        const theEvent = {
          chartEndDate: dayjs(event?.displayEndDate).format('MM/DD/YYYY'),
          chartStartDate: dayjs(event?.displayStartDate).format('MM/DD/YYYY'),
          id: event?.eventId,
          mode: event?.displayStartDate === event?.displayEndDate ? 'single' : 'range',
          name: event?.title,
          tooltipEndDate: dayjs(event?.endDate).format('MM/DD/YYYY'),
          tooltipStartDate: dayjs(event?.startDate).format('MM/DD/YYYY'),
          type: theEventType?.codeDesc,
        };
        theEvents.push(theEvent);
      });
    }

    if (theEvents !== events) {
      setEvents(theEvents);
    }
  }, [selectedEvents]);

  /**
   * Load the flag icons.
   */
  useEffect(() => {
    iconRef.current = new Image(24, 24);
    iconRef.current.src = flagIcon?.src;

    iconHoverRef.current = new Image(24, 24);
    iconHoverRef.current.src = flagIconHover?.src;
  }, []);

  /**
   * getSingleIcon
   *
   * Get the single icon annotation.
   *
   * @param chart
   * @param event
   * @returns {{yScaleID: string, pointStyle: null, xValue: *, xScaleID: string, leave(*): void, yAdjust: number,
   *   enter(*): void, type: string, yValue: *}}
   */
  const getSingleIcon = (chart, event) => {
    return {
      type: 'point',
      xValue: event?.chartStartDate,
      xScaleID: 'x',
      yValue: chart?.scales?.y?.max,
      yScaleID: 'y',
      yAdjust: -16,
      pointStyle: iconRef?.current,
      enter(ctx) {
        ctx.chart.config.options.plugins.annotation.annotations[ctx?.id].hovered = true;
        ctx.chart.config.options.plugins.annotation.annotations[ctx?.id].pointStyle = iconHoverRef?.current;
        ctx.chart.update();
      },
      leave(ctx) {
        ctx.chart.config.options.plugins.annotation.annotations[ctx?.id].hovered = false;
        ctx.chart.config.options.plugins.annotation.annotations[ctx?.id].pointStyle = iconRef?.current;
        ctx.chart.update();
      },
    };
  };

  /**
   * getSingleLine
   *
   * Get the single line annotation.
   *
   * @param chart
   * @param event
   * @returns {{scaleID: string, borderDash: (function(*): *[]|number[]), borderColor: string, borderWidth: number,
   *   label: {textAlign: string, display: (function(*): boolean), yAdjust: number, z: number, position: string,
   *   content: *[]}, type: string, value: *}}
   */
  const getSingleLine = (chart, event) => {
    return {
      type: 'line',
      scaleID: 'x',
      value: event?.chartStartDate,
      borderColor: 'rgba(255, 97, 43, 1.0)',
      borderWidth: 2,
      borderDash: (ctx) => {
        return ctx?.chart?.config?.options?.plugins?.annotation?.annotations?.[`icon-${event?.id}`]?.hovered === true
          ? []
          : [3, 3];
      },
      label: {
        display: (ctx) => {
          return ctx?.chart?.config?.options?.plugins?.annotation?.annotations?.[`icon-${event?.id}`]?.hovered === true;
        },
        content: [event?.name, event?.type, event?.tooltipStartDate],
        yAdjust: 4,
        textAlign: 'left',
        position: 'start',
        z: 100,
      },
    };
  };

  /**
   * getRangeStartLine
   *
   * Get the range start line annotation.
   *
   * @param chart
   * @param event
   * @returns {{scaleID: string, borderDash: (function(*): *[]|number[]), borderColor: string, borderWidth: number,
   *   type: string, value: *}}
   */
  const getRangeStartLine = (chart, event) => {
    return {
      type: 'line',
      scaleID: 'x',
      value: event?.chartStartDate,
      borderColor: 'rgba(255, 97, 43, 1.0)',
      borderWidth: 2,
      borderDash: (ctx) => {
        return ctx?.chart?.config?.options?.plugins?.annotation?.annotations?.[`icon-${event?.id}`]?.hovered === true
          ? []
          : [3, 3];
      },
    };
  };

  /**
   * getRangeEndLine
   *
   * Get the range end line annotation.
   *
   * @param chart
   * @param event
   * @returns {{scaleID: string, borderDash: (function(*): *[]|number[]), borderColor: string, borderWidth: number,
   *   type: string, value: *}}
   */
  const getRangeEndLine = (chart, event) => {
    return {
      type: 'line',
      scaleID: 'x',
      value: event?.chartEndDate,
      borderColor: 'rgba(255, 97, 43, 1.0)',
      borderWidth: 2,
      borderDash: (ctx) => {
        return ctx?.chart?.config?.options?.plugins?.annotation?.annotations?.[`icon-${event?.id}`]?.hovered === true
          ? []
          : [3, 3];
      },
    };
  };

  /**
   * getRangeBox
   *
   * Get the range box annotation.
   *
   * @param chart
   * @param event
   * @returns {{backgroundColor: (function(*): string), borderColor: string, xMax: *, type: string, xMin: *}}
   */
  const getRangeBox = (chart, event) => {
    return {
      type: 'box',
      xMin: event?.chartStartDate,
      xMax: event?.chartEndDate,
      backgroundColor: (ctx) => {
        return ctx?.chart?.config?.options?.plugins?.annotation?.annotations?.[`icon-${event?.id}`]?.hovered === true
          ? 'rgba(255, 97, 43, 0.40)'
          : 'rgba(255, 97, 43, 0.10)';
      },

      borderColor: 'transparent',
    };
  };

  /**
   * getRangeHorizontalLine
   *
   * Get the range horizontal line annotation.
   *
   * @param chart
   * @param event
   * @returns {{yScaleID: string, yMin: *, borderColor: string, yMax: *, borderWidth: number, xScaleID: string,
   *   arrowHeads: {start: {borderColor: string, display: boolean}, end: {borderColor: string, display: boolean}},
   *   label: {textAlign: string, display: (function(*): boolean), yAdjust: number, z: number, content: (*|string)[]},
   *   xMax: *, type: string, xMin: *}}
   */
  const getRangeHorizontalLine = (chart, event) => {
    return {
      type: 'line',
      borderColor: 'rgba(255, 97, 43, 1.0)',
      borderWidth: 2,
      label: {
        display: (ctx) => {
          return ctx?.chart?.config?.options?.plugins?.annotation?.annotations?.[`icon-${event?.id}`]?.hovered === true;
        },
        content: [event?.name, event?.type, `${event?.tooltipStartDate} - ${event?.tooltipEndDate}`],
        yAdjust: 4,
        textAlign: 'left',
        z: 100,
      },
      arrowHeads: {
        start: {
          display: true,
          borderColor: 'rgba(255, 97, 43, 1.0)',
        },
        end: {
          display: true,
          borderColor: 'rgba(255, 97, 43, 1.0)',
        },
      },
      xMin: event?.chartStartDate,
      xMax: event?.chartEndDate,
      xScaleID: 'x',
      yMax: chart?.scales?.y?.max,
      yMin: chart?.scales?.y?.max,
      yScaleID: 'y',
    };
  };

  /**
   * getRangeIcon
   *
   * Get the range icon annotation.
   *
   * @param chart
   * @param event
   * @returns {{yScaleID: string, yMin: *, pointStyle: null, yMax: *, xScaleID: string, leave(*): void, yAdjust:
   *   number, xMax: *, enter(*): void, type: string, xMin: *}}
   */
  const getRangeIcon = (chart, event) => {
    return {
      type: 'point',
      xMin: event?.chartStartDate,
      xMax: event?.chartEndDate,
      xScaleID: 'x',
      yMax: chart?.scales?.y?.max,
      yMin: chart?.scales?.y?.max,
      yScaleID: 'y',
      yAdjust: -16,
      pointStyle: iconRef?.current,
      enter(ctx) {
        ctx.chart.config.options.plugins.annotation.annotations[ctx?.id].hovered = true;
        ctx.chart.config.options.plugins.annotation.annotations[ctx?.id].pointStyle = iconHoverRef?.current;
        ctx.chart.update();
      },
      leave(ctx) {
        ctx.chart.config.options.plugins.annotation.annotations[ctx?.id].hovered = false;
        ctx.chart.config.options.plugins.annotation.annotations[ctx?.id].pointStyle = iconRef?.current;
        ctx.chart.update();
      },
    };
  };

  /**
   * Configure the chart annotations from the api data.
   */
  useEffect(() => {
    const chart = chartRef.current;

    if (!isEmpty(events)) {
      const theAnnotations = {};

      events.forEach((event) => {
        if (event?.mode === 'single') {
          theAnnotations[`icon-${event?.id}`] = getSingleIcon(chart, event);
          theAnnotations[`line-${event?.id}`] = getSingleLine(chart, event);
        }

        if (event?.mode === 'range') {
          theAnnotations[`lineStart-${event?.id}`] = getRangeStartLine(chart, event);
          theAnnotations[`lineEnd-${event?.id}`] = getRangeEndLine(chart, event);
          theAnnotations[`box-${event?.id}`] = getRangeBox(chart, event);
          theAnnotations[`horizontalLine-${event?.id}`] = getRangeHorizontalLine(chart, event);
          theAnnotations[`icon-${event?.id}`] = getRangeIcon(chart, event);
        }
      });

      if (theAnnotations !== annotations) {
        setAnnotations(theAnnotations);
      }
    } else {
      setAnnotations({});
    }
  }, [events]);

  /**
   * Configure the chart labels and data sets from the api data.
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const theLabels = [];
      const theDatasets = [];

      data?.series?.[0]?.dataPoints?.forEach((item) => {
        const theDate = dayjs(item?.date).format('MM/DD/YYYY');
        theLabels.push(theDate);
      });

      if (theLabels !== labels) {
        setLabels(theLabels);
      }

      data?.series?.forEach((item) => {
        const color = colors[item?.sourceCode];
        const dataset = {
          label: item?.sourceCode,
          data: item?.dataPoints.map((theData) => {
            return theData?.value;
          }),
          borderColor: color,
          backgroundColor: color,
          borderJoinStyle: 'bevel',
          borderWidth: 2,
          pointRadius: 3,
        };

        theDatasets.push(dataset);
      });

      if (theDatasets !== datasets) {
        setDatasets(theDatasets);
      }
    }
  }, [data]);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/Operations/components/LineChart/LineChart.jsx">
      <Line
        ref={chartRef}
        data={{
          labels,
          datasets,
        }}
        options={{
          layout: {
            padding: {
              top: 36,
            },
          },
          animation: false,
          responsive: true,
          plugins: {
            annotation: {
              clip: false,
              common: {
                drawTime: 'afterDraw',
              },
              annotations,
            },
            legend: {
              position: 'right',
              align: 'middle',
              title: {
                display: true,
                text: 'Sources',
              },
            },
            tooltip: {
              callbacks: {
                label(context) {
                  return `${context?.dataset?.label} (${context?.label})`;
                },
                title() {
                  return 'Untrusted Source';
                },
                footer(context) {
                  const record = data?.series?.find((item) => {
                    return item?.sourceCode === context?.[0]?.dataset?.label;
                  });

                  if (isUndefined(record)) {
                    return '';
                  }

                  const item = record?.dataPoints?.[context?.[0]?.dataIndex];

                  if (isUndefined(item)) {
                    return '';
                  }

                  return `\nValue: ${Number(item?.value).toLocaleString('en-US')}\nTrend Value: ${Number(
                    item?.trendValue
                  ).toLocaleString('en-US')}\nPercent Change: ${item?.percChange}%\n`;
                },
              },
            },
          },
          scales: {
            y: {
              stacked: false,
              border: { color: themeConfiguration?.theme?.colors?.gray6, dash: [5, 5] },
              grid: {
                color: themeConfiguration?.theme?.colors?.gray3,
              },
              ticks: {
                // Include a dollar sign in the ticks
                callback(value) {
                  return abbrNum(value);
                },
              },
              title: {
                display: true,
                text: 'Number of Entities',
              },
            },
            x: {
              border: { color: themeConfiguration?.theme?.colors?.gray6, dash: [5, 5] },
              grid: {
                color: themeConfiguration?.theme?.colors?.gray3,
              },
              title: {
                display: true,
                text: 'Date Range',
              },
            },
          },
        }}
      />
    </ErrorHandler>
  );
};

LineChart.propTypes = {
  data: PropTypes.shape({
    series: PropTypes.arrayOf(
      PropTypes.shape({
        sourceCode: PropTypes.string,
        dataPoints: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            percChange: PropTypes.number,
            value: PropTypes.number,
          })
        ),
      })
    ),
    events: PropTypes.arrayOf(
      PropTypes.shape({
        displayEndDate: PropTypes.string,
        displayStartDate: PropTypes.string,
        endDate: PropTypes.string,
        eventId: PropTypes.string,
        eventTypeCode: PropTypes.string,
        startDate: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }),
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      codeId: PropTypes.string,
      codeDesc: PropTypes.string,
    })
  ),
  selectedEvents: PropTypes.arrayOf({
    eventId: PropTypes.string,
    title: PropTypes.string,
  }),
};

LineChart.defaultProps = {
  data: {},
  eventTypes: [],
  selectedEvents: [],
};
